import React from 'react';
import {
  Box,
  Button,
  Dialog,
  Grid,
  TextField,
  Autocomplete,
  CircularProgress,
  IconButton,
  Typography,
  Divider,
  FormControlLabel,
  Switch,
  Checkbox,
  useTheme
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Formik } from 'formik';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { isMobile, isTablet } from 'react-device-detect';

import CustomDialogHeader from 'src/components/CustomDialog/CustomDialogHeader';
import CustomDialogContent from 'src/components/CustomDialog/CustomDialogContent';
import CustomDialogFooter from 'src/components/CustomDialog/CustomDialogFooter';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import CommonSkeleton from 'src/components/Helpers/CommonSkeleton';
import routes from 'src/components/Helpers/Routes';
import moment from 'moment-timezone';
import { useData } from 'src/context/Provider';

interface ManageScheduleProps {
  onClose: () => void;
  handleSucess: () => void;
  isClone?: boolean;
  scheduleId?: any;
  courseId?: any;
  instructorId?: any;
}

const ManageSchedule = ({ onClose, handleSucess, isClone, scheduleId, courseId, instructorId }: ManageScheduleProps) => {
  const theme = useTheme();
  const mode = theme.palette.mode;

  const formikRef = React.useRef(null);
  const toastConfig = React.useContext(CustomToastContext);
  const {
    state: { brand }
  } = useData();
  const [users, setUsers] = React.useState(null);
  const [fullScreen, setFullScreen] = React.useState(isMobile || isTablet);
  const [initialValues, setInitialValues] = React.useState<any>(null);

  React.useEffect(() => {
    if (scheduleId) {
      axiosInstance()
        .get(`${routes.courses.api}/${courseId}/schedule/${scheduleId}`)
        .then(({ data: { data } }) => {
          setInitialValues({
            startTime: data?.startTime,
            endTime: data?.endTime,
            instructor: data?.instructor?.optionValue,
            startDate: data?.startDate,
            endDate: data?.endDate,
            blockDayNames: data?.blockDayNames || [],
            label: data?.label,
            information: data?.information,
            status: data?.status,
            inactiveReason: data?.inactiveReason || ''
          });
        })
        .catch((error) => {});
    } else {
      setInitialValues({
        startDate: new Date(),
        endDate: new Date(),
        startTime: new Date(),
        endTime: new Date(),
        instructor: instructorId ? instructorId : '',
        status: true,
        blockDayNames: [],
        label: '',
        information: '',
        frequency: 0,
        inactiveReason: ''
      });
    }
  }, [scheduleId]);

  React.useEffect(() => {
    axiosInstance()
      .get(`${routes.courses.api}/instructor/${courseId}`)
      .then((res) => {
        setUsers(res?.data?.data);
      })
      .catch((err) => {});
  }, [scheduleId]);

  const handleSubmit = async (values: any) => {
    const payload: any = {
      startTime: values?.startTime,
      endTime: values?.endTime,
      startDate: values?.startDate,
      endDate: values?.endDate,
      instructor: values?.instructor,
      blockDayNames: values?.blockDayNames,
      label: values?.label,
      information: values?.information,
      status: values?.status,
      ...{ inactiveReason: !values?.status ? values?.inactiveReason : '' }
    };
    if (scheduleId && !isClone) {
      payload._id = scheduleId;
      await axiosInstance()
        .put(`${routes.courses.api}/${courseId}/schedule`, payload)
        .then(({ data: data }) => {
          toastConfig.setToastConfig({
            open: true,
            type: 'success',
            message: data.message
          });
          handleSucess();
        })
        .catch((err) => {
          toastConfig.setToastConfig(err);
        });
    } else {
      payload.frequency = values?.frequency;
      await axiosInstance()
        .post(`${routes.courses.api}/${courseId}/schedule`, payload)
        .then(({ data: data }) => {
          toastConfig.setToastConfig({
            open: true,
            type: 'success',
            message: data.message
          });
          handleSucess();
        })
        .catch((err) => {
          toastConfig.setToastConfig(err);
        });
    }
  };

  const validate = (values: any) => {
    let errors: any = {};
    if (!values?.instructor) {
      errors.instructor = 'Please select instructor';
    }
    if (!values?.status && values?.inactiveReason === '') {
      errors.inactiveReason = 'Please enter inactive reason';
    }
    const startDate = new Date(values?.startDate);
    const endDate = new Date(values?.endDate);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
    if (startDate > endDate) {
      errors['endDate'] = 'End date should be greater then start date';
    }
    if (moment(values?.startTime).hours() > moment(values?.endTime).hours()) {
      errors.endTime = 'End time should be greater then start time';
    }
    return errors;
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen || isMobile || isTablet}
      aria-labelledby="customized-dialog-title"
      onClose={(e, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      open
    >
      {initialValues && users ? (
        <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={handleSubmit} validate={validate} enableReinitialize>
          {({ values, setFieldValue, submitForm, errors, touched, isSubmitting }) => (
            <>
              <CustomDialogHeader
                title={scheduleId && !isClone ? 'Update Schedule' : 'Add Schedule'}
                onClose={onClose}
                isMinimized={!fullScreen}
                onMinimizeMaximize={() => {
                  setFullScreen((prevState) => !prevState);
                }}
                showManimizeMaximize={true}
              />
              <CustomDialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <DesktopDatePicker
                      label={'Start Date'}
                      value={values.startDate}
                      onChange={(newValue) => {
                        setFieldValue('startDate', newValue);
                      }}
                      inputFormat="MM/DD/YYYY"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="startDate"
                          size="small"
                          variant="outlined"
                          error={touched['startDate'] && Boolean(errors['startDate'])}
                          helperText={touched['startDate'] && errors['startDate']?.toString()}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DesktopDatePicker
                      label={'End Date'}
                      value={values.endDate}
                      onChange={(newValue) => {
                        setFieldValue('endDate', newValue);
                      }}
                      inputFormat="MM/DD/YYYY"
                      minDate={values.startDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="endDate"
                          size="small"
                          variant="outlined"
                          error={touched['endDate'] && Boolean(errors['endDate'])}
                          helperText={touched['endDate'] && errors['endDate']?.toString()}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TimePicker
                      value={values?.startTime}
                      onChange={(newValue) => {
                        setFieldValue('startTime', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={'Start Time'}
                          size="small"
                          variant="outlined"
                          error={touched['startTime'] && Boolean(errors['startTime'])}
                          helperText={touched['startTime'] && errors['startTime']?.toString()}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TimePicker
                      value={values?.endTime}
                      onChange={(newValue) => {
                        setFieldValue('endTime', newValue);
                      }}
                      minTime={moment(values?.startTime)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={'End Time'}
                          size="small"
                          variant="outlined"
                          error={touched['endTime'] && Boolean(errors['endTime'])}
                          helperText={touched['endTime'] && errors['endTime']?.toString()}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  {!instructorId && (
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        size="small"
                        fullWidth
                        isOptionEqualToValue={(option, value) => {
                          if (option && value) {
                            return option._id === value.id;
                          }
                          return false;
                        }}
                        options={users || []}
                        value={users?.find((user: any) => user._id === values.instructor) || null}
                        getOptionLabel={(option: any) => option.firstName + ' ' + option.lastName}
                        onChange={(_, newVal: any) => setFieldValue('instructor', newVal?._id)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            required
                            name={'instructor'}
                            label={'Instructor'}
                            error={touched['instructor'] && Boolean(errors['instructor'])}
                            helperText={touched['instructor'] && errors['instructor']?.toString()}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      type="text"
                      label="Label"
                      autoComplete="off"
                      name="label"
                      value={values.label}
                      onChange={(e) => {
                        setFieldValue('label', e.target.value);
                      }}
                      error={touched['label'] && Boolean(errors['label'])}
                      helperText={touched['label'] && errors['label']?.toString()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      type="text"
                      autoComplete="off"
                      label="Information"
                      name="information"
                      value={values.information}
                      onChange={(e) => {
                        setFieldValue('information', e.target.value);
                      }}
                      error={touched['information'] && Boolean(errors['information'])}
                      helperText={touched['information'] && errors['information']?.toString()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      value={values.blockDayNames}
                      multiple
                      onChange={(event: any, newValue: any) => {
                        setFieldValue('blockDayNames', newValue);
                      }}
                      id="controllable-states-demo"
                      options={['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']}
                      renderInput={(params) => <TextField {...params} size="small" label="Block Day Names" />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          onChange={(e: { target: { checked: any } }) => setFieldValue('status', e.target.checked)}
                          checked={values.status}
                          defaultChecked
                        />
                      }
                      label="Active"
                    />
                  </Grid>
                  {!values.status && (
                    <Grid item xs={12} sm={6}>
                      <TextField
                        size="small"
                        variant="outlined"
                        fullWidth
                        type="text"
                        label="Inactive Reason"
                        autoComplete="off"
                        name="inactiveReason"
                        value={values?.inactiveReason}
                        onChange={(e) => {
                          setFieldValue('inactiveReason', e.target.value);
                        }}
                        error={touched['inactiveReason'] && Boolean(errors['inactiveReason'])}
                        helperText={touched['inactiveReason'] && errors['inactiveReason']?.toString()}
                      />
                    </Grid>
                  )}
                </Grid>
                {!scheduleId && (
                  <Box pt={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          size="small"
                          variant="outlined"
                          fullWidth
                          type="number"
                          label={'Frequency(minutes)'}
                          required
                          name="frequency"
                          value={values.frequency}
                          onChange={(e) => {
                            const value = parseFloat(e.target.value);
                            setFieldValue('frequency', !isNaN(value) ? value : 0);
                          }}
                          error={touched['frequency'] && Boolean(errors['frequency'])}
                          helperText={touched['frequency'] && errors['frequency']?.toString()}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </CustomDialogContent>
              <CustomDialogFooter>
                <Button variant="outlined" size="small" onClick={onClose} disabled={isSubmitting}>
                  Close
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={submitForm}
                  disabled={isSubmitting}
                  endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                >
                  Save
                </Button>
              </CustomDialogFooter>
            </>
          )}
        </Formik>
      ) : (
        <Box height={500} bgcolor={mode === 'dark' ? 'gray.900' : 'white'}>
          <CommonSkeleton lenArray={[...Array(10).keys()]} />
        </Box>
      )}
    </Dialog>
  );
};

export default ManageSchedule;
