import { PaletteMode, createTheme, darkScrollbar } from '@mui/material';
import { grey } from '@mui/material/colors';

export const getDesignTokens = (mode: PaletteMode) => ({
  typography: {
    fontFamily: [
      'Nunito',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          ...darkScrollbar(
            mode === 'light'
              ? {
                  track: grey[200],
                  thumb: grey[400],
                  active: grey[400]
                }
              : undefined
          ),
          //scrollbarWidth for Firefox
          scrollbarWidth: 'thin'
        }
      }
    }
  },
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          primary: {
            light: '#091823',
            main: '#163340',
            dark: '#163340'
          },
          secondary: {
            light: '#047d1c',
            main: '#047d1c',
            dark: '#047d1c'
          },
          error: {
            light: '#e57373',
            main: '#f44336',
            dark: '#d32f2f',
            contrastText: '#fff'
          },
          info: {
            light: '#75e2dd',
            main: '#2196f3',
            dark: '#1976d2'
          },
          success: {
            light: '#81c784',
            main: '#4caf50',
            dark: '#388e3c'
          }
        }
      : {
          primary: {
            light: '#026899',
            main: '#026899',
            dark: '#026899'
          },
          secondary: {
            light: '#047d1c',
            main: '#047d1c',
            dark: '#047d1c'
          },
          error: {
            light: '#e57373',
            main: '#f44336',
            dark: '#d32f2f',
            contrastText: '#fff'
          },
          info: {
            light: '#75e2dd',
            main: '#2196f3',
            dark: '#1976d2'
          },
          success: {
            light: '#81c784',
            main: '#4caf50',
            dark: '#388e3c'
          }
        })
  }
});

export const theme = createTheme();
