import React from 'react';
import {
  Typography,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  TablePagination,
  Skeleton,
  useTheme
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { useData } from 'src/context/Provider';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import moment from 'moment-timezone';
import { StudentsIcon, Instructors, Parents } from 'src/assets/svgIcons';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import routes from 'src/components/Helpers/Routes';

const SimpleCard = ({ title, info, loading, icon = <></> }: { title: string; info: number; loading: boolean; icon: JSX.Element | null }) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  return (
    <Paper sx={{ p: 2, ...customStyles.cardContainer }}>
      <Box sx={{ ...customStyles.cardIconContainer }}>{icon}</Box>
      <Box>
        <Typography variant="h6" color="gray">
          {title}
        </Typography>
        {loading ? (
          <Skeleton width={80} height={60} variant="text" />
        ) : (
          <Typography
            variant="h3"
            color="GreyText"
            sx={{ ...customStyles.infoText, color: mode === 'light' ? '#3A3A3A' : theme.palette.getContrastText(theme.palette.background.paper) }}
          >
            {info}
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

const Dashboard = () => {
  const { setToastConfig } = React.useContext(CustomToastContext);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [instructorCount, setInstructorCount] = React.useState(0);
  const [studentCount, setStudentCount] = React.useState(0);
  const [parentCount, setParentCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const navigate = useNavigate();

  const theme = useTheme();

  const {
    state: { user }
  } = useData();

  const columns = [
    { id: 'courseName', label: 'Course Name', minWidth: 150, _id: '_id' },
    { id: 'count', label: 'Total Registration', minWidth: 180, _id: '_id' }
  ];

  const fetchData = useCallback(() => {
    setLoading(true);
    axiosInstance()
      .get('/dashboard')
      .then(({ data: { data } }) => {
        setRows(data.courses || []);
        setStudentCount(data.count.student || 0);
        setParentCount(data.count.parent || 0);
        setInstructorCount(data.count.instructor || 0);
        setLoading(false);
      })
      .catch((err) => {
        setToastConfig(err);
        setLoading(false);
      });
  }, [setToastConfig]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <React.Fragment>
      <Box sx={{ p: 1 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 42
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <DashboardIcon fontSize="small" color="primary" />
            <Box mr={1} />
            <Typography variant="h6" color="GrayText">
              Dashboard
            </Typography>
            <Typography variant={'h6'} sx={{ ...customStyles.userRole }} className={'poppins'} style={{ color: '#3A3A3A' }}>
              {user?.role}
            </Typography>
            {/* <Box mr={1} /> */}
          </Box>
        </Box>
        <Box
          sx={{ ...customStyles.userDetailsContainer, backgroundColor: theme.palette.mode === 'light' ? '#EFF8FF' : theme.palette.background.paper }}
        >
          <Box sx={{ ...customStyles.iconContainer }}>
            <AccountCircleIcon color="inherit" />
          </Box>
          <Box>
            <Typography
              sx={{
                ...customStyles.time,
                color: theme.palette.mode === 'light' ? '#3A3A3A' : theme.palette.getContrastText(theme.palette.background.paper)
              }}
              variant="h6"
            >
              {moment().format('dddd MMMM Do')}
            </Typography>
            <Typography
              sx={{
                ...customStyles.desc,
                color: theme.palette.mode === 'light' ? '#3A3A3A' : theme.palette.getContrastText(theme.palette.background.paper)
              }}
              variant="h6"
              component="p"
            >
              {`${user?.firstName} ${user?.lastName}`}
            </Typography>
          </Box>
        </Box>
        <Box mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <SimpleCard title={'Students'} info={studentCount} loading={loading} icon={<StudentsIcon />} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SimpleCard title={'Parents'} info={parentCount} loading={loading} icon={<Parents />} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SimpleCard title={'Instructors'} info={instructorCount} loading={loading} icon={<Instructors />} />
            </Grid>
          </Grid>
        </Box>
        <Box mt={1}>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} align={'left'} style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.fullName + ' ' + i}>
                        {columns.map((column) => {
                          const value = row[column.id];

                          return (
                            <TableCell
                              key={column.id}
                              align={'left'}
                              onClick={() => user?.role === 'Admin' && navigate(`${routes.coursesDetails.path}/${row[column._id]}`)}
                            >
                              <p style={{ cursor: user?.role === 'Admin' ? 'pointer' : 'default' }}>{value}</p>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Dashboard;

const customStyles = {
  userRole: {
    backgroundColor: '#FFE48A',
    borderRadius: '6px',
    padding: '8px 22px 4px',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.02em',
    color: '#3A3A3A',
    fontWeight: 700,
    marginLeft: '20px'
  },
  userDetailsContainer: {
    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
    borderRadius: '4px',
    padding: { xs: '16px 20px', md: '16px 40px' },
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBlock: { xs: '15px', md: '22px' }
  },
  iconContainer: {
    width: '76px',
    height: '76px',
    borderRadius: '50%',
    border: '4px solid #99D0FF',
    overflow: 'hidden',
    color: 'lightgray',
    marginRight: { xs: '15px', md: '28px' },
    '& > svg, & > img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  },
  time: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '27px',
    color: '#3A3A3A'
  },
  desc: {
    fontWeight: 600,
    fontSize: '22px',
    lineHeight: '33px',
    color: '#4E4E4E'
  },
  cardContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  cardIconContainer: {
    flexBasis: '80px',
    maxWidth: '80px',
    marginRight: { xs: '20px', md: '50px' }
  },
  infoText: {
    fontWeight: 700,
    fontSize: '34px',
    lineHeight: '51px',
    letterSpacing: '0.04em'
  }
};
