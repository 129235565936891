import React from 'react';
import { Dialog } from '@mui/material';

import { CustomDialogHeader, CustomDialogContent } from 'src/components/CustomDialog';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { axiosInstance } from 'src/axios';
import ImageCropTool from 'src/components/ImageCropTool';

interface ImageDialogProps {
  images: string[];
  setFieldValue: (name: string, value: any) => void;
  setReadingImage: React.Dispatch<React.SetStateAction<boolean>>;
  image: string;
  setImage: React.Dispatch<React.SetStateAction<string>>;
  imageFileName: string;
  setImageFileName: React.Dispatch<React.SetStateAction<string>>;
  fieldName?: string;
}

const ImageUploadDialog = ({
  images,
  setFieldValue,
  setReadingImage,
  image,
  setImage,
  imageFileName,
  setImageFileName,
  fieldName = 'images'
}: ImageDialogProps) => {
  const { setToastConfig } = React.useContext(CustomToastContext);
  const [isImgUploading, setImgUploading] = React.useState(false);
  const [imageUploadProgress, setImageUploadProgress] = React.useState(0);

  const getImageUrl = (file: any) => {
    setImageUploadProgress(0);
    let formData = new FormData();
    formData.append('file', file);
    setImgUploading(true);
    axiosInstance()
      .post('/users/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (pE: { loaded: number; total: number }) => {
          const completedPercent = Math.floor((pE.loaded * 100) / pE.total);
          setImageUploadProgress(completedPercent);

          if (completedPercent === 100) {
            setTimeout(() => {
              setImageUploadProgress(0);
            }, 4000);
          }
        }
      })
      .then(({ data }) => {
        // if(fieldName === 'images')
        if (fieldName === 'icon') {
          setImage('');
          setFieldValue(fieldName, data.fileURL);
          setImageFileName('');
        } else if (images) {
          setImage('');
          setFieldValue(fieldName, [...images, data.fileURL]);
          setImageFileName('');
        } else {
          let currentData = images ? images : [];
          setImage('');
          setFieldValue(fieldName, [...currentData, data.fileURL]);
          setImageFileName('');
        }

        setImgUploading(false);
      })
      .catch((err) => {
        setToastConfig(err);
        setImage('');
        setImageFileName('');
        setImgUploading(false);
        setImageUploadProgress(0);
      });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={Boolean(image) || isImgUploading}
      onClose={() => {
        if (!isImgUploading) {
          setImage('');
          setReadingImage(false);
        }
      }}
    >
      <CustomDialogHeader
        showRequiredLabel={false}
        onClose={() => {
          if (!isImgUploading) {
            setImage('');
          }
        }}
        title="Edit Image"
      />
      <CustomDialogContent>
        <ImageCropTool
          image={image}
          setImage={setImage}
          getImageUrl={getImageUrl}
          isImgUploading={isImgUploading}
          imageUploadProgress={imageUploadProgress}
          imageFileName={imageFileName}
        />
      </CustomDialogContent>
    </Dialog>
  );
};
export default ImageUploadDialog;
