import React from 'react';
import { RestartAlt, SaveAlt } from '@mui/icons-material';
import { Button, Box, TextField, CircularProgress, useTheme } from '@mui/material';
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import { downloadExcel } from 'react-export-table-to-excel';
import { camelCase } from 'lodash';
import { read, utils, writeFile } from 'xlsx';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import moment from 'moment-timezone';
import { useData } from 'src/context/Provider';

type CustomToolbarTypes = {
  onRefresh?: () => void;
  hasExport?: boolean | false;
  value?: string;
  exportApi?: string;
  fileName?: string;
  header?: [string];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const CustomToolbar = (props: CustomToolbarTypes) => {

  const { onRefresh, onChange, hasExport, value, exportApi, fileName, header } = props;
  const { setToastConfig } = React.useContext(CustomToastContext);
  const theme = useTheme();
  const mode = theme.palette.mode;

  const [exporting, setExporting] = React.useState(false);

  const handleExport = () => {
    setExporting(true);
    axiosInstance()
      .get(`${exportApi}`)
      .then(({ data: { data } }) => {
        if (exportApi.includes('/report?') && !exportApi.includes('student')) {
          data = data?.data?.map((d: any, index: number) => ({
            ...d,
            course: d?.course?.optionLabel,
            date: d?.bookDates?.startDate,
            time: d?.bookDates?.startTime,
            instructor: d?.instructor?.optionLabel,
            totalStudents: d?.students?.length,
            registrationDate: moment(d?.registrationDate).format('DD/MM/YYYY'),
            studentsName: d?.students?.map((s: any) => `${s?.firstName} ${s?.lastName}`)?.join(', ')
          }));
        }
        if (exportApi.includes('donation')) {
          data = data?.map((d: any) => ({
            ...d,
            contributeType: d?.contributeTo?.contributeType
          }));
        }
        if (exportApi.includes('student-attendance')) {
          data = data?.data.map((d: any, index: number) => ({
            ...d,
            course: d?.course?.optionLabel,
            instructor: d?.instructor.optionLabel,
            studentsName: d?.student.optionLabel,
            orderNumber: d?.order.optionLabel,
            attendanceDateTime: moment(d?.date).format('MM/DD/YYYY hh:mm A'),
            transactionDateTime: d?.transactionDate ? moment(d?.transactionDate).format('MM/DD/YYYY hh:mm A') : ''
          }));
        }
        const body: any[] = [];
        data.forEach((d: any) => {
          let fieldData: any = {};

          header.forEach((field: string) => {
            fieldData[field] = field === 'Status' ? (d[camelCase(field)] ? 'Active' : 'In-Active') : d[camelCase(field)]?.toString();
          });

          body.push(fieldData);
        });
        // downloadExcel({
        //   fileName: fileName || 'SNEI-Excel',
        //   sheet: fileName || 'SNEI-Sheet',
        //   tablePayload: {
        //     header,
        //     body
        //   }
        // });
        var ws = utils.json_to_sheet(body);
        if (header.length) {
          utils.sheet_add_aoa(ws, [header]);
        }
        var wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Sheet1');
        writeFile(wb, `${fileName}.xlsx`);
      })
      .catch((err) => {
        setToastConfig(err);
      })
      .finally(() => setExporting(false));
  };

  return (
    <GridToolbarContainer
      sx={{
        backgroundColor: mode === 'light' ? '#F3F3F3' : theme.palette.background.paper,
        padding: '11px 15px',
        backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09))'
      }}
    >
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      {hasExport && exportApi && (
        <Button
          variant="text"
          size="small"
          disabled={exporting}
          onClick={handleExport}
          startIcon={exporting ? <CircularProgress color="inherit" size={18} /> : <SaveAlt />}
        >
          Export
        </Button>
      )}
      <Button variant="text" size="small" onClick={() => onRefresh && onRefresh()} startIcon={<RestartAlt />}>
        Refresh
      </Button>
      <Box ml="auto">
        <TextField
          sx={{ width: 200, background: mode === 'light' ? 'white' : 'transparent' }}
          type="search"
          size="small"
          placeholder="Search"
          value={value}
          onChange={onChange}
        />
      </Box>
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
