import { createContext, useContext, useEffect, useReducer } from 'react';
import { axiosInstance } from 'src/axios';
import { SET_USER, SET_BRAND, USER_LOADING } from './ActionTypes';
import reducer, { ActionTypes, initialState, InitialStateType } from './Reducer';

type ContextType = {
  state: InitialStateType;
  dispatch: ({ type, payload }: ActionTypes) => void;
};

const StateContext = createContext<ContextType>(null);

export const Provider = ({ children }: any) => {
  const token = localStorage.getItem('token');
  const [state, dispatch] = useReducer<React.Reducer<InitialStateType, ActionTypes>>(reducer, initialState);

  useEffect(() => {
    dispatch({ type: USER_LOADING, payload: true });
    if (token && navigator.onLine) {
      axiosInstance()
        .get('auth/me')
        .then(({ data: { data } }) => {
          dispatch({ type: SET_USER, payload: data?.user ?? null });
          dispatch({ type: SET_BRAND, payload: data?.brand ?? null });
          dispatch({ type: USER_LOADING, payload: false });
        })
        .catch(() => {
          dispatch({ type: USER_LOADING, payload: false });
          localStorage.removeItem('token');
        });
    }
  }, [token]);

  return <StateContext.Provider value={{ state, dispatch }}>{children}</StateContext.Provider>;
};

export const useData = () => useContext(StateContext);
