import {
  Autocomplete,
  Box,
  TextField,
  Typography,
  Grid,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
  Button,
  IconButton
} from '@mui/material';
import React from 'react';
import CustomBreadcrumbs from 'src/components/Helpers/CustomBreadcrumbs';
import routes from 'src/components/Helpers/Routes';
import AssessmentIcon from '@mui/icons-material/Assessment';
import moment from 'moment';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { getQueryParams } from 'src/utils';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import CustomToolbar from 'src/components/Helpers/CustomToolbar';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import ManageBroadcastDialog from './ManageBroadcastDialog';
import { MdDelete } from 'react-icons/md';
import ConfirmationDialog from 'src/components/Helpers/ConfirmationDialog';

type Props = {};

const Bradcast = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  const { setToastConfig } = React.useContext(CustomToastContext);
  const [searchValue, setSearchValue] = React.useState('');
  const [count, setCount] = React.useState(0);
  const [broadcastData, setbroadcastData] = React.useState([]);
  const [broadcastDialog, setBroadcastDialog] = React.useState({ open: false, data: null });
  const [deleteRecord, setDeleteRecord] = React.useState([]);
  const [showDeleteConfirmBox, setShowDeleteConfirmBox] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [gridState, setGridState] = React.useState({
    limit: 20,
    page: 0
  });

  React.useEffect(() => {
    fetchBroadcastsReport();
  }, []);
  const fetchBroadcastsReport = async () => {
    setLoading(true);
    try {
      let {
        data: { data }
      } = await axiosInstance().get(`${routes.broadcast.api}`);
      const notifications = data?.data?.map((d: any, index: number) => ({
        ...d,
        id: d?._id + '-' + index,
        title: d.title,
        description: d?.description,
        date: moment(d?.date).format('DD-MM-YYYY'),
        status: d?.active ? 'Active' : 'Inactive',
        createdBy: d?.createdBy?.user?.concatedName || '',
        updatedBy: d?.updatedBy?.user?.concatedName || ''
      }));
      setbroadcastData(notifications || []);
      setCount(data?.data?.length);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setToastConfig(error);
    }
  };

  const handleSearch = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value.trimStart());
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Title',
      width: 180,
      renderCell: (params: any) => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setBroadcastDialog({ open: true, data: params?.row });
            }}
          >
            {params?.row?.title}
          </div>
        );
      }
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 150,
      renderCell: (params: any) => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setBroadcastDialog({ open: true, data: params?.row });
            }}
          >
            {params?.row?.description}
          </div>
        );
      }
    },
    { field: 'date', headerName: 'Date', width: 250 },
    { field: 'link', headerName: 'Link', width: 250 },
    { field: 'status', headerName: 'Status', width: 100 },
    { field: 'createdBy', headerName: 'CreatedBy', width: 250 },
    { field: 'updatedBy', headerName: 'UpdatedBy', width: 250 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params: any) => {
        return (
          <Box>
            <IconButton
              onClick={() => {
                setDeleteRecord([params.row._id]);
                setShowDeleteConfirmBox(true);
              }}
            >
              <MdDelete size={18} color="red" />
            </IconButton>
          </Box>
        );
      },
      headerAlign: 'left',
      align: 'left'
    }
  ];

  const handleDelete = () => {
    setDeleting(true);
    axiosInstance()
      .put(`${routes.broadcast.api}/remove`, {
        ids: deleteRecord
      })
      .then(() => {
        setDeleting(false);
        setDeleteRecord(null);
        setShowDeleteConfirmBox(false);
        fetchBroadcastsReport();
      })
      .catch((err) => {
        setDeleting(false);
        setToastConfig(err);
      });
  };

  return (
    <React.Fragment>
      <Box sx={{ p: 1 }}>
        <CustomBreadcrumbs routes={[routes.broadcast]} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 42,
            pl: 1,
            pr: 1
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PodcastsIcon fontSize="small" color="primary" />
            <Box mr={1} />
            <Typography variant="h6" color="GrayText">
              {routes.broadcast.title}
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setBroadcastDialog({ open: true, data: null });
            }}
          >
            Add
          </Button>
        </Box>

        <Box mt={1}>
          <DataGrid
            sx={{ height: 'calc(100vh - 190px)' }}
            loading={loading}
            rows={broadcastData}
            columns={columns}
            pageSize={gridState.limit}
            pagination
            paginationMode={'server'}
            page={gridState.page}
            onPageSizeChange={(pageSize) => setGridState((s) => ({ ...s, limit: pageSize }))}
            onPageChange={(page) => {
              setGridState((s) => ({ ...s, page }));
            }}
            rowCount={count}
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{
              toolbar: {
                value: searchValue,
                onChange: handleSearch,
                onRefresh: fetchBroadcastsReport,
                hasExport: false
              }
            }}
            rowsPerPageOptions={[5, 20, 50]}
            disableSelectionOnClick
          />
        </Box>
      </Box>
      {showDeleteConfirmBox && (
        <ConfirmationDialog
          open={showDeleteConfirmBox}
          message={`Are you sure you want to delete broadcast?`}
          onClose={() => {
            setDeleteRecord(null);
            setShowDeleteConfirmBox(false);
          }}
          onOk={handleDelete}
        />
      )}
      {broadcastDialog.open && (
        <ManageBroadcastDialog
          onClose={() => {
            setBroadcastDialog({ open: false, data: null });
          }}
          data={broadcastDialog.data}
          handleSucess={() => {
            setBroadcastDialog({ open: false, data: null });
            fetchBroadcastsReport();
          }}
        />
      )}
    </React.Fragment>
  );
};

export default Bradcast;
