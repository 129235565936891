import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Autocomplete, IconButton, Paper, Typography, Menu, MenuItem, TextField } from '@mui/material';
import { axiosInstance } from 'src/axios';
import routes from 'src/components/Helpers/Routes';
import ManageSchedule from '../Courses/Schedule/ManageSchedule';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ScheduleIcon from '@mui/icons-material/Schedule';
import EditIcon from '@mui/icons-material/Edit';
import ArrowDownIcon from '@mui/icons-material/ExpandMore';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { CustomToastContext } from 'src/context/CustomToastContext';
import moment from 'moment-timezone';
import ConfirmationDialog from 'src/components/Helpers/ConfirmationDialog';
import { DataGrid, GridColDef, GridToolbar, GridValueGetterParams } from '@mui/x-data-grid';
import CustomBreadcrumbs from 'src/components/Helpers/CustomBreadcrumbs';
import CustomToolbar from 'src/components/Helpers/CustomToolbar';
import CustomTooltip from 'src/components/Helpers/CustomTooltip';
import { useData } from 'src/context/Provider';

const Schedule = () => {
  const {
    state: { brand, user }
  } = useData();
  const toastConfig = useContext(CustomToastContext);
  const [openDialog, setOpenDialog] = useState({ open: false, clone: false });
  const [scheduleId, setScheduleId] = useState(null);
  const [searchValue, setSearchValue] = React.useState('');
  const [showDeleteConfirmBox, setShowDeleteConfirmBox] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [loadingCourses, setLoadingCourses] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectedCourse, setSelectedCourse] = React.useState(null);
  const [courseOptions, setCourseOptions] = React.useState([]);
  const [count, setCount] = React.useState(1);
  const [gridState, setGridState] = React.useState({
    limit: 20,
    page: 0
  });

  useEffect(() => {
    fetchCourses();
  }, []);

  useEffect(() => {
    if (!selectedCourse) return;

    fetchSchedule();
  }, [selectedCourse]);

  const fetchCourses = () => {
    setLoadingCourses(true);
    axiosInstance()
      .get(`${routes.courses.api}/i-teach`)
      .then(({ data: { data } }) => {
        const options = data?.map((course: any) => ({
          optionValue: course?._id,
          optionLabel: `${course?.courseName} (${course?.type})`
        }));
        setCourseOptions(options);
        setLoading(false);
      })
      .catch((error) => {
        toastConfig.setToastConfig(error);
      });
  };

  const fetchSchedule = async () => {
    if (selectedRows.length > 0) setSelectedRows([]);
    setLoading(true);
    await axiosInstance()
      .get(`${routes.courses.api}/${selectedCourse.optionValue}/schedule?type=instructor`)
      .then(({ data: { data } }) => {
        setRows(data?.map((d: any) => ({ ...d, id: d._id })));
        setCount(data?.length);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toastConfig.setToastConfig(error);
      });
  };

  const handleDelete = () => {
    let ids = [];
    if (deleteRecord) {
      ids = deleteRecord;
    } else {
      ids = selectedRows;
    }

    axiosInstance()
      .put(`${routes.courses.api}/${selectedCourse.optionValue}/schedule/remove`, { ids })
      .then(({ data }) => {
        toastConfig.setToastConfig({
          open: true,
          type: 'success',
          message: data.message
        });
        fetchSchedule();
        setDeleteRecord(null);
        setShowDeleteConfirmBox(false);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  const columns: GridColDef[] = [
    {
      field: 'startDate',
      headerName: 'Date',
      width: 250,
      valueGetter: (params: GridValueGetterParams) => {
        return `${moment(params.row.startDate).format('Do MMM YY')} - ${moment(params.row.endDate).format('Do MMM YY')}`;
      }
    },
    {
      field: 'startTime',
      headerName: 'Time',
      width: 250,
      valueGetter: (params: GridValueGetterParams) => {
        return `${moment(params.row.startTime).format('h:mm A')} - ${moment(params.row.endTime).format('h:mm A')}`;
      }
    },
    {
      field: 'blockDayNames',
      headerName: 'Block Day Names',
      width: 250
    },
    {
      field: 'label',
      headerName: 'Label',
      width: 200
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params: any) => {
        return (
          <>
            <CustomTooltip title="Edit">
              <IconButton
                onClick={() => {
                  setScheduleId(params.row?._id);
                  setOpenDialog({ open: true, clone: false });
                }}
                edge="end"
                color="primary"
                aria-label="delete"
              >
                <EditIcon />
              </IconButton>
            </CustomTooltip>
            <CustomTooltip title="Clone">
              <IconButton
                onClick={() => {
                  setScheduleId(params.row?._id);
                  setOpenDialog({ open: true, clone: true });
                }}
                edge="end"
                color="primary"
                aria-label="delete"
              >
                <FileCopyIcon />
              </IconButton>
            </CustomTooltip>
            <CustomTooltip title="Delete">
              <IconButton
                onClick={() => {
                  setDeleteRecord([params.row._id]);
                  setShowDeleteConfirmBox(true);
                }}
                edge="end"
                aria-label="delete"
              >
                <DeleteIcon color="error" />
              </IconButton>
            </CustomTooltip>
          </>
        );
      },
      headerAlign: 'left',
      align: 'left'
    }
  ];

  const handleSearch = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value.trimStart());
  }, []);

  return (
    <React.Fragment>
      <Box sx={{ p: 1 }}>
        <CustomBreadcrumbs routes={[routes.schedule]} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 42,
            pl: 1,
            pr: 1
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ScheduleIcon fontSize="small" color="primary" />
            <Box mr={1} />
            <Typography variant="h6" color="GrayText">
              Schedules
            </Typography>
            <Box mr={1} />
            <Autocomplete
              loading={loadingCourses}
              loadingText={'Loading courses...'}
              sx={{ width: 250 }}
              options={courseOptions}
              value={selectedCourse}
              getOptionLabel={(option) => option.optionLabel}
              isOptionEqualToValue={(option, value) => option.optionValue === value.optionValue}
              onChange={(_, newValue) => {
                setSelectedCourse(newValue);
              }}
              size="small"
              renderInput={(params) => <TextField {...params} label="Select Course" />}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              id="add-button"
              aria-controls={'add-button'}
              onClick={() => {
                setOpenDialog({ open: true, clone: false });
              }}
              size="small"
              disabled={!Boolean(selectedCourse)}
              variant="contained"
            >
              Add
            </Button>
            <Box mr={1} />
            <Button
              aria-label="delete button"
              onClick={() => setShowDeleteConfirmBox(true)}
              size="small"
              disabled={!Boolean(selectedCourse) || selectedRows.length === 0}
              variant="outlined"
            >
              Delete
            </Button>
          </Box>
        </Box>
        <Box pt={1}>
          <DataGrid
            sx={{ height: 'calc(100vh - 150px)' }}
            loading={loading}
            rows={rows}
            columns={columns}
            pagination
            paginationMode={'server'}
            pageSize={gridState.limit}
            page={gridState.page}
            onPageSizeChange={(pageSize) => setGridState((s) => ({ ...s, limit: pageSize }))}
            onPageChange={(page) => setGridState((s) => ({ ...s, page }))}
            rowCount={count}
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{
              toolbar: {
                value: searchValue,
                onChange: handleSearch,
                onRefresh: () => selectedCourse && fetchSchedule()
              }
            }}
            rowsPerPageOptions={[5, 20, 50, 100]}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(selection) => {
              setSelectedRows(selection);
            }}
          />
        </Box>
      </Box>
      {openDialog.open && (
        <ManageSchedule
          instructorId={user?._id}
          scheduleId={scheduleId}
          isClone={openDialog.clone}
          courseId={selectedCourse?.optionValue}
          onClose={() => {
            setOpenDialog({ open: false, clone: false });
            setScheduleId(null);
          }}
          handleSucess={() => {
            setOpenDialog({ open: false, clone: false });
            fetchSchedule();
          }}
        />
      )}
      {showDeleteConfirmBox && (
        <ConfirmationDialog
          open={showDeleteConfirmBox}
          message={`Are you sure you want to delete?`}
          onClose={() => {
            setDeleteRecord(null);
            setShowDeleteConfirmBox(false);
          }}
          onOk={handleDelete}
        />
      )}
    </React.Fragment>
  );
};

export default Schedule;
