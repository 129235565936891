import { useState, useEffect, useContext, Fragment } from 'react';
import { axiosInstance } from 'src/axios';
import { Box, Button, IconButton, Typography, Menu, MenuItem } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { MdDelete, MdOutlineModeEditOutline } from 'react-icons/md';
import { CustomToastContext } from 'src/context/CustomToastContext';
import AddIcon from '@mui/icons-material/Add';
import ArrowDownIcon from '@mui/icons-material/ExpandMore';
import CustomBreadcrumbs from 'src/components/Helpers/CustomBreadcrumbs';
import routes from 'src/components/Helpers/Routes';
import CustomToolbar from 'src/components/Helpers/CustomToolbar';
import ConfirmationDialog from 'src/components/Helpers/ConfirmationDialog';
import ManageContributeDialog from './ManageContributeDialog';
import { useData } from 'src/context/Provider';
import AcUnitIcon from '@mui/icons-material/AcUnit';

const Contribute = () => {
  const { setToastConfig } = useContext(CustomToastContext);

  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [searchedRows, setSearchedRows] = useState([]);
  const [contributeId, setContributeId] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [gridState, setGridState] = useState({
    limit: 20,
    page: 0
  });
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const [rowCount, setRowCount] = useState(0);
  const openMenu = Boolean(anchorEl);
  const [showDeleteConfirmBox, setShowDeleteConfirmBox] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const {
    state: { brand }
  } = useData();

  useEffect(() => {
    fetchContribute();
  }, []);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const fetchContribute = () => {
    setLoading(true);
    axiosInstance()
      .get(routes.contributeMaster.api)
      .then(({ data: { data, count } }) => {
        setRowCount(count);
        setRows(data);
        setLoading(false);
      })
      .catch((err) => {
        setToastConfig(err);
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);
    axiosInstance()
      .put(`${routes.contributeMaster.api}/remove`, { ids: deleteRecord })
      .then(({ data }) => {
        setToastConfig({
          open: true,
          type: 'success',
          message: data.message
        });

        setDeleteRecord(null);
        setShowDeleteConfirmBox(false);
        fetchContribute();
        setLoading(false);
      })
      .catch((error) => {
        setToastConfig(error);
        setLoading(false);
      });
  };

  const columns: GridColDef[] = [
    {
      field: 'contributeType',
      headerName: 'Donation Type',
      width: 150
    },
    {
      field: 'amount',
      headerName: `Amount ${brand?.currency}`,
      width: 150
    },
    {
      field: 'private',
      headerName: 'Private',
      type: 'string',
      valueGetter: (params) => {
        if (params.value) {
          return 'True';
        } else {
          return 'False';
        }
      },
      width: 150
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      valueGetter: (params) => {
        if (params.value) {
          return 'Active';
        } else {
          return 'Inactive';
        }
      },
      width: 150
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      headerAlign: 'left',
      renderCell: (params: any) => {
        return (
          <>
            <div style={{ display: 'flex' }}>
              <IconButton
                onClick={() => {
                  setContributeId(params.id);
                  setOpen(true);
                }}
              >
                <MdOutlineModeEditOutline size={18} />
              </IconButton>
              <IconButton
                onClick={() => {
                  setDeleteRecord([params.id]);
                  setShowDeleteConfirmBox(true);
                }}
              >
                <MdDelete size={18} color="red" />
              </IconButton>
            </div>
          </>
        );
      },
      align: 'left'
    }
  ];

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trimStart();
    setSearchValue(value);
    const findRows = rows.filter((row: any) => {
      const searchTerm = value.toLowerCase();
      return row?.contributeType.toLowerCase().includes(searchTerm);
    });
    setSearchedRows(findRows);
  };

  return (
    <Fragment>
      <Box sx={{ p: 1 }}>
        <CustomBreadcrumbs routes={[routes.contributeMaster]} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 42,
            pl: 1,
            pr: 1
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AcUnitIcon fontSize="small" color="primary" />
            <Box mr={1} />
            <Typography variant="h6" color="GrayText">
              {routes.contributeMaster.title}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              id="add-button"
              aria-controls={'add-button'}
              onClick={() => {
                setOpen(true);
                setContributeId(null);
              }}
              size="small"
              variant="contained"
              endIcon={<AddIcon />}
            >
              Add
            </Button>
            <Box mr={1} />
            <Button
              id="actions-button"
              aria-controls={open ? 'actions-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleOpenMenu}
              size="small"
              disabled={selectedRows.length === 0 || deleting}
              variant="outlined"
              endIcon={<ArrowDownIcon />}
            >
              Actions
            </Button>
            <Menu
              id="actions-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleCloseMenu}
              MenuListProps={{
                'aria-labelledby': 'actions-button'
              }}
            >
              <MenuItem
                onClick={() => {
                  setDeleteRecord(selectedRows);
                  setShowDeleteConfirmBox(true);
                  handleCloseMenu();
                }}
              >
                Delete
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <Box pt={1}>
          <DataGrid
            sx={{ height: 'calc(100vh - 150px)' }}
            rowCount={rowCount}
            loading={loading}
            rows={searchedRows.length > 0 ? searchedRows : rows}
            columns={columns}
            rowsPerPageOptions={[5, 20, 50, 100]}
            paginationMode="client"
            pageSize={gridState.limit}
            onPageSizeChange={(newSize) => setGridState((s) => ({ ...s, limit: newSize }))}
            onPageChange={(newPage) => setGridState((s) => ({ ...s, page: newPage }))}
            page={gridState.page}
            checkboxSelection
            getRowId={(row) => row?._id}
            disableSelectionOnClick
            components={{
              Toolbar: CustomToolbar
            }}
            componentsProps={{
              toolbar: {
                value: searchValue,
                onChange: handleSearch,
                onRefresh: fetchContribute
              }
            }}
            onSelectionModelChange={(selection) => {
              setSelectedRows(selection);
            }}
          />
        </Box>
      </Box>
      {open && (
        <ManageContributeDialog
          contributeId={contributeId}
          onClose={() => {
            setOpen(false);
          }}
          handleSucess={() => {
            setOpen(false);
            fetchContribute();
          }}
        />
      )}
      {showDeleteConfirmBox && (
        <ConfirmationDialog
          open={showDeleteConfirmBox}
          message={`Are you sure you want to delete ?`}
          onClose={() => {
            setDeleteRecord(null);
            setShowDeleteConfirmBox(false);
          }}
          onOk={handleDelete}
        />
      )}
    </Fragment>
  );
};

export default Contribute;
