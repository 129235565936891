import React from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

function CommonSkeleton({ lenArray }: any) {
  const theme = useTheme();
  const mode = theme.palette.mode;
  return (
    <Grid container spacing={2} bgcolor={mode === 'dark' ? 'gray.800' : 'white'}>
      {lenArray?.map((i: any, index: any) => (
        <Grid item sm={6} md={6} key={index}>
          <Skeleton variant="text" width="100px" height="16px" />
          <Box marginY={1} />
          <Skeleton width="100%" height="50px" />
        </Grid>
      ))}
    </Grid>
  );
}
export default CommonSkeleton;
