export const userFieldName = {
  FIRST_NAME: 'firstName',
  MIDDLE_NAME: 'middleName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  DOB: 'dob',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirmPassword',
  PHONE: 'phone',
  GENDER: 'gender',
  ROLE: 'role',
  STATUS: 'status',
  HEALTH_CARE_NUMBER: 'healthCareNumber',
  ALLERGIES: 'allergies',
  EMERGENCY_FIRST_NAME: 'emergencyFirstName',
  EMERGENCY_MIDDLE_NAME: 'emergencyMiddleName',
  EMERGENCY_LAST_NAME: 'emergencyLastName',
  EMERGENCY_EMAIL: 'emergencyEmail',
  EMERGENCY_GENDER: 'emergencyGender',
  EMERGENCY_PHONE: 'emergencyPhone',
  EMERGENCY_ADDRESS: 'emergencyAddress',
  EMERGENCY_CITY: 'emergencyCity',
  EMERGENCY_STATE: 'emergencyState',
  EMERGENCY_COUNTRY: 'emergencyCountry',
  EMERGENCY_ZIP_CODE: 'emergencyZipCode'
};

export const userFieldLabel = {
  FIRST_NAME: 'First Name',
  MIDDLE_NAME: 'Middle Name',
  LAST_NAME: 'Last Name',
  EMAIL: 'Email',
  DOB: 'Date of Birth',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  PHONE: 'Phone',
  GENDER: 'Gender',
  ROLE: 'Role',
  STATUS: 'Status',
  HEALTH_CARE_NUMBER: 'Health Care Number',
  ALLERGIES: 'Allergies',
  EMERGENCY_FIRST_NAME: 'First Name',
  EMERGENCY_MIDDLE_NAME: 'Middle Name',
  EMERGENCY_LAST_NAME: 'Last Name',
  EMERGENCY_EMAIL: 'Email',
  EMERGENCY_GENDER: 'Gender',
  EMERGENCY_PHONE: 'Phone',
  EMERGENCY_ADDRESS: 'Address',
  EMERGENCY_CITY: 'City',
  EMERGENCY_STATE: 'State',
  EMERGENCY_COUNTRY: 'Country',
  EMERGENCY_ZIP_CODE: 'Zip Code'
};

export const roleOptions = ['Admin', 'Instructor', 'Parent', 'Student'];

export type UserFormData = {
  _id?: string;
  firstName: string;
  lastName: string;
  email: string;
  dob: Date;
  password: string;
  confirmPassword: string;
  phone: string;
  gender: string;
  role: string;
  status: Boolean;
  healthCareNumber?: string;
  allergies?: string;
  emergencyFirstName?: string;
  emergencyMiddleName?: string;
  emergencyLastName?: string;
  emergencyEmail?: string;
  emergencyGender?: string;
  emergencyPhone?: string;
  emergencyAddress?: string;
  emergencyCity?: string;
  emergencyState?: string;
  emergencyCountry?: string;
  emergencyZipCode?: string;
};
