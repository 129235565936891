import React from 'react';
import {
  Box,
  Button,
  Dialog,
  Grid,
  TextField,
  Autocomplete,
  CircularProgress,
  Typography,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Avatar,
  useTheme
} from '@mui/material';
import { Formik } from 'formik';
import { isMobile, isTablet } from 'react-device-detect';
import CustomDialogHeader from 'src/components/CustomDialog/CustomDialogHeader';
import CustomDialogContent from 'src/components/CustomDialog/CustomDialogContent';
import CustomDialogFooter from 'src/components/CustomDialog/CustomDialogFooter';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import CommonSkeleton from 'src/components/Helpers/CommonSkeleton';
import ImageLists from 'src/pages/Courses/ManageCourses/ImageLists';
import routes from 'src/components/Helpers/Routes';
import { flatMap, map } from 'lodash';
import { useData } from 'src/context/Provider';
import getSymbolFromCurrency from 'currency-symbol-map';
import ImageUploadDialog from './ImageUploadDialog';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import { COURSE_TYPES_ARRAY_SITE1, COURSE_TYPES_ARRAY_SITE2 } from 'src/constants/helpers';
import { siteName } from 'src/config';
const siteType = siteName === 'Site1' ? COURSE_TYPES_ARRAY_SITE1 : COURSE_TYPES_ARRAY_SITE2;
interface ManageCoursesProps {
  onClose: () => void;
  handleSucess: () => void;
  courseId?: any;
}

const ManageCourses = ({ onClose, handleSucess, courseId }: ManageCoursesProps) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const formikRef = React.useRef(null);
  const toastConfig = React.useContext(CustomToastContext);
  const [users, setUsers] = React.useState(null);
  const [fullScreen, setFullScreen] = React.useState(isMobile || isTablet);

  const [initialValues, setInitialValues] = React.useState<any>(null);
  const [readingImage, setReadingImage] = React.useState(false);
  const [image, setImage] = React.useState(null);
  const [imageFileName, setImageFileName] = React.useState(null);
  const [readingIcon, setReadingIcon] = React.useState(false);
  const [iconFileName, setIconFileName] = React.useState(null);
  const [icon, setIcon] = React.useState(null);

  const {
    state: { brand }
  } = useData();

  React.useEffect(() => {
    if (courseId) {
      axiosInstance()
        .get(`${routes.courses.api}/${courseId}`)
        .then(({ data: { data } }) => {
          if (users) {
            data?.instructor?.forEach((e: any) => {
              const _user = users.find((obj: any) => obj._id === e.instructor);
              if (_user) {
                e.name = _user?.firstName + ' ' + _user.lastName;
              }
            });
          }
          setInitialValues({
            courseName: data?.courseName,
            icon: data.icon,
            type: data?.type,
            details: data?.details,
            color: data?.color,
            status: data?.status,
            images: data?.images ?? [],
            instructor: data?.instructor ?? [],
            courseInfo: data?.courseInfo
          });
        })
        .catch((error) => {});
    } else {
      setInitialValues({
        courseName: '',
        type: '',
        icon: '',
        details: '',
        color: '',
        status: true,
        images: [],
        instructor: [],
        courseInfo: ''
      });
    }
  }, [courseId, users]);

  React.useEffect(() => {
    axiosInstance()
      .get('/users?role=Instructor')
      .then((res) => {
        setUsers(res?.data?.data);
      })
      .catch((err) => {});
  }, [courseId]);

  const handleSubmit = async (values: any) => {
    values?.instructor?.forEach((e: any) => {
      if (e?.rate === 0 || e?.rate === '') {
        toastConfig.setToastConfig({
          open: true,
          type: 'success',
          message: 'Please enter instructor rate'
        });
        return false;
      }
    });

    const payload = {
      courseName: values.courseName,
      type: values.type,
      icon: values.icon,
      details: values.details,
      color: values.color,
      status: values.status,
      images: values.images,
      instructor: values.instructor.map((e: any) => {
        return { instructor: e.instructor, rate: e.rate };
      }),
      courseInfo: values.courseInfo
    };

    if (courseId) {
      await axiosInstance()
        .put(`${routes.courses.api}`, { ...payload, _id: courseId })
        .then(({ data }) => {
          toastConfig.setToastConfig({
            open: true,
            type: 'success',
            message: data.message
          });
          handleSucess();
        })
        .catch((err) => {
          toastConfig.setToastConfig(err);
        });
    } else {
      await axiosInstance()
        .post(`${routes.courses.api}`, payload)
        .then(({ data }) => {
          toastConfig.setToastConfig({
            open: true,
            type: 'success',
            message: data.message
          });
          handleSucess();
        })
        .catch((err) => {
          toastConfig.setToastConfig(err);
        });
    }
  };

  const validate = (values: any) => {
    let errors: any = {};
    if (!values?.courseName) {
      errors.courseName = 'Please enter course name';
    }
    if (!values?.type) {
      errors.type = 'Please select type';
    }
    if (!values?.instructor || values?.instructor?.length === 0) {
      errors.instructor = 'Please select instructor';
    }
    return errors;
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen || isMobile || isTablet}
      aria-labelledby="customized-dialog-title"
      onClose={(e, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      open
    >
      {initialValues && users ? (
        <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={handleSubmit} validate={validate} enableReinitialize={true}>
          {({ values, setFieldValue, submitForm, errors, touched, isSubmitting }) => (
            <>
              <CustomDialogHeader
                title={courseId ? 'Edit Course' : 'Add Course'}
                onClose={onClose}
                isMinimized={!fullScreen}
                onMinimizeMaximize={() => {
                  setFullScreen((prevState) => !prevState);
                }}
                showManimizeMaximize={true}
              />
              <CustomDialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      required
                      name={'courseName'}
                      label={'Course Name'}
                      value={values.courseName}
                      onChange={(e) => setFieldValue('courseName', e.target.value)}
                      error={touched['courseName'] && Boolean(errors['courseName'])}
                      helperText={touched['courseName'] && errors['courseName']?.toString()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography color="textSecondary">Logo</Typography>
                    <input
                      disabled={readingIcon}
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="icon-file"
                      // multiple
                      type="file"
                      onChange={(e) => {
                        setReadingIcon(true);
                        const file = e.target.files[0];
                        if (!file) return;
                        setIconFileName(file.name.toString().split('.')[0]);
                        let reader = new FileReader();

                        reader.onload = async (e) => {
                          const result = e.target?.result;
                          setIcon(result);
                          setReadingIcon(false);
                        };

                        if (file) {
                          reader.readAsDataURL(file);
                        }
                      }}
                    />
                    <label htmlFor="icon-file">
                      <Avatar style={{ cursor: 'pointer' }} src={values?.icon ?? ''}>
                        <FormatShapesIcon />
                      </Avatar>
                    </label>
                    <ImageUploadDialog
                      images={values['icon']}
                      setFieldValue={setFieldValue}
                      image={icon}
                      setImage={setIcon}
                      imageFileName={iconFileName}
                      setImageFileName={setIconFileName}
                      setReadingImage={setReadingIcon}
                      fieldName={'icon'}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      size="small"
                      fullWidth
                      options={siteName === 'Site1' ? COURSE_TYPES_ARRAY_SITE1 : COURSE_TYPES_ARRAY_SITE2}
                      isOptionEqualToValue={(option, value) => option === value}
                      getOptionLabel={(option: any) => option.label}
                      // @ts-ignore
                      value={siteType?.find((t) => t.value === values['type'])}
                      onChange={(_, newVal: any) => setFieldValue('type', newVal?.value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          required
                          name={'type'}
                          label={'Type'}
                          error={touched['type'] && Boolean(errors['type'])}
                          helperText={touched['type'] && errors['type']?.toString()}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      name={'courseInfo'}
                      label={'Course Info'}
                      value={values.courseInfo}
                      onChange={(e) => setFieldValue('courseInfo', e.target.value)}
                      error={touched['courseInfo'] && Boolean(errors['courseInfo'])}
                      helperText={touched['courseInfo'] && errors['courseInfo']?.toString()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      size="small"
                      variant="outlined"
                      multiline
                      rows={2}
                      fullWidth
                      name="details"
                      label={'Details'}
                      value={values.details}
                      onChange={(e) => setFieldValue('details', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      size="small"
                      multiple={true}
                      fullWidth
                      options={users || []}
                      value={users.filter((data: any) =>
                        flatMap(values.instructor, (nameObj: any) =>
                          map(nameObj, (instructor) => {
                            return instructor;
                          })
                        ).includes(data._id)
                      )}
                      getOptionLabel={(option: any) => option?.firstName + ' ' + option?.lastName}
                      onChange={(_, newVal: any) => {
                        const newData: any = [];
                        newVal?.forEach((e: any) => {
                          const rate = values.instructor?.find((data: any) => data.instructor === e._id)?.rate;
                          newData.push({ instructor: e._id, name: e?.firstName + ' ' + e?.lastName, rate: rate ?? 0 });
                        });
                        setFieldValue('instructor', newData);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          required
                          name={'instructor'}
                          label={'Instructor'}
                          error={touched['instructor'] && Boolean(errors['instructor'])}
                          helperText={touched['instructor'] && errors['instructor']?.toString()}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          onChange={(e: { target: { checked: any } }) => setFieldValue('status', e.target.checked)}
                          checked={values.status}
                          defaultChecked
                        />
                      }
                      label="Active"
                    />
                  </Grid>
                </Grid>
                <Box pt={3}>
                  <Grid container>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <Box
                        style={{ maxHeight: '350px', overflow: 'auto' }}
                        bgcolor={mode === 'dark' ? 'gray.900' : 'white'}
                        border={1}
                        mt={2}
                        mb={1}
                        borderColor="grey.300"
                        width={'100%'}
                      >
                        <Box p={1}>
                          <Grid container>
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                              <Typography variant="body2">Sr.</Typography>
                            </Grid>
                            <Grid item xs={5} sm={5} md={5} lg={5}>
                              <Typography variant="body2">Instructor</Typography>
                            </Grid>
                            <Grid item xs={5} sm={5} md={5} lg={5}>
                              <Typography variant="body2">Rate</Typography>
                            </Grid>
                          </Grid>
                        </Box>
                        {values?.instructor?.map((item: any, index: any) => (
                          <Box key={index} bgcolor={mode === 'dark' ? 'gray.900' : 'white'} p={1} borderTop={1} borderColor="grey.300" width={'100%'}>
                            <Grid container>
                              <Grid item xs={2} sm={2} md={2} lg={2}>
                                <Typography variant="body2">{index + 1}</Typography>
                              </Grid>
                              <Grid item xs={5} sm={5} md={5} lg={5}>
                                <Typography variant="body2">{item?.name}</Typography>
                              </Grid>
                              <Grid item xs={5} sm={5} md={5} lg={5}>
                                <TextField
                                  id="standard-basic"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  type="number"
                                  autoComplete="off"
                                  style={{ margin: 0 }}
                                  value={item?.rate}
                                  onChange={(event) => {
                                    if (parseFloat(event.target.value) < 0) return;
                                    const data = [...values?.instructor];
                                    data[index].rate = event.target.value === '' ? 0 : parseFloat(event.target.value);
                                    setFieldValue('instructor', data);
                                  }}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">{getSymbolFromCurrency(brand?.currency)}</InputAdornment>
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Typography color="textSecondary">Images</Typography>
                    <input
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="multiple-images-button"
                      // multiple
                      type="file"
                      onChange={(e) => {
                        setReadingImage(true);
                        const file = e.target.files[0];
                        if (!file) return;
                        setImageFileName(file.name.toString().split('.')[0]);
                        let reader = new FileReader();

                        reader.onload = async (e) => {
                          const result = e.target?.result;
                          setImage(result);
                          setReadingImage(false);
                        };

                        if (file) {
                          reader.readAsDataURL(file);
                        }
                      }}
                    />
                    <label htmlFor="multiple-images-button">
                      <Button disabled={readingImage} variant="contained" color="primary" component="span">
                        Upload
                      </Button>
                    </label>
                    <ImageLists
                      images={values['images']}
                      setFieldValue={setFieldValue}
                      image={image}
                      setImage={setImage}
                      imageFileName={imageFileName}
                      setImageFileName={setImageFileName}
                      setReadingImage={setReadingImage}
                    />
                  </Grid>
                </Grid>
              </CustomDialogContent>
              <CustomDialogFooter>
                <Button variant="outlined" size="small" onClick={onClose} disabled={isSubmitting}>
                  Close
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={submitForm}
                  disabled={isSubmitting}
                  endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                >
                  Save
                </Button>
              </CustomDialogFooter>
            </>
          )}
        </Formik>
      ) : (
        <Box height={500}>
          <CommonSkeleton lenArray={[...Array(10).keys()]} />
        </Box>
      )}
    </Dialog>
  );
};

export default ManageCourses;
