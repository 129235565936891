import { useState, useContext, useEffect } from 'react';
import { Button, Dialog, TextField, Grid, CircularProgress, FormControlLabel, Box, Checkbox, useTheme } from '@mui/material';
import { Formik } from 'formik';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { CustomDialogHeader, CustomDialogContent, CustomDialogFooter } from 'src/components/CustomDialog';
import { isMobile, isTablet } from 'react-device-detect';
import CommonSkeleton from 'src/components/Helpers/CommonSkeleton';
import routes from 'src/components/Helpers/Routes';

const ManageContributeDialog = ({ onClose, handleSucess, contributeId }: any) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toastConfig = useContext(CustomToastContext);
  const [initialValues, setInitialValues] = useState(null);
  const [fullScreen, setFullScreen] = useState(isMobile || isTablet);

  useEffect(() => {
    if (contributeId) {
      axiosInstance()
        .get(`${routes.contributeMaster.api}/${contributeId}`)
        .then(({ data: { data } }) => {
          setInitialValues({
            contributeType: data?.contributeType,
            amount: data?.amount,
            private: data?.private,
            status: data?.status
          });
        })
        .catch((error) => {});
    } else {
      setInitialValues({
        contributeType: '',
        amount: 0,
        private: false,
        status: true
      });
    }
  }, [contributeId]);

  const handleSubmit = (values: any) => {
    if (contributeId) {
      setIsSubmitting(true);
      axiosInstance()
        .put(`${routes.contributeMaster.api}`, { ...values, _id: contributeId })
        .then(({ data }) => {
          setIsSubmitting(false);
          toastConfig.setToastConfig({
            open: true,
            type: 'success',
            message: data.message
          });
          handleSucess();
        })
        .catch((error) => {
          toastConfig.setToastConfig(error);
          setIsSubmitting(false);
        });
    } else {
      setIsSubmitting(true);
      axiosInstance()
        .post(`${routes.contributeMaster.api}`, values)
        .then(({ data }) => {
          setIsSubmitting(false);
          toastConfig.setToastConfig({
            open: true,
            type: 'success',
            message: data.message
          });
          handleSucess();
        })
        .catch((error) => {
          toastConfig.setToastConfig(error);
          setIsSubmitting(false);
        });
    }
  };

  const validate = (values: any) => {
    const errors: any = {};
    if (!values.contributeType) {
      errors.contributeType = 'Please enter contribute type';
    }
    return errors;
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen || isMobile || isTablet}
      aria-labelledby="customized-dialog-title"
      onClose={(e, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      open
    >
      {initialValues ? (
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
          {({ submitForm, values, errors, touched, setFieldValue }) => (
            <>
              <CustomDialogHeader
                title={contributeId ? 'Edit' : 'Add'}
                onClose={onClose}
                isMinimized={!fullScreen}
                onMinimizeMaximize={() => {
                  setFullScreen((prevState) => !prevState);
                }}
                showManimizeMaximize={true}
              />
              <CustomDialogContent>
                <Box sx={{ pt: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        size="small"
                        required
                        name={'contributeType'}
                        label={'Donation Type'}
                        value={values.contributeType}
                        onChange={(e) => setFieldValue('contributeType', e.target.value)}
                        error={touched.contributeType && Boolean(errors.contributeType)}
                        helperText={(touched.contributeType && errors.contributeType)?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        size="small"
                        variant="outlined"
                        fullWidth
                        type="number"
                        label={'Amount'}
                        required
                        name="amount"
                        value={values.amount}
                        onChange={(e) => {
                          const value = parseFloat(e.target.value);
                          setFieldValue('amount', !isNaN(value) ? value : 0);
                        }}
                        error={touched['amount'] && Boolean(errors['amount'])}
                        helperText={touched['amount'] && errors['amount']?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            onChange={(e: { target: { checked: any } }) => setFieldValue('private', e.target.checked)}
                            checked={values.private}
                            defaultChecked
                          />
                        }
                        label="Private"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            onChange={(e: { target: { checked: any } }) => setFieldValue('status', e.target.checked)}
                            checked={values.status}
                            defaultChecked
                          />
                        }
                        label="Active"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </CustomDialogContent>
              <CustomDialogFooter>
                <Button variant="outlined" onClick={onClose} size="small">
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={submitForm}
                  size="small"
                  disabled={isSubmitting}
                  endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                >
                  Save
                </Button>
              </CustomDialogFooter>
            </>
          )}
        </Formik>
      ) : (
        <Box height={500} bgcolor={mode === 'dark' ? 'gray.900' : 'white'}>
          <CommonSkeleton lenArray={[...Array(10).keys()]} />
        </Box>
      )}
    </Dialog>
  );
};

export default ManageContributeDialog;
