import { useState, useEffect, useContext, Fragment, useCallback } from 'react';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import CustomBreadcrumbs from 'src/components/Helpers/CustomBreadcrumbs';
import routes from 'src/components/Helpers/Routes';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import { getQueryParams } from 'src/utils';
import { axiosInstance } from 'src/axios';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { CustomToastContext } from 'src/context/CustomToastContext';
import CustomToolbar from 'src/components/Helpers/CustomToolbar';
import moment from 'moment-timezone';
import { useData } from 'src/context/Provider';

const Donation = () => {
  const { setToastConfig } = useContext(CustomToastContext);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [gridState, setGridState] = useState({
    limit: 20,
    page: 0
  });
  const [contributeType, setContributeType] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const {
    state: { brand }
  } = useData();

  useEffect(() => {
    let timeout = setTimeout(fetchDonation, 500);
    return () => clearTimeout(timeout);
  }, [gridState, searchValue, selectedOption]);

  useEffect(() => {
    fetchOptionsData();
  }, []);

  const fetchDonation = () => {
    let query = getQueryParams(gridState);
    setLoading(true);
    if (selectedOption) {
      query = `${query}contributeType=${selectedOption}`;
    }
    axiosInstance()
      .get(`${routes.donation.api}?search=${searchValue}&${query}`)
      .then(({ data: { data, count } }) => {
        setRowCount(count);
        setRows(data);
        setLoading(false);
      })
      .catch((err) => {
        setToastConfig(err);
        setLoading(false);
      });
  };

  const fetchOptionsData = () => {
    setLoadingOptions(true);
    axiosInstance()
      .get(`${routes.contributeMaster.api}`)
      .then(({ data: { data, count } }) => {
        setContributeType(data);
        setLoadingOptions(false);
      })
      .catch((err) => {
        setToastConfig(err);
        setLoadingOptions(false);
      });
  };

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value.trimStart());
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'orderNumber',
      headerName: 'Order Number',
      width: 150
    },
    {
      field: 'contributeTo',
      headerName: 'Donation Type',
      width: 150,
      valueGetter: (params) => {
        return params?.value?.contributeType;
      }
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 150
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 210
    },
    {
      field: 'phone',
      headerName: 'Phone',
      width: 150
    },
    {
      field: 'amount',
      headerName: `Amount ${brand?.currency}`,
      width: 150
    },
    {
      field: 'createDate',
      headerName: 'Date',
      width: 150,
      valueGetter: (params) => {
        return moment(params?.value).format('MM/DD/YYYY');
      }
    },
    {
      field: 'paymentId',
      headerName: 'Payment Id',
      width: 150
    },
    {
      field: 'paymentStatus',
      headerName: 'Payment Status',
      width: 150
    },
    {
      field: 'jatha',
      headerName: 'Jatha',
      width: 150
    }
  ];

  return (
    <Fragment>
      <Box sx={{ p: 1 }}>
        <CustomBreadcrumbs routes={[routes.donation]} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 42,
            pl: 1,
            pr: 1
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <VolunteerActivismIcon fontSize="small" color="primary" />
            <Box mr={1} />
            <Typography variant="h6" color="GrayText">
              {routes.donation.title}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: '4px' }}>
          <Autocomplete
            loading={loadingOptions || loading}
            options={contributeType}
            onChange={(_, newVal: any) => {
              setSelectedOption(newVal ? newVal._id : '');
            }}
            getOptionLabel={(option) => option.contributeType}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            sx={{ width: 280 }}
            renderInput={(params) => <TextField {...params} label={'Donation Master'} size="small" />}
          />
        </Box>
        <Box pt={1}>
          <DataGrid
            sx={{ height: 'calc(100vh - 150px)' }}
            rowCount={rowCount}
            loading={loading}
            rows={rows}
            columns={columns}
            pageSize={gridState.limit}
            onPageSizeChange={(pageSize) => setGridState((s) => ({ ...s, limit: pageSize }))}
            page={gridState.page}
            onPageChange={(page) => setGridState((s) => ({ ...s, page }))}
            rowsPerPageOptions={[5, 20, 50, 100]}
            checkboxSelection
            getRowId={(row) => row?._id}
            disableSelectionOnClick
            paginationMode="server"
            components={{
              Toolbar: CustomToolbar
            }}
            componentsProps={{
              toolbar: {
                value: searchValue,
                onChange: handleSearch,
                onRefresh: fetchDonation,
                hasExport: true,
                exportApi: routes.donation.api,
                fileName: 'SNEI-Donation',
                header: ['Order Number', 'Contribute Type', 'Name', 'Email', 'Phone', 'Amount', 'Date', 'Payment Id', 'Payment Status']
              }
            }}
            onSelectionModelChange={(selection) => {
              setSelectedRows(selection);
            }}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default Donation;
