import { useState, useContext, useEffect } from 'react';
import { Button, Dialog, TextField, Grid, CircularProgress, Box, useTheme, FormControlLabel, Checkbox, Avatar, Typography } from '@mui/material';
import { Formik } from 'formik';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { CustomDialogHeader, CustomDialogContent, CustomDialogFooter } from 'src/components/CustomDialog';
import { isMobile, isTablet } from 'react-device-detect';
import CommonSkeleton from 'src/components/Helpers/CommonSkeleton';
import routes from 'src/components/Helpers/Routes';
import { isValidUrl } from 'src/utils';
import ImageUploadDialog from '../Courses/ManageCourses/ImageUploadDialog';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';

const ManageBroadcastDialog = ({ onClose, handleSucess, data = null }: any) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toastConfig = useContext(CustomToastContext);
  const [initialValues, setInitialValues] = useState(null);
  const [fullScreen, setFullScreen] = useState(isMobile || isTablet);

  const [studentLoading, setStudentLoading] = useState(false);
  const [readingImage, setReadingImage] = useState(false);
  const [image, setImage] = useState(null);
  const [imageFileName, setImageFileName] = useState(null);
  const [readingIcon, setReadingIcon] = useState(false);
  const [iconFileName, setIconFileName] = useState(null);
  const [icon, setIcon] = useState(null);

  useEffect(() => {
    setStudentLoading(true);
    if (data && data._id) {
      axiosInstance()
        .get(`${routes.broadcast.api}/${data._id}`)
        .then(({ data: { data } }) => {
          setInitialValues({
            title: data?.title,
            description: data?.description,
            link: data?.link,
            icon: data?.icon,
            active: data?.active
          });
          setStudentLoading(false);
        })
        .catch((error) => {
          toastConfig.setToastConfig(error);
          setStudentLoading(false);
        });
    } else {
      setInitialValues({
        title: '',
        description: '',
        link: '',
        icon: '',
        active: false
      });
      setStudentLoading(false);
    }
  }, []);

  const handleSubmit = (values: any) => {
    if (data && data._id) {
      setIsSubmitting(true);
      axiosInstance()
        .put(`${routes.broadcast.api}`, { ...values, _id: data._id })
        .then(({ data }) => {
          setIsSubmitting(false);
          toastConfig.setToastConfig({
            open: true,
            type: 'success',
            message: data.message
          });
          handleSucess();
        })
        .catch((error) => {
          toastConfig.setToastConfig(error);
          setIsSubmitting(false);
        });
    } else {
      setIsSubmitting(true);
      axiosInstance()
        .post(`${routes.broadcast.api}`, values)
        .then(({ data }) => {
          setIsSubmitting(false);
          toastConfig.setToastConfig({
            open: true,
            type: 'success',
            message: data.message
          });
          handleSucess();
        })
        .catch((error) => {
          toastConfig.setToastConfig(error);
          setIsSubmitting(false);
        });
    }
  };

  const validate = (values: any) => {
    const errors: any = {};
    if (!values.title) errors.title = 'Please enter title';

    if (!values.description) errors.description = 'Please enter description';

    if (values.link?.length && !isValidUrl(values.link)) errors.link = 'Please enter valid URL';

    return errors;
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen || isMobile || isTablet}
      aria-labelledby="customized-dialog-title"
      onClose={(e, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      open
    >
      {!studentLoading && initialValues ? (
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
          {({ submitForm, values, errors, touched, setFieldValue }) => (
            <>
              <CustomDialogHeader
                title={'Create Broadcast'}
                onClose={onClose}
                isMinimized={!fullScreen}
                onMinimizeMaximize={() => {
                  setFullScreen((prevState) => !prevState);
                }}
                showManimizeMaximize={true}
              />
              <CustomDialogContent>
                <Box sx={{ pt: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        size="small"
                        required
                        name={'title'}
                        label={'Title'}
                        value={values.title}
                        onChange={(e) => setFieldValue('title', e.target.value)}
                        error={touched.title && Boolean(errors.title)}
                        helperText={(touched.title && errors.title)?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        size="small"
                        required
                        name={'description'}
                        label={'Description'}
                        value={values.description}
                        onChange={(e) => setFieldValue('description', e.target.value)}
                        error={touched.description && Boolean(errors.description)}
                        helperText={(touched.description && errors.description)?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        size="small"
                        name={'link'}
                        label={'Link'}
                        value={values.link}
                        onChange={(e) => setFieldValue('link', e.target.value)}
                        error={touched.link && Boolean(errors.link)}
                        helperText={(touched.link && errors.link)?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography color="textSecondary">Image</Typography>
                      <input
                        disabled={readingIcon}
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="icon-file"
                        // multiple
                        type="file"
                        onChange={(e) => {
                          setReadingIcon(true);
                          const file = e.target.files[0];
                          if (!file) return;
                          setIconFileName(file.name.toString().split('.')[0]);
                          let reader = new FileReader();

                          reader.onload = async (e) => {
                            const result = e.target?.result;
                            setIcon(result);
                            setReadingIcon(false);
                          };

                          if (file) {
                            reader.readAsDataURL(file);
                          }
                        }}
                      />
                      <label htmlFor="icon-file">
                        <Avatar style={{ cursor: 'pointer' }} src={values?.icon ?? ''}>
                          <FormatShapesIcon />
                        </Avatar>
                      </label>
                      <ImageUploadDialog
                        images={values['icon']}
                        setFieldValue={setFieldValue}
                        image={icon}
                        setImage={setIcon}
                        imageFileName={iconFileName}
                        setImageFileName={setIconFileName}
                        setReadingImage={setReadingIcon}
                        fieldName={'icon'}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            onChange={(e: { target: { checked: any } }) => setFieldValue('active', e.target.checked)}
                            checked={values.active}
                            defaultChecked
                          />
                        }
                        label="Active"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </CustomDialogContent>
              <CustomDialogFooter>
                <Button variant="outlined" onClick={onClose} size="small">
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={submitForm}
                  size="small"
                  disabled={isSubmitting}
                  endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                >
                  Save
                </Button>
              </CustomDialogFooter>
            </>
          )}
        </Formik>
      ) : (
        <Box height={500} bgcolor={mode === 'dark' ? 'gray.900' : 'white'}>
          <CommonSkeleton lenArray={[...Array(10).keys()]} />
        </Box>
      )}
    </Dialog>
  );
};

export default ManageBroadcastDialog;
