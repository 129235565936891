import { Autocomplete, Box, TextField, Typography, Grid, InputLabel, FormControl, MenuItem, Select, Tooltip } from '@mui/material';
import React from 'react';
import CustomBreadcrumbs from 'src/components/Helpers/CustomBreadcrumbs';
import routes from 'src/components/Helpers/Routes';
import AssessmentIcon from '@mui/icons-material/Assessment';
import moment from 'moment';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { getQueryParams } from 'src/utils';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import CustomToolbar from 'src/components/Helpers/CustomToolbar';

type Props = {};

export type GlobalFiltersType = {
  from: Date;
  to: Date;
};

const AttendanceReport = (props: Props) => {
  const [loadingOptions, setLoadingOptions] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [instructors, setInstructors] = React.useState([]);

  const [timeFrame, setTimeFrame] = React.useState<any>('custom');

  const { setToastConfig } = React.useContext(CustomToastContext);
  const [searchValue, setSearchValue] = React.useState('');
  const [count, setCount] = React.useState(0);
  const [selectedOptions, setSelectedOptions] = React.useState({
    instructor: null,
    student: null,
    course: null,
    reportType: null
  });

  const [courses, setCourses] = React.useState([]);
  const [students, setStudents] = React.useState([]);

  const [attendanceData, setAttendanceData] = React.useState([]);

  const [globalFilters, setGlobalFilters] = React.useState<GlobalFiltersType>({
    from: new Date(moment().startOf('month').calendar()),
    to: new Date(moment().endOf('month').calendar())
  });

  const [reportType, setReportType] = React.useState('Individual');

  const [gridState, setGridState] = React.useState({
    limit: 20,
    page: 0
  });

  const fetchOptionsData = async () => {
    setLoadingOptions(true);
    try {
      let {
        data: { data: instructors }
      } = await axiosInstance().get(`${routes.users.api}?role=Instructor`);
      let {
        data: { data: students }
      } = await axiosInstance().get(`${routes.users.api}?role=Student`);
      let {
        data: { data: courses }
      } = await axiosInstance().get(`${routes.courses.api}`);
      instructors = instructors.map((user: any) => ({
        ...user,
        fullName: `${user.firstName} ${user.lastName}`
      }));
      students = students.map((user: any) => ({
        ...user,
        fullName: `${user.firstName} ${user.lastName}`
      }));
      setCourses(courses);
      setInstructors(instructors);
      setStudents(students);
      setLoadingOptions(false);
    } catch (error) {
      setLoadingOptions(false);
      setToastConfig(error);
    }
  };

  React.useEffect(() => {
    if (
      selectedOptions?.course ||
      selectedOptions?.instructor ||
      selectedOptions?.reportType ||
      selectedOptions.student ||
      searchValue ||
      reportType
    ) {
      let timeout = setTimeout(fetchattendanceReport, 500);
      return () => clearTimeout(timeout);
    }
  }, [gridState, searchValue]);

  React.useEffect(() => {
    fetchattendanceReport();
  }, [selectedOptions, globalFilters]);

  const getParams = () => {
    let params: string = '&sortBy=date&orderBy=desc&';
    if (selectedOptions?.instructor) {
      params = `${params}instructor=${selectedOptions.instructor._id}&`;
    }
    if (selectedOptions?.course) {
      params = `${params}course=${selectedOptions.course._id}&`;
    }
    if (selectedOptions?.student) {
      params = `${params}student=${selectedOptions.student._id}&`;
    }
    if (globalFilters) {
      params = `${params}startdate=${globalFilters.from}&enddate=${globalFilters.to}`;
    }
    return params;
  };

  React.useEffect(() => {
    switch (timeFrame) {
      case '1-month':
        setGlobalFilters({
          from: new Date(moment().subtract('1', 'month').calendar()),
          to: new Date()
        });
        break;

      case '3-months':
        setGlobalFilters({
          from: new Date(moment().subtract('3', 'months').calendar()),
          to: new Date()
        });
        break;

      case '6-months':
        setGlobalFilters({
          from: new Date(moment().subtract('6', 'months').calendar()),
          to: new Date()
        });
        break;

      case '1-year':
        setGlobalFilters({
          from: new Date(moment().subtract('1', 'year').calendar()),
          to: new Date()
        });
        break;

      default:
        break;
    }
  }, [timeFrame]);

  React.useEffect(() => {
    fetchOptionsData();
    fetchattendanceReport();
  }, []);

  React.useEffect(() => {
    fetchattendanceReport();
  }, [reportType]);

  const fetchattendanceReport = async () => {
    let query = getQueryParams(gridState);
    query = query + getParams();
    setLoading(true);
    try {
      if (reportType !== 'Individual') {
        let {
          data: { data }
        } = await axiosInstance().get(`/student-attendance/report-groupby?search=${searchValue}&${query}`);
        const attendanceGroup = data?.data?.map((d: any, index: number) => ({
          ...d,
          id: d?._id + '-' + index,
          courseName: d?.course?.optionLabel,
          instructorName: d?.instructor.optionLabel,
          studentName: d?.student.optionLabel,
          orderNumber: d?.order.optionLabel
        }));
        setAttendanceData(attendanceGroup);
        setCount(attendanceGroup?.length);
      } else {
        let {
          data: { data }
        } = await axiosInstance().get(`/student-attendance/report?search=${searchValue}&${query}`);
        const attendance = data?.data?.map((d: any, index: number) => ({
          ...d,
          id: d?._id + '-' + index,
          courseName: d?.course?.optionLabel,
          instructorName: d?.instructor?.optionLabel,
          studentName: d?.student?.optionLabel,
          orderNumber: d?.order?.optionLabel
        }));
        setAttendanceData(attendance || []);
        setCount(data?.count);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setToastConfig(error);
    }
  };

  const handleSearch = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value.trimStart());
  }, []);

  const attendanceColor: any = {
    present: '#4caf50',
    absent: '#f44336',
    excuse: '#ff9800'
  };

  const individualColumns: GridColDef[] = [
    { field: 'orderNumber', headerName: 'Order Number', width: 180 },
    { field: 'courseName', headerName: 'Course', width: 150 },
    { field: 'instructorName', headerName: 'Instructor', width: 250 },
    { field: 'studentName', headerName: 'Students Name', width: 300 },
    {
      field: 'startDate',
      headerName: 'Class Date',
      width: 250,
      valueGetter: (params: GridValueGetterParams) => {
        return `${moment(params.row.startDate).format('MM/DD/YYYY')}`;
      }
    },
    {
      field: 'startTime',
      headerName: 'Class Time',
      width: 250,
      valueGetter: (params: GridValueGetterParams) => {
        return `${moment(params.row.startTime).format('h:mm A')}`;
      }
    },
    {
      field: 'date',
      headerName: 'Attendance Date Time',
      width: 250,
      valueGetter: (params: GridValueGetterParams) => {
        return `${moment(params.row.date).format('MM/DD/YYYY hh:mm A')}`;
      }
    },
    {
      field: 'transactionDate',
      headerName: 'Transaction Date Time',
      width: 250,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.transactionDate ? `${moment(params.row.transactionDate).format('MM/DD/YYYY hh:mm A')}` : '---';
      }
    }
  ];

  const columns: GridColDef[] = [
    { field: 'orderNumber', headerName: 'Order Number', width: 180 },
    { field: 'courseName', headerName: 'Course', width: 150 },
    { field: 'instructorName', headerName: 'Instructor', width: 250 },
    { field: 'studentName', headerName: 'Students Name', width: 300 },
    {
      field: 'attendance',
      headerName: 'Last 7 Days Attendance',
      width: 250,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'space-evenly', height: 23, width: 250 }}>
            {params.row?.lastWeekAttendanceDates?.map((a: any, index: number) => {
              return (
                <Tooltip
                  title={`${a?.absentReason || a?.status} (${moment(a?.date).format('MMMM Do YYYY')})`}
                  arrow
                  disableInteractive
                  placement="top"
                >
                  <div
                    style={{
                      width: 27,
                      height: '100%',
                      backgroundColor: attendanceColor[a?.status],
                      marginRight: '5px',
                      borderRadius: '5px'
                    }}
                  ></div>
                </Tooltip>
              );
            })}
          </div>
        );
      }
    }
  ];

  return (
    <React.Fragment>
      <Box sx={{ p: 1 }}>
        <CustomBreadcrumbs routes={[routes.attendanceReport]} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 42,
            pl: 1,
            pr: 1
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AssessmentIcon fontSize="small" color="primary" />
            <Box mr={1} />
            <Typography variant="h6" color="GrayText">
              {routes.attendanceReport.title}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: '4px' }}>
          <Autocomplete
            loading={loadingOptions || loading}
            options={instructors}
            onChange={(_, newVal: any) => {
              setSelectedOptions((ps) => ({ ...ps, instructor: newVal ? newVal : '' }));
            }}
            getOptionLabel={(option) => option.fullName}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            sx={{ width: 280 }}
            renderInput={(params) => <TextField {...params} label={'Instructors'} size="small" />}
          />
          <Box mr={1} />
          <Autocomplete
            loading={loadingOptions || loading}
            options={courses}
            onChange={(_, newVal: any) => {
              setSelectedOptions((ps) => ({ ...ps, course: newVal }));
            }}
            getOptionLabel={(option) => option.courseName}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            sx={{ width: 280 }}
            renderInput={(params) => <TextField {...params} label={'Courses'} size="small" />}
          />
          <Box mr={1} />
          <Autocomplete
            loading={loadingOptions || loading}
            options={students}
            onChange={(_, newVal: any) => {
              setSelectedOptions((ps) => ({ ...ps, student: newVal ? newVal : '' }));
            }}
            getOptionLabel={(option) => option.fullName}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            sx={{ width: 280 }}
            renderInput={(params) => <TextField {...params} label={'Students'} size="small" />}
          />
          <Box mr={1} />
          <Autocomplete
            options={['Individual', 'Group By']}
            // @ts-ignore
            value={reportType}
            onChange={(_, newVal: any) => {
              if (newVal) {
                setReportType(newVal);
              }
            }}
            getOptionLabel={(option: any) => option}
            sx={{ width: 280 }}
            renderInput={(params) => <TextField {...params} label={'Report Type'} size="small" />}
          />
          <Box mr={1} />
          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={reportType === 'Individual' ? 4 : 12}>
                <FormControl fullWidth size="small" variant="outlined">
                  <InputLabel id="duration">Select Duration</InputLabel>
                  <Select labelId="duration" id="time-duration" value={timeFrame} onChange={(e) => setTimeFrame(e.target.value)}>
                    <MenuItem value={'1-year'}>Last 1 Year</MenuItem>
                    <MenuItem value={'6-months'}>Last 6 Months</MenuItem>
                    <MenuItem value={'3-months'}>Last 3 Months</MenuItem>
                    <MenuItem value={'1-month'}>Last 1 Month</MenuItem>
                    <MenuItem value={'custom'}>Custom</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {reportType === 'Individual' && (
                <Grid item xs={6} sm={4}>
                  <DesktopDatePicker
                    label={'Start Date'}
                    disabled={timeFrame !== 'custom'}
                    value={globalFilters.from}
                    onChange={(date) => {
                      setGlobalFilters({ ...globalFilters, from: date });
                    }}
                    inputFormat="MM/DD/YYYY"
                    renderInput={(params) => <TextField {...params} name="startDate" size="small" variant="outlined" fullWidth />}
                  />
                </Grid>
              )}
              {reportType === 'Individual' && (
                <Grid item xs={6} sm={4}>
                  <DesktopDatePicker
                    label={'End Date'}
                    disabled={timeFrame !== 'custom'}
                    value={globalFilters.to}
                    onChange={(date) => {
                      setGlobalFilters({ ...globalFilters, to: date });
                    }}
                    inputFormat="MM/DD/YYYY"
                    renderInput={(params) => <TextField {...params} name="startDate" size="small" variant="outlined" fullWidth />}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box mt={1}>
          <DataGrid
            sx={{ height: 'calc(100vh - 190px)' }}
            loading={loading}
            rows={attendanceData}
            columns={reportType === 'Individual' ? individualColumns : columns}
            pageSize={gridState.limit}
            pagination
            paginationMode={'server'}
            page={gridState.page}
            onPageSizeChange={(pageSize) => setGridState((s) => ({ ...s, limit: pageSize }))}
            onPageChange={(page) => {
              setGridState((s) => ({ ...s, page }));
            }}
            rowCount={count}
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{
              toolbar: {
                value: searchValue,
                onChange: handleSearch,
                onRefresh: fetchattendanceReport,
                hasExport: true,
                exportApi: `${routes.attendanceReport.api}/report?${getParams()}`,
                fileName: 'Attendance Report',
                header: ['Order Number', 'Course', 'Instructor', 'Students Name', 'Attendance Date Time', 'Transaction Date Time']
              }
            }}
            rowsPerPageOptions={[5, 20, 50, 100]}
            disableSelectionOnClick
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default AttendanceReport;
