import React from 'react';
import { Toolbar, Box } from '@mui/material';
import Sidebar from './Sidebar';

const Layout = ({ children }: { children: any }) => {
  const [toggleDrawer, setToggleDrawer] = React.useState(false);
  return (
    <div>
      <Sidebar toggleDrawer={toggleDrawer} setToggleDrawer={setToggleDrawer} />
      <Toolbar />
      <Box sx={{ display: 'flex' }}>
        <Toolbar sx={{ width: 48 }} />
        <Box sx={{ flexGrow: 1, width: '100%', overflow: 'hidden' }}>{children}</Box>
      </Box>
    </div>
  );
};

export default Layout;
