import Link, { LinkProps } from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import { startCase } from "lodash";

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
);

const CustomBreadcrumbs = ({ routes }: any) => {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <LinkRouter underline="hover" color="inherit" to="/dashboard">
        Home
      </LinkRouter>
      {routes?.map((value: any, index: any) => {
        const last = index === routes.length - 1;
        return last ? (
          <Typography color="text.primary" key={value.path}>
            {value.title}
          </Typography>
        ) : (
          <LinkRouter
            underline="hover"
            color="inherit"
            to={value.path}
            key={value.path}
          >
            {value.title}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
