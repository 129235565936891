import { Box } from '@mui/system';
import React from 'react';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { useState, useEffect, useContext, Fragment } from 'react';
import { axiosInstance } from 'src/axios';
import { useNavigate } from 'react-router-dom';
import { Button, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import MuiPhoneInput from 'material-ui-phone-number';

import ManageUpdatePassword from './ManageUpdatePassword';

const Profile = () => {
  const toastConfig = React.useContext(CustomToastContext);
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [changePass, setChangePass] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    axiosInstance()
      .get(`/auth/me`)
      .then(({ data: { data } }) => {
        const user = data?.user;
        setProfileData({
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          phone: user?.phone,
          gender: user?.gender || '',
          address: user?.address,
          city: user?.city,
          state: user?.state,
          country: user?.country,
          zipCode: user?.zipCode
        });
        setLoading(false);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
        setLoading(false);
      });
  };

  const handleEdit = (data: any) => {
    setUpdating(true);
    axiosInstance()
      .post(`/auth/profile`, data)
      .then(({data}) => {
        setEditMode(false);
        setUpdating(false);
        toastConfig.setToastConfig({
          open: true,
          type: 'success',
          message: data.message
      });
        fetchData();
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
        setUpdating(false);
      });
  };

  const validateForm = (values: any) => {
    const errors: any = {};
    if (!values.firstName) {
      errors.firstName = 'First Name is required';
    }
    if (!values.lastName) {
      errors.lastName = 'Last Name is required';
    }
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.phone) {
      errors.phone = 'Phone number is required';
    } else {
      let simpleStr = values?.phone.replaceAll(' ', '');
      simpleStr = simpleStr.replaceAll('(', '');
      simpleStr = simpleStr.replaceAll(')', '');
      simpleStr = simpleStr.replaceAll('-', '');
      const isValid = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(simpleStr);

      if (!isValid) {
        errors.phone = 'Phone number is not valid';
      }
    }
    return errors;
  };

  return (
    <Box className="container" pt={5}>
      {profileData && !loading && (
        <Formik
          initialValues={{
            firstName: profileData?.['firstName'] || '',
            lastName: profileData?.['lastName'] || '',
            email: profileData?.['email'] || '',
            gender: profileData?.['gender'] || '',
            phone: profileData?.['phone'] || '',
            address: profileData?.['address'] || '',
            city: profileData?.['city'] || '',
            state: profileData?.['state'] || '',
            country: profileData?.['country'] || '',
            zipCode: profileData?.['zipCode'] || ''
          }}
          validate={validateForm}
          onSubmit={handleEdit}
        >
          {({ submitForm, values, errors, touched, setFieldValue, isSubmitting }) => (
            <Form>
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5>Profile</h5>
                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {editMode ? (
                    <>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={() => {
                          fetchData();
                          setEditMode(!editMode);
                        }}
                      >
                        Cancel
                      </Button>
                      <Box ml={2} />
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={submitForm}
                        disabled={updating}
                        endIcon={updating && <CircularProgress size={20} />}
                      >
                        Save
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button variant="contained" size="small" color="primary" onClick={() => setEditMode(!editMode)} disabled={updating}>
                        Edit
                      </Button>
                      <Box ml={2} />
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => {
                          setChangePass(!changePass);
                        }}
                      >
                        Change Password
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
              <Box pt={3} pb={3}>
                {profileData && !loading ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        key={'fName'}
                        label={'First Name'}
                        value={values['firstName'] || ''}
                        variant="outlined"
                        fullWidth
                        required
                        disabled={!editMode}
                        onChange={(e) => {
                          setFieldValue('firstName', e.target.value);
                        }}
                        error={touched['firstName'] && Boolean(errors['firstName'])}
                        helperText={touched['firstName'] && Boolean(errors['firstName']) && `${errors['firstName']}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        key={'fName'}
                        label={'Last Name'}
                        required
                        value={values['lastName'] || ''}
                        variant="outlined"
                        fullWidth
                        disabled={!editMode}
                        onChange={(e) => {
                          setFieldValue('lastName', e.target.value);
                        }}
                        error={touched['lastName'] && Boolean(errors['lastName'])}
                        helperText={touched['lastName'] && Boolean(errors['lastName']) && `${errors['lastName']}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        key={'email'}
                        label={'Email'}
                        type={'email'}
                        required
                        value={values['email']}
                        variant="outlined"
                        fullWidth
                        disabled={!editMode}
                        onChange={(e) => {
                          setFieldValue('email', e.target.value);
                        }}
                        error={touched['email'] && Boolean(errors['email'])}
                        helperText={touched['email'] && Boolean(errors['email']) && `${errors['email']}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MuiPhoneInput
                        defaultCountry={'us'}
                        disableAreaCodes
                        countryCodeEditable={true}
                        enableLongNumbers={false}
                        fullWidth
                        variant="outlined"
                        type="phone"
                        required
                        label="Phone"
                        name="phone"
                        dropdownClass='phone_dropdown'
                        disabled={!editMode}
                        onChange={(val: any) => setFieldValue('phone', val.toString())}
                        value={values['phone']}
                        error={touched['phone'] && Boolean(errors['phone'])}
                        helperText={touched['phone'] && Boolean(errors['phone']) && `${errors['phone']}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl disabled={!editMode} error={touched['gender'] && Boolean(errors['gender'])} required>
                        <FormLabel id="gender-value">Gender</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="gender-value"
                          defaultValue={values['gender'] || ''}
                          onChange={(e) => {
                            setFieldValue('gender', e.target.value);
                          }}
                        >
                          <FormControlLabel checked={values['gender'] === 'Female'} value="Female" control={<Radio />} label="Female" />
                          <FormControlLabel checked={values['gender'] === 'Male'} value="Male" control={<Radio />} label="Male" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={12}>
                      <h5>Address Info</h5>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        data-testid="address"
                        variant="outlined"
                        type="text"
                        placeholder='e.g. "123, abc street, xyz city"'
                        label="Address"
                        disabled={!editMode}
                        name="address"
                        onChange={(e) => setFieldValue('address', e.target.value)}
                        value={values['address']}
                        error={touched['address'] && Boolean(errors['address'])}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        data-testid="city"
                        variant="outlined"
                        type="text"
                        placeholder='e.g. "New York"'
                        label="City"
                        name="city"
                        disabled={!editMode}
                        onChange={(e) => setFieldValue('city', e.target.value)}
                        value={values['city']}
                        error={touched['city'] && Boolean(errors['city'])}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        data-testid="zip code"
                        variant="outlined"
                        type="text"
                        placeholder='e.g. "10001"'
                        label="Zip Code"
                        name="zipCode"
                        disabled={!editMode}
                        onChange={(e) => setFieldValue('zipCode', e.target.value)}
                        value={values['zipCode']}
                        error={touched['zipCode'] && Boolean(errors['zipCode'])}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        data-testid="state"
                        variant="outlined"
                        type="text"
                        placeholder='e.g. "New York"'
                        label="State"
                        name="state"
                        disabled={!editMode}
                        onChange={(e) => setFieldValue('state', e.target.value)}
                        value={values['state']}
                        error={touched['state'] && Boolean(errors['state'])}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        data-testid="country"
                        variant="outlined"
                        type="text"
                        placeholder='e.g. "Manhattan"'
                        label="Country"
                        name="country"
                        disabled={!editMode}
                        onChange={(e) => setFieldValue('country', e.target.value)}
                        value={values['country']}
                        error={touched['country'] && Boolean(errors['country'])}
                      />
                    </Grid> */}
                  </Grid>
                ) : loading ? (
                  <>Loading...</>
                ) : (
                  <>No Profile Data Found</>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      )}
      {changePass && (
        <ManageUpdatePassword
          onClose={() => {
            setChangePass(false);
          }}
        />
      )}
    </Box>
  );
};

export default Profile;
