export const b64toBlob = (dataURI: string) => {
  var byteString = atob(dataURI.split(',')[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
};

export const getQueryParams = (gridState: any) => {
  const state: any = { ...gridState };
  const keys: any = Object.keys(state);
  let query = '';

  keys.forEach((k: any) => {
    if (state[k]) {
      query = `${query}${k}=${state[k]}&`;
    }
  });

  return query;
};

export const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
};
