import { Box, TextField, Typography, InputAdornment, IconButton, Button, CircularProgress } from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import queryString from 'query-string';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { logo } from 'src/config';
import { HiOutlineKey } from 'react-icons/hi';

interface ResetPasswordData {
  password: string;
  confirmPassword: string;
}

const ResetPassword = () => {
  const navigate = useNavigate();
  const toastConfig = useContext(CustomToastContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfrimPassword] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [tokenChecking, setTokenChecking] = useState(false);
  const { email, token } = queryString.parse(window.location.search);

  useEffect(() => {
    checkToken();
  }, []);

  const checkToken = async () => {
    setTokenChecking(true);
    axiosInstance()
      .get(`/users/check-token?token=${token}`)
      .then(({ data }) => {
        data.data
          ? setIsTokenValid(true)
          : toastConfig.setToastConfig({
              message: 'Invalid token',
              type: 'error',
              open: true
            });
      })
      .catch((error) => {
        toastConfig.setToastConfig(error);
      });
  };

  const handleSubmit = async (values: ResetPasswordData) => {
    axiosInstance()
      .post(`/users/reset-password`, {
        token: token,
        password: values.password
      })
      .then(({ data }) => {
        toastConfig.setToastConfig({
          open: true,
          message: data.message,
          type: 'success'
        });
        navigate('/', { replace: true });
      })
      .catch((error) => {
        localStorage.removeItem('token');
        toastConfig.setToastConfig(error);
      });
  };

  const validateForm = (values: ResetPasswordData) => {
    const errors: any = {};
    if (!values.password) {
      errors.password = 'Required field';
    } else if (!/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/.test(values.password)) {
      errors.password = 'Minimum eight characters, at least one uppercase, one lowercase, one number and one special character';
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = 'Required field';
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'New Password and Confirm Password should be same';
    }
    return errors;
  };

  return (
    <Box sx={{ ...customStyles.container }}>
      <Box sx={{ flexBasis: '487px', maxWidth: '487px' }}>
        <Box sx={{ ...customStyles.loginContainer }}>
          <Box
            sx={{
              maxWidth: '250px',
              maxHeight: '46px',
              margin: { xs: '0px auto 15px', sm: '0px auto 20px', md: '0px auto 25px', lg: '0px auto 30px' }
            }}
          >
            <img src={logo} alt="" style={{ maxWidth: '250px', maxHeight: '46px' }} />
          </Box>
          <Typography sx={{ ...customStyles.logoText }} mb={1}>
            Reset Password
          </Typography>
          <Box mt={'10px'}>
            <Formik
              initialValues={{
                password: '',
                confirmPassword: ''
              }}
              validate={validateForm}
              onSubmit={handleSubmit}
            >
              {({ submitForm, values, errors, touched, setFieldValue, isSubmitting }) => (
                <Form>
                  <Box>
                    <Box mb={'22px'}>
                      <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <Typography style={{ ...customStyles.lebel }}>New password</Typography>
                        <div className="error">{touched.password && errors.password && <div>{errors.password}</div>}</div>
                      </Box>
                      <TextField
                        fullWidth
                        data-testid="password"
                        variant="outlined"
                        type={showPassword ? 'text' : 'password'}
                        disabled={!isTokenValid || !tokenChecking}
                        size="small"
                        required
                        placeholder="New password"
                        name="password"
                        value={values['password']}
                        error={touched['password'] && Boolean(errors['password'])}
                        onChange={(e) => setFieldValue('password', e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <HiOutlineKey color={'#B0B6BF'} />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton className="p-0" onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Box>

                    <Box>
                      <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <Typography style={{ ...customStyles.lebel }}>Confirm password</Typography>
                        <div className="error">{touched.confirmPassword && errors.confirmPassword && <div>{errors.confirmPassword}</div>}</div>
                      </Box>
                      <TextField
                        fullWidth
                        data-testid="confirmPassword"
                        variant="outlined"
                        type={showConfirmPassword ? 'text' : 'password'}
                        disabled={!isTokenValid || !tokenChecking}
                        size="small"
                        placeholder="Confirm password"
                        name="confimPassword"
                        value={values['confirmPassword']}
                        error={touched['confirmPassword'] && Boolean(errors['confirmPassword'])}
                        onChange={(e) => setFieldValue('confirmPassword', e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <HiOutlineKey color={'#B0B6BF'} />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton className="p-0" onClick={() => setShowConfrimPassword(!showConfirmPassword)}>
                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Box>

                    <Box>
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ ...customStyles.buttons }}
                        disabled={isSubmitting}
                        endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPassword;

const customStyles = {
  container: {
    width: '100%',
    minHeight: '90vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 20px'
  },
  loginContainer: {
    maxWidth: '487px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    boxShadow: '0px 4px 60px rgba(0, 0, 0, 0.12)',
    borderRadius: '10px',
    padding: { xs: '35px', sm: '45px', md: '45px 50px', lg: '45px 70px' }
  },
  logoText: {
    fontWeight: 600,
    fontSize: '28px',
    lineHeight: '42px',
    color: '#3A3A3A',
    textAlign: 'center'
  },
  lebel: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
    marginBottom: '5px',
    color: '#3A3A3A'
  },
  fields: {
    '> div': {
      height: '48px',
      input: {
        fontSize: '16px !important'
      },
      borderRadius: '8px !important'
    }
  },
  buttons: {
    padding: '10px 16px',
    borderRadius: '10px !important',
    fontSize: '14px',
    marginTop: '20px',
    boxShadow: '0px 4px 30px rgba(22, 51, 64, 0.3)'
  },
  forgotPassword: {
    fontSize: '12px',
    color: '#878787',
    textDecoration: 'none',
    display: 'block'
  }
};
