import React from 'react';
import {
  Box,
  Button,
  Dialog,
  Grid,
  TextField,
  Autocomplete,
  CircularProgress,
  Typography,
  Checkbox,
  FormControlLabel,
  Avatar,
  useTheme
} from '@mui/material';
import { Formik } from 'formik';
import { isMobile, isTablet } from 'react-device-detect';
import CustomDialogHeader from 'src/components/CustomDialog/CustomDialogHeader';
import CustomDialogContent from 'src/components/CustomDialog/CustomDialogContent';
import CustomDialogFooter from 'src/components/CustomDialog/CustomDialogFooter';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import CommonSkeleton from 'src/components/Helpers/CommonSkeleton';
import currencies from '../../../constants/currency_with_country.json';
import routes from 'src/components/Helpers/Routes';

interface ManageCoursesProps {
  onClose: () => void;
  handleSucess: () => void;
  brandId?: any;
}

const ManageBrands = ({ onClose, handleSucess, brandId }: ManageCoursesProps) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const formikRef = React.useRef(null);
  const toastConfig = React.useContext(CustomToastContext);
  const [users, setUsers] = React.useState(null);
  const [fullScreen, setFullScreen] = React.useState(isMobile || isTablet);

  const [initialValues, setInitialValues] = React.useState<any>(null);
  const [currencyData, setCurrencyData] = React.useState([]);
  React.useEffect(() => {
    const sortedArr = currencies.sort((a, b) =>
      a.name.toUpperCase() < b.name.toUpperCase() ? -1 : a.name.toUpperCase() > b.name.toUpperCase() ? 1 : 0
    );
    setCurrencyData(sortedArr);
  }, []);

  React.useEffect(() => {
    if (brandId) {
      axiosInstance()
        .get(`${routes.brands.api}/${brandId}`)
        .then(({ data: { data } }) => {
          setInitialValues({
            companyName: data?.companyName,
            currency: data?.currency,
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            phone: data?.phone
          });
        })
        .catch((error) => {});
    } else {
      setInitialValues({
        companyName: '',
        currency: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
      });
    }
  }, [brandId]);

  React.useEffect(() => {
    axiosInstance()
      .get('/users?role=Instructor')
      .then((res) => {
        setUsers(res?.data?.data);
      })
      .catch((err) => {});
  }, [brandId]);

  const handleSubmit = async (values: any) => {
    if (brandId) {
      const payload = {
        companyName: values.companyName,
        currency: values.currency
      };
      await axiosInstance()
        .put(`${routes.brands.api}/${brandId}`, payload)
        .then(({ data: data }) => {
          toastConfig.setToastConfig({
            open: true,
            type: 'success',
            message: data.message
          });
          handleSucess();
        })
        .catch((err) => {
          toastConfig.setToastConfig(err);
        });
    } else {
      const payload = {
        companyName: values.companyName,
        currency: values.currency,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone || ''
      };
      await axiosInstance()
        .post(`${routes.brands.api}`, payload)
        .then(({ data: data }) => {
          toastConfig.setToastConfig({
            open: true,
            type: 'success',
            message: data.message
          });
          handleSucess();
        })
        .catch((err) => {
          toastConfig.setToastConfig(err);
        });
    }
  };

  const validate = (values: any) => {
    let errors: any = {};
    if (!values?.companyName) {
      errors.companyName = 'Please enter company name';
    }
    if (!values?.currency) {
      errors.currency = 'Please select currency';
    }
    if (!values.email && !brandId) {
      errors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email) && !brandId) {
      errors.email = 'Invalid email address';
    }
    if (!values.firstName && !brandId) {
      errors.firstName = 'Please enter first name';
    }
    if (!values.lastName && !brandId) {
      errors.lastName = 'Please enter last name';
    }
    return errors;
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen || isMobile || isTablet}
      aria-labelledby="customized-dialog-title"
      onClose={(e, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      open
    >
      {initialValues && users ? (
        <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={handleSubmit} validate={validate}>
          {({ values, setFieldValue, submitForm, errors, touched, isSubmitting }) => (
            <>
              <CustomDialogHeader
                title={'Add Brands'}
                onClose={onClose}
                isMinimized={!fullScreen}
                onMinimizeMaximize={() => {
                  setFullScreen((prevState) => !prevState);
                }}
                showManimizeMaximize={true}
              />
              <CustomDialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      required
                      name={'companyName'}
                      label={'Company Name'}
                      value={values.companyName}
                      onChange={(e) => setFieldValue('companyName', e.target.value)}
                      error={touched['companyName'] && Boolean(errors['companyName'])}
                      helperText={touched['companyName'] && errors['companyName']?.toString()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      size="small"
                      fullWidth
                      options={currencyData}
                      isOptionEqualToValue={(option, value) => option === value}
                      getOptionLabel={(option: any) => (option ? `${option?.currencyCode} - ${option?.name}` : '')}
                      value={
                        currencyData.filter((data) => data.currencyCode === values['currency']).length
                          ? currencyData.filter((data) => data.currencyCode === values['currency'])[0]
                          : ''
                      }
                      onChange={(_, newVal: any) => setFieldValue('currency', newVal?.currencyCode)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          required
                          name={'currency'}
                          label={'Currency'}
                          error={touched['currency'] && Boolean(errors['currency'])}
                          helperText={touched['currency'] && errors['currency']?.toString()}
                        />
                      )}
                      renderOption={(props, option: any) => {
                        const { currencyCode, name, countryCode } = option;
                        return (
                          <li {...props}>
                            <Grid container alignItems="center">
                              <Grid item>
                                <Avatar
                                  variant="rounded"
                                  src={`https://flagcdn.com/w80/${countryCode?.toLowerCase()}.png`}
                                  style={{
                                    marginRight: 20,
                                    width: '40px',
                                    height: '30px'
                                  }}
                                />
                              </Grid>
                              <Grid item xs>
                                <Typography>{currencyCode}</Typography>
                                <Typography variant="body2" color="textSecondary">
                                  {name}
                                </Typography>
                              </Grid>
                            </Grid>
                          </li>
                        );
                      }}
                    />
                  </Grid>
                </Grid>
                {!brandId && (
                  <Grid container spacing={2} mt={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        size="small"
                        required
                        name={'firstName'}
                        label={'First Name'}
                        value={values.firstName}
                        onChange={(e) => setFieldValue('firstName', e.target.value)}
                        error={touched.firstName && Boolean(errors.firstName)}
                        helperText={(touched.firstName && errors.firstName)?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        size="small"
                        required
                        name={'lastName'}
                        label={'Last Name'}
                        value={values.lastName}
                        onChange={(e) => setFieldValue('lastName', e.target.value)}
                        error={touched.lastName && Boolean(errors.lastName)}
                        helperText={(touched.lastName && errors.lastName)?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="email"
                        size="small"
                        required
                        name={'email'}
                        label={'Email'}
                        value={values.email}
                        onChange={(e) => setFieldValue('email', e.target.value)}
                        error={touched.email && Boolean(errors.email)}
                        helperText={(touched.email && errors.email)?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        size="small"
                        name={'phone'}
                        label={'Phone'}
                        value={values.phone}
                        onChange={(e) => setFieldValue('phone', e.target.value)}
                      />
                    </Grid>
                  </Grid>
                )}
              </CustomDialogContent>
              <CustomDialogFooter>
                <Button variant="outlined" size="small" onClick={onClose} disabled={isSubmitting}>
                  Close
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={submitForm}
                  disabled={isSubmitting}
                  endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                >
                  Save
                </Button>
              </CustomDialogFooter>
            </>
          )}
        </Formik>
      ) : (
        <Box height={500} bgcolor={mode === 'dark' ? 'gray.900' : 'white'}>
          <CommonSkeleton lenArray={[...Array(10).keys()]} />
        </Box>
      )}
    </Dialog>
  );
};

export default ManageBrands;
