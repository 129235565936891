import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from '@mui/material';

export default function ConfirmationDialogRaw(props: any) {
    const { onClose, onOk, open, message, okBtnLoading } = props;

    return (
        <Dialog
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            id="confirmation-dialog"
            keepMounted
        >
            <DialogTitle id="confirmation-dialog-title" sx={{ color: 'white' }}>Confirm</DialogTitle>
            <DialogContent dividers>
                {
                    message ? <Typography>{message}</Typography> : null
                }
            </DialogContent>
            <DialogActions>
                <Button size="small" autoFocus onClick={onClose} color="primary">Cancel</Button>
                <Button size="small" onClick={onOk}
                    disabled={okBtnLoading} color="primary">
                    {okBtnLoading ? <CircularProgress
                        style={{ marginRight: "8px" }}
                        size={20} color="inherit" /> : null}
                    Ok</Button>
            </DialogActions>
        </Dialog>
    );
}


