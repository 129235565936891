import { useState, useContext, useEffect } from 'react';
import {
  Button,
  Dialog,
  TextField,
  Grid,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  RadioGroup,
  Radio,
  Box,
  Typography,
  useTheme
} from '@mui/material';
import { Formik } from 'formik';
import { axiosInstance } from 'src/axios';
import MuiPhoneInput from 'material-ui-phone-number';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { userFieldName, userFieldLabel, UserFormData, roleOptions } from './UserHelper';
import { CustomDialogHeader, CustomDialogContent, CustomDialogFooter } from 'src/components/CustomDialog';
import { isMobile, isTablet } from 'react-device-detect';
import CommonSkeleton from 'src/components/Helpers/CommonSkeleton';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Dayjs } from 'dayjs';
import { Country, State, City } from 'country-state-city';

const ManageStudentDialog = ({ onClose, handleSucess, userId, parentId = null }: any) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toastConfig = useContext(CustomToastContext);
  const [initialValues, setInitialValues] = useState(null);
  const [fullScreen, setFullScreen] = useState(isMobile || isTablet);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState<any>({ label: 'Canada', isoCode: 'CA' });
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [studentLoading, setStudentLoading] = useState(false);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const result = await Country.getAllCountries();
        let allCountries = [];
        allCountries = result?.map(({ isoCode, name }) => ({
          isoCode,
          label: `${name}`
        }));
        setCountries(allCountries);
      } catch (error) {
        setCountries([]);
      }
    };

    getCountries();
  }, []);

  useEffect(() => {
    const getStates = async () => {
      try {
        const result = await State.getStatesOfCountry(selectedCountry.isoCode);
        let allStates = [];
        allStates = result?.map(({ isoCode, name }) => ({
          isoCode,
          label: name
        }));
        setCities([]);
        setStates(allStates);
      } catch (error) {
        setStates([]);
        setCities([]);
        setSelectedCity('');
      }
    };
    // setSelectedState({ label: '' });
    // setSelectedCity({ label: '' });
    getStates();
  }, [selectedCountry]);

  useEffect(() => {
    const getCities = async () => {
      try {
        const result = await City.getCitiesOfState(selectedCountry.isoCode, selectedState.isoCode);
        let allCities = [];
        allCities = result?.map(({ name }) => ({
          label: name
        }));
        setCities(allCities);
      } catch (error) {
        setCities([]);
      }
    };
    // setSelectedCity({ label: '' });
    getCities();
  }, [selectedState]);

  useEffect(() => {
    setStudentLoading(true);
    if (userId) {
      axiosInstance()
        .get(`/students/${userId}?parentId=${parentId}`)
        .then(({ data: { data } }) => {
          setInitialValues({
            firstName: data?.firstName || '',
            middleName: data?.middleName || '',
            lastName: data?.lastName || '',
            email: data?.email || '',
            dob: data?.dob || '',
            phone: data?.phone || '',
            healthCareNumber: data?.healthCareNumber || 0,
            allergies: data?.allergies?.join(', ') || ''
          });
          setStudentLoading(false);
        })
        .catch((error) => {
          toastConfig.setToastConfig(error);
          setStudentLoading(false);
        });
    } else {
      setInitialValues({
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        dob: '',
        phone: '',
        healthCareNumber: 0,
        allergies: ''
      });
      setStudentLoading(false);
    }
  }, [userId]);

  const handleSubmit = (values: any) => {
    values.healthCareNumber = parseInt(values?.healthCareNumber);
    values.allergies = values.allergies.split(/[, \n]+/).filter(Boolean);
    if (userId && parentId) {
      setIsSubmitting(true);
      axiosInstance()
        .put(`/students?parentId=${parentId}`, { ...values, _id: userId })
        .then(({ data }) => {
          setIsSubmitting(false);
          toastConfig.setToastConfig({
            open: true,
            type: 'success',
            message: data.message
          });
          handleSucess();
        })
        .catch((error) => {
          toastConfig.setToastConfig(error);
          setIsSubmitting(false);
        });
    } else if (parentId) {
      setIsSubmitting(true);
      axiosInstance()
        .post(`/students?parentId=${parentId}`, values)
        .then(({ data }) => {
          setIsSubmitting(false);
          toastConfig.setToastConfig({
            open: true,
            type: 'success',
            message: data.message
          });
          handleSucess();
        })
        .catch((error) => {
          toastConfig.setToastConfig(error);
          setIsSubmitting(false);
        });
    }
  };

  const isErroredEmail = (email: string) => {
    if (!email) {
      return 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return 'Invalid email address';
    }
    return false;
  };

  const isErroredPhoneNumber = (phone: string) => {
    let simpleStr = phone.replaceAll(' ', '');
    simpleStr = simpleStr.replaceAll('(', '');
    simpleStr = simpleStr.replaceAll(')', '');
    simpleStr = simpleStr.replaceAll('-', '');
    const isValid = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(simpleStr);

    if (!isValid) {
      return 'Phone number is not valid';
    }
    return false;
  };

  const validate = (values: UserFormData) => {
    const errors: any = {};
    if (isErroredEmail(values.email)) errors.email = isErroredEmail(values.email);

    if (!values.firstName) errors.firstName = 'Please enter first name';

    if (!values.lastName) errors.lastName = 'Please enter last name';

    if (!values.dob) errors.dob = 'Please enter DOB';
    if (isErroredPhoneNumber(values.phone)) errors.phone = isErroredPhoneNumber(values.phone);
    if (!values.healthCareNumber) {
      errors.healthCareNumber = 'Health Care Number is required';
    } else if (values.healthCareNumber) {
      let str = values?.healthCareNumber;
      str = str.replaceAll(' ', '');
      str = str.replaceAll('-', '');
      str = str.replaceAll('_', '');
      const valid = /^\d+$/.test(str);
      const numberLength = str.length;
      if (numberLength !== 9) errors.healthCareNumber = 'Only 9 characters allowed';
      if (!valid) errors.healthCareNumber = 'Health Care Number can only contain numbers';
    }

    return errors;
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen || isMobile || isTablet}
      aria-labelledby="customized-dialog-title"
      onClose={(e, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      open
    >
      {!studentLoading && initialValues ? (
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
          {({ submitForm, values, errors, touched, setFieldValue }) => (
            <>
              <CustomDialogHeader
                title={userId ? 'Edit Student' : 'Add Student'}
                onClose={onClose}
                isMinimized={!fullScreen}
                onMinimizeMaximize={() => {
                  setFullScreen((prevState) => !prevState);
                }}
                showManimizeMaximize={true}
              />
              <CustomDialogContent>
                <Box sx={{ pt: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        size="small"
                        required
                        name={userFieldName.FIRST_NAME}
                        label={userFieldLabel.FIRST_NAME}
                        value={values.firstName}
                        onChange={(e) => setFieldValue(userFieldName.FIRST_NAME, e.target.value)}
                        error={touched.firstName && Boolean(errors.firstName)}
                        helperText={(touched.firstName && errors.firstName)?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        size="small"
                        required
                        name={userFieldName.LAST_NAME}
                        label={userFieldLabel.LAST_NAME}
                        value={values.lastName}
                        onChange={(e) => setFieldValue(userFieldName.LAST_NAME, e.target.value)}
                        error={touched.lastName && Boolean(errors.lastName)}
                        helperText={(touched.lastName && errors.lastName)?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="email"
                        size="small"
                        name={userFieldName.EMAIL}
                        label={userFieldLabel.EMAIL}
                        required={true}
                        value={values.email}
                        onChange={(e) => setFieldValue(userFieldName.EMAIL, e.target.value)}
                        error={touched.email && Boolean(errors.email)}
                        helperText={(touched.email && errors.email)?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DesktopDatePicker
                        label={userFieldLabel.DOB}
                        inputFormat="MM/DD/YYYY"
                        onChange={(newValue: Dayjs | null) => setFieldValue(userFieldName.DOB, newValue)}
                        value={values.dob}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            error={touched.dob && Boolean(errors.dob)}
                            helperText={(touched.dob && errors.dob)?.toString()}
                            placeholder={userFieldLabel.DOB}
                            name={userFieldName.DOB}
                            fullWidth
                            size="small"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MuiPhoneInput
                        defaultCountry={'ca'}
                        excludeCountries={['us']}
                        disableAreaCodes
                        countryCodeEditable={true}
                        enableLongNumbers={false}
                        fullWidth
                        variant="outlined"
                        type="phone"
                        required
                        label="Phone"
                        name="phone"
                        size="small"
                        dropdownClass="phone_dropdown"
                        onChange={(val: any) => setFieldValue(userFieldName.PHONE, val.toString())}
                        value={values['phone']}
                        error={touched['phone'] && Boolean(errors['phone'])}
                        helperText={touched['phone'] && Boolean(errors['phone']) && `${errors['phone']}`}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        size="small"
                        required
                        name={userFieldName.HEALTH_CARE_NUMBER}
                        label={userFieldLabel.HEALTH_CARE_NUMBER}
                        value={values.healthCareNumber}
                        onChange={(e) => setFieldValue(userFieldName.HEALTH_CARE_NUMBER, e.target.value)}
                        error={touched.healthCareNumber && Boolean(errors.healthCareNumber)}
                        helperText={(touched.healthCareNumber && errors.healthCareNumber)?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        data-testid="allergies"
                        variant="outlined"
                        type="text"
                        placeholder="Allergies"
                        label="Allergies"
                        name="allergies"
                        size="small"
                        multiline
                        minRows={3}
                        maxRows={4}
                        onChange={(e) => setFieldValue('allergies', e.target.value)}
                        value={values['allergies']}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </CustomDialogContent>
              <CustomDialogFooter>
                <Button variant="outlined" onClick={onClose} size="small">
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={submitForm}
                  size="small"
                  disabled={isSubmitting}
                  endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                >
                  Save
                </Button>
              </CustomDialogFooter>
            </>
          )}
        </Formik>
      ) : (
        <Box height={500} bgcolor={mode === 'dark' ? 'gray.900' : 'white'}>
          <CommonSkeleton lenArray={[...Array(10).keys()]} />
        </Box>
      )}
    </Dialog>
  );
};

export default ManageStudentDialog;
