import {
  Box,
  Typography,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageUploadDialog from "./ImageUploadDialog";

interface ImageListsProps {
  images: string[];
  setFieldValue: (name: string, value: any) => void;
  setReadingImage: React.Dispatch<React.SetStateAction<boolean>>;
  image: string;
  setImage: React.Dispatch<React.SetStateAction<string>>;
  imageFileName: string;
  setImageFileName: React.Dispatch<React.SetStateAction<string>>;
}

const ImageLists = ({
  images,
  setFieldValue,
  setReadingImage,
  image,
  setImage,
  imageFileName,
  setImageFileName,
}: ImageListsProps) => {
  return (
    <>
      <Box mt={1}>
        <Typography color="textSecondary">
          {images?.length > 0 ? "Images Preview" : "No Images"}
        </Typography>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-arounf"
          overflow="hidden"
        >
          <ImageList
            style={{
              flexWrap: "nowrap",
              transform: "translateZ(0)",
            }}
          >
            {images ? (
              images.map((item: string, i: number) => (
                <ImageListItem
                  style={{
                    height: "100px",
                    width: "33.3%",
                  }}
                  key={item}
                >
                  <img src={item} alt={`demo ${i + 1}`} />
                  <ImageListItemBar
                    title={""}
                    actionIcon={
                      <IconButton
                        onClick={() => {
                          const updatedArr = images.filter(
                            (i: string) => i !== item
                          );
                          setFieldValue("images", updatedArr);
                        }}
                        aria-label={`demo ${i + 1}`}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              ))
            ) : (
              <></>
            )}
          </ImageList>
        </Box>
      </Box>
      <ImageUploadDialog
        images={images}
        setFieldValue={setFieldValue}
        image={image}
        setImage={setImage}
        imageFileName={imageFileName}
        setImageFileName={setImageFileName}
        setReadingImage={setReadingImage}
      />
    </>
  );
};

export default ImageLists;
