const routes = {
  courses: {
    title: 'Courses',
    path: '/courses',
    api: '/course-master'
  },
  coursesDetails: {
    title: 'Courses',
    path: '/courses/details'
  },
  users: {
    title: 'Users',
    path: '/users',
    api: '/users'
  },
  report: {
    title: 'Report',
    path: '/report',
    api: '/report'
  },
  offers: {
    title: 'Offers',
    path: '/offers',
    api: '/offers'
  },
  policy: {
    title: 'Policy',
    path: '/policy',
    api: '/policy'
  },
  brands: {
    title: 'Brands',
    path: '/brands',
    api: '/brands'
  },
  profile: {
    title: 'Profile',
    path: '/profile',
    api: '/profile'
  },
  contributeMaster: {
    title: 'Donation Master',
    path: '/donation-master',
    api: '/contribute-master'
  },
  paymentLinks: {
    title: 'Payment Links',
    path: '/payment-links',
    api: '/payment-links'
  },
  donation: {
    title: 'Donation Transactions',
    path: '/donation-transactions',
    api: '/donation'
  },
  schedule: {
    title: 'Schedule',
    path: '/schedule',
    api: '/schedule'
  },
  attendanceReport: {
    title: 'Attendance Report',
    path: '/attendance-report',
    api: '/student-attendance'
  },
  broadcast: {
    title: 'Broadcast',
    path: '/broadcast',
    api: '/notification'
  }
};

export default routes;
