import { useState, useContext, useEffect } from 'react';
import {
  Button,
  Dialog,
  TextField,
  Grid,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  RadioGroup,
  Radio,
  Box,
  Typography,
  useTheme
} from '@mui/material';
import { Formik } from 'formik';
import { axiosInstance } from 'src/axios';
import MuiPhoneInput from 'material-ui-phone-number';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { userFieldName, userFieldLabel, UserFormData, roleOptions } from './UserHelper';
import { CustomDialogHeader, CustomDialogContent, CustomDialogFooter } from 'src/components/CustomDialog';
import { isMobile, isTablet } from 'react-device-detect';
import CommonSkeleton from 'src/components/Helpers/CommonSkeleton';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Dayjs } from 'dayjs';
import { Country, State, City } from 'country-state-city';
import _ from 'lodash';

const ManageUserDialog = ({ onClose, handleSucess, userId }: any) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toastConfig = useContext(CustomToastContext);
  const [initialValues, setInitialValues] = useState(null);
  const [fullScreen, setFullScreen] = useState(isMobile || isTablet);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState<any>({ label: 'Canada', isoCode: 'CA' });
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const result = await Country.getAllCountries();
        let allCountries = [];
        allCountries = result?.map(({ isoCode, name }) => ({
          isoCode,
          label: `${name}`
        }));
        setCountries(allCountries);
      } catch (error) {
        setCountries([]);
      }
    };

    getCountries();
  }, []);

  useEffect(() => {
    const getStates = async () => {
      try {
        const result = await State.getStatesOfCountry(selectedCountry.isoCode);
        let allStates = [];
        allStates = result?.map(({ isoCode, name }) => ({
          isoCode,
          label: name
        }));
        setCities([]);
        setStates(allStates);
      } catch (error) {
        setStates([]);
        setCities([]);
        setSelectedCity('');
      }
    };
    // setSelectedState({ label: '' });
    // setSelectedCity({ label: '' });
    getStates();
  }, [selectedCountry]);

  useEffect(() => {
    const getCities = async () => {
      try {
        const result = await City.getCitiesOfState(selectedCountry.isoCode, selectedState.isoCode);
        let allCities = [];
        allCities = result?.map(({ name }) => ({
          label: name
        }));
        setCities(allCities);
      } catch (error) {
        setCities([]);
      }
    };
    // setSelectedCity({ label: '' });
    getCities();
  }, [selectedState]);

  useEffect(() => {
    if (userId) {
      axiosInstance()
        .get(`/users/${userId}`)
        .then(({ data: { data } }) => {
          setInitialValues({
            firstName: data?.firstName,
            middleName: data?.middleName,
            lastName: data?.lastName,
            email: data?.email,
            gender: data?.gender,
            dob: data?.dob,
            phone: data?.phone,
            role: data?.role,
            status: data?.status,
            healthCareNumber: data.healthCareNumber,
            allergies: data.allergies,
            emergencyFirstName: data.emergencyFirstName,
            emergencyMiddleName: data.emergencyMiddleName,
            emergencyLastName: data.emergencyLastName,
            emergencyEmail: data.emergencyEmail,
            emergencyGender: data.emergencyGender,
            emergencyPhone: data.emergencyPhone,
            emergencyAddress: data.emergencyAddress,
            emergencyState: data?.emergencyState?.label || '',
            emergencyCity: data?.emergencyCity?.label || '',
            emergencyCountry: data?.emergencyCountry?.label || '',
            emergencyZipCode: data.emergencyZipCode
          });
          setSelectedCountry(data?.emergencyCountry?.label);
          setSelectedState(data?.emergencyState?.label);
          setSelectedCity(data?.emergencyCity?.label);
        })
        .catch((error) => {});
    } else {
      setInitialValues({
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        gender: 'Male',
        dob: '',
        phone: '',
        role: '',
        status: true,
        healthCareNumber: '',
        allergies: '',
        emergencyFirstName: '',
        emergencyMiddleName: '',
        emergencyLastName: '',
        emergencyEmail: '',
        emergencyGender: 'Male',
        emergencyPhone: '',
        emergencyAddress: '',
        emergencyCity: '',
        emergencyState: '',
        emergencyCountry: '',
        emergencyZipCode: ''
      });
    }
  }, [userId]);

  const handleSubmit = (values: any) => {
    if (userId) {
      setIsSubmitting(true);
      axiosInstance()
        .put(`/users`, { ...values, _id: userId })
        .then(({ data }) => {
          setIsSubmitting(false);
          toastConfig.setToastConfig({
            open: true,
            type: 'success',
            message: data.message
          });
          handleSucess();
        })
        .catch((error) => {
          toastConfig.setToastConfig(error);
          setIsSubmitting(false);
        });
    } else {
      setIsSubmitting(true);
      axiosInstance()
        .post('/users', values)
        .then(({ data }) => {
          setIsSubmitting(false);
          toastConfig.setToastConfig({
            open: true,
            type: 'success',
            message: data.message
          });
          handleSucess();
        })
        .catch((error) => {
          toastConfig.setToastConfig(error);
          setIsSubmitting(false);
        });
    }
  };

  const isErroredEmail = (email: string) => {
    if (!email) {
      return 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return 'Invalid email address';
    }
    return false;
  };

  const isErroredPhoneNumber = (phone: string) => {
    let simpleStr = _.replace(phone, /[ ()-]/g, '');
    // let simpleStr = phone.replaceAll(' ', '');
    // simpleStr = simpleStr.replaceAll('(', '');
    // simpleStr = simpleStr.replaceAll(')', '');
    // simpleStr = simpleStr.replaceAll('-', '');
    const isValid = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(simpleStr);

    if (!isValid) {
      return 'Phone number is not valid';
    }
    return false;
  };

  const validate = (values: UserFormData) => {
    const errors: any = {};
    if (isErroredEmail(values.email)) errors.email = isErroredEmail(values.email);

    if (!values.firstName) errors.firstName = 'Please enter first name';

    if (!values.lastName) errors.lastName = 'Please enter last name';

    if (!values.dob) errors.dob = 'Please enter DOB';

    if (!values.role) errors.role = 'Please select role';

    if (isErroredPhoneNumber(values.phone)) errors.phone = isErroredPhoneNumber(values.phone);

    if (values.role === 'Student') {
      if (!values.healthCareNumber) {
        errors.healthCareNumber = 'Health Care Number is required';
      } else {
        let str = values?.healthCareNumber;
        str = _.replace(str, /[-]/g, '');
        // str = str.replaceAll(' ', '');
        // str = str.replaceAll('-', '');
        // str = str.replaceAll('_', '');
        // const valid = /^\d+$/.test(str);
        // const valid = _.every(str, _.isNumber);
        const numberLength = str?.length;
        if (numberLength !== 9) errors.healthCareNumber = 'Only 9 characters allowed';
        // if (!valid) errors.healthCareNumber = 'Health Care Number can only contain numbers';
      }

      if (!values.emergencyFirstName) errors.emergencyFirstName = 'First Name is required';

      if (!values.emergencyLastName) errors.emergencyLastName = 'Last Name is required';

      if (isErroredEmail(values.emergencyEmail)) errors.emergencyEmail = isErroredEmail(values.emergencyEmail);

      if (isErroredPhoneNumber(values.emergencyPhone)) errors.emergencyPhone = isErroredPhoneNumber(values.emergencyPhone);

      if (!values.emergencyAddress) errors.emergencyAddress = 'Address is required';

      if (!values.emergencyCity) errors.emergencyCity = 'City is required';

      if (!values.emergencyState) errors.emergencyState = 'State is required';

      if (!values.emergencyCountry) errors.emergencyCountry = 'Country is required';
    }

    return errors;
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen || isMobile || isTablet}
      aria-labelledby="customized-dialog-title"
      onClose={(e, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      open
    >
      {initialValues ? (
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
          {({ submitForm, values, errors, touched, setFieldValue }) => (
            <>
              <CustomDialogHeader
                title={userId ? 'Edit User' : 'Add User'}
                onClose={onClose}
                isMinimized={!fullScreen}
                onMinimizeMaximize={() => {
                  setFullScreen((prevState) => !prevState);
                }}
                showManimizeMaximize={true}
              />
              <CustomDialogContent>
                <Box sx={{ pt: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        size="small"
                        required
                        name={userFieldName.FIRST_NAME}
                        label={userFieldLabel.FIRST_NAME}
                        value={values.firstName}
                        onChange={(e) => setFieldValue(userFieldName.FIRST_NAME, e.target.value)}
                        error={touched.firstName && Boolean(errors.firstName)}
                        helperText={(touched.firstName && errors.firstName)?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        size="small"
                        required
                        name={userFieldName.LAST_NAME}
                        label={userFieldLabel.LAST_NAME}
                        value={values.lastName}
                        onChange={(e) => setFieldValue(userFieldName.LAST_NAME, e.target.value)}
                        error={touched.lastName && Boolean(errors.lastName)}
                        helperText={(touched.lastName && errors.lastName)?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="email"
                        size="small"
                        name={userFieldName.EMAIL}
                        label={userFieldLabel.EMAIL}
                        required={true}
                        value={values.email}
                        onChange={(e) => setFieldValue(userFieldName.EMAIL, e.target.value)}
                        error={touched.email && Boolean(errors.email)}
                        helperText={(touched.email && errors.email)?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DesktopDatePicker
                        label={userFieldLabel.DOB}
                        inputFormat="MM/DD/YYYY"
                        onChange={(newValue: Dayjs | null) => setFieldValue(userFieldName.DOB, newValue)}
                        value={values.dob}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            error={touched.dob && Boolean(errors.dob)}
                            helperText={(touched.dob && errors.dob)?.toString()}
                            placeholder={userFieldLabel.DOB}
                            name={userFieldName.DOB}
                            fullWidth
                            size="small"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MuiPhoneInput
                        defaultCountry={'ca'}
                        excludeCountries={['us']}
                        disableAreaCodes
                        countryCodeEditable={true}
                        enableLongNumbers={false}
                        fullWidth
                        variant="outlined"
                        type="phone"
                        required
                        label="Phone"
                        name="phone"
                        size="small"
                        dropdownClass="phone_dropdown"
                        onChange={(val: any) => setFieldValue(userFieldName.PHONE, val.toString())}
                        value={values['phone']}
                        error={touched['phone'] && Boolean(errors['phone'])}
                        helperText={touched['phone'] && Boolean(errors['phone']) && `${errors['phone']}`}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        size="small"
                        fullWidth
                        options={roleOptions}
                        getOptionLabel={(option) => option || ''}
                        value={values.role}
                        isOptionEqualToValue={(option, value) => option === value}
                        onChange={(_, newVal: any) => {
                          setFieldValue(userFieldName.ROLE, newVal);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            required={true}
                            label={userFieldLabel.ROLE}
                            value={values.role}
                            error={touched.role && Boolean(errors.role)}
                            helperText={(touched.role && errors.role)?.toString()}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          onChange={(e, val) => {
                            setFieldValue(userFieldName.GENDER, val);
                          }}
                          value={values.gender}
                        >
                          <FormControlLabel value="Female" control={<Radio />} label="Female" />
                          <FormControlLabel value="Male" control={<Radio />} label="Male" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            onChange={(e) => setFieldValue(userFieldName.STATUS, e.target.checked)}
                            checked={values.status}
                            defaultChecked
                          />
                        }
                        label="Active"
                      />
                    </Grid>
                    {values.role === 'Student' && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            type="number"
                            size="small"
                            required
                            name={userFieldName.HEALTH_CARE_NUMBER}
                            label={userFieldLabel.HEALTH_CARE_NUMBER}
                            value={values.healthCareNumber}
                            onChange={(e) => setFieldValue(userFieldName.HEALTH_CARE_NUMBER, e.target.value)}
                            error={touched.healthCareNumber && Boolean(errors.healthCareNumber)}
                            helperText={(touched.healthCareNumber && errors.healthCareNumber)?.toString()}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            size="small"
                            multiline
                            minRows={3}
                            maxRows={4}
                            name={userFieldName.ALLERGIES}
                            label={userFieldLabel.ALLERGIES}
                            value={values.allergies}
                            onChange={(e) => setFieldValue(userFieldName.ALLERGIES, e.target.value)}
                            error={touched.allergies && Boolean(errors.allergies)}
                            helperText={(touched.allergies && errors.allergies)?.toString()}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h6" align="center">
                            Emergency Contact Details
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            size="small"
                            required
                            name={userFieldName.EMERGENCY_FIRST_NAME}
                            label={userFieldLabel.EMERGENCY_FIRST_NAME}
                            value={values.emergencyFirstName}
                            onChange={(e) => setFieldValue(userFieldName.EMERGENCY_FIRST_NAME, e.target.value)}
                            error={touched.emergencyFirstName && Boolean(errors.emergencyFirstName)}
                            helperText={(touched.emergencyFirstName && errors.emergencyFirstName)?.toString()}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            size="small"
                            name={userFieldName.EMERGENCY_MIDDLE_NAME}
                            label={userFieldLabel.EMERGENCY_MIDDLE_NAME}
                            value={values.emergencyMiddleName}
                            onChange={(e) => setFieldValue(userFieldName.EMERGENCY_MIDDLE_NAME, e.target.value)}
                            error={touched.emergencyMiddleName && Boolean(errors.emergencyMiddleName)}
                            helperText={(touched.emergencyMiddleName && errors.emergencyMiddleName)?.toString()}
                          />
                        </Grid>
                        {/* last name */}
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            size="small"
                            name={userFieldName.EMERGENCY_LAST_NAME}
                            label={userFieldLabel.EMERGENCY_LAST_NAME}
                            value={values.emergencyLastName}
                            onChange={(e) => setFieldValue(userFieldName.EMERGENCY_LAST_NAME, e.target.value)}
                            error={touched.emergencyLastName && Boolean(errors.emergencyLastName)}
                            helperText={(touched.emergencyLastName && errors.emergencyLastName)?.toString()}
                          />
                        </Grid>
                        {/* email */}
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            size="small"
                            name={userFieldName.EMERGENCY_EMAIL}
                            label={userFieldLabel.EMERGENCY_EMAIL}
                            value={values.emergencyEmail}
                            onChange={(e) => setFieldValue(userFieldName.EMERGENCY_EMAIL, e.target.value)}
                            error={touched.emergencyEmail && Boolean(errors.emergencyEmail)}
                            helperText={(touched.emergencyEmail && errors.emergencyEmail)?.toString()}
                          />
                        </Grid>
                        {/* gender */}
                        <Grid item xs={12} md={6}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(e, val) => {
                              setFieldValue(userFieldName.EMERGENCY_GENDER, val);
                            }}
                            value={values.emergencyGender}
                          >
                            <FormControlLabel value="Female" control={<Radio />} label="Female" />
                            <FormControlLabel value="Male" control={<Radio />} label="Male" />
                          </RadioGroup>
                        </Grid>
                        {/* phone */}
                        <Grid item xs={12} md={6}>
                          <MuiPhoneInput
                            defaultCountry={'ca'}
                            excludeCountries={['us']}
                            disableAreaCodes
                            countryCodeEditable={true}
                            enableLongNumbers={false}
                            fullWidth
                            variant="outlined"
                            type="phone"
                            required
                            label="Phone"
                            name="phone"
                            size="small"
                            dropdownClass="phone_dropdown"
                            onChange={(val: any) => setFieldValue(userFieldName.EMERGENCY_PHONE, val.toString())}
                            value={values['emergencyPhone']}
                            error={touched['emergencyPhone'] && Boolean(errors['emergencyPhone'])}
                            helperText={touched['emergencyPhone'] && Boolean(errors['emergencyPhone']) && `${errors['emergencyPhone']}`}
                          />
                        </Grid>
                        {/* Country */}
                        <Grid item xs={12} md={6}>
                          <Autocomplete
                            fullWidth
                            size="small"
                            options={countries}
                            value={selectedCountry}
                            isOptionEqualToValue={(option, value) => option === value}
                            onChange={(event, newValue: any) => {
                              if (typeof newValue === 'string') {
                                setSelectedCountry({
                                  label: newValue,
                                  isoCode: ''
                                });
                                setFieldValue('emergencyCountry', newValue);
                              } else if (newValue && newValue.inputValue) {
                                setSelectedCountry({
                                  label: newValue.inputValue,
                                  isoCode: ''
                                });
                                setFieldValue('emergencyCountry', newValue.inputValue);
                              } else {
                                setSelectedCountry(newValue);
                                setFieldValue('emergencyCountry', newValue);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="outlined"
                                error={touched['emergencyCountry'] && Boolean(errors['emergencyCountry'])}
                                name="emergencyCountry"
                                required
                                type="text"
                                placeholder="Country"
                                label="Country"
                                {...params}
                              />
                            )}
                          />
                        </Grid>
                        {/* state */}
                        <Grid item xs={12} md={6}>
                          <Autocomplete
                            fullWidth
                            data-testid="emergencyState"
                            size="small"
                            options={states}
                            value={selectedState}
                            onChange={(event: any, newValue: any | null) => {
                              if (typeof newValue === 'string') {
                                setSelectedState({
                                  label: newValue
                                });
                                setFieldValue('emergencyState', newValue);
                              } else if (newValue && newValue.inputValue) {
                                setSelectedState({
                                  label: newValue.inputValue
                                });
                                setFieldValue('emergencyState', newValue.inputValue);
                              } else {
                                setSelectedState(newValue);
                                setFieldValue('emergencyState', newValue);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="outlined"
                                value={selectedState?.label}
                                error={touched['emergencyState'] && Boolean(errors['emergencyState'])}
                                name="emergencyState"
                                required
                                type="text"
                                placeholder="Province"
                                label="Province"
                                {...params}
                              />
                            )}
                          />
                        </Grid>
                        {/* city */}
                        <Grid item xs={12} md={6}>
                          <Autocomplete
                            fullWidth
                            data-testid="emergencyCity"
                            size="small"
                            options={cities}
                            value={selectedCity}
                            onChange={(event: any, newValue: any | null) => {
                              if (typeof newValue === 'string') {
                                setSelectedCity({
                                  label: newValue
                                });
                                setFieldValue('emergencyCity', newValue);
                              } else if (newValue && newValue.inputValue) {
                                setSelectedCity({
                                  label: newValue.inputValue
                                });
                                setFieldValue('emergencyCity', newValue.inputValue);
                              } else {
                                setSelectedCity(newValue);
                                setFieldValue('emergencyCity', newValue);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="outlined"
                                error={touched['emergencyCity'] && Boolean(errors['emergencyCity'])}
                                name="emergencyCity"
                                value={selectedCity?.label}
                                required
                                type="text"
                                placeholder="City"
                                label="City"
                                {...params}
                              />
                            )}
                          />
                        </Grid>
                        {/* ZIP */}
                        <Grid item xs={12} md={6}>
                          <TextField
                            required
                            fullWidth
                            data-testid="emergencyZipCode"
                            variant="outlined"
                            type="text"
                            placeholder="Postal Code"
                            label="Postal Code"
                            name="emergencyZipCode"
                            size="small"
                            onChange={(e) => setFieldValue('emergencyZipCode', e.target.value)}
                            value={values['emergencyZipCode']}
                            error={touched['emergencyZipCode'] && Boolean(errors['emergencyZipCode'])}
                          />
                        </Grid>
                        {/* address */}
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            multiline
                            rows={3}
                            data-testid="emergencyAddress"
                            variant="outlined"
                            type="text"
                            placeholder="Address"
                            label="Address"
                            name="emergencyAddress"
                            size="small"
                            onChange={(e) => setFieldValue('emergencyAddress', e.target.value)}
                            value={values['emergencyAddress']}
                            error={touched['emergencyAddress'] && Boolean(errors['emergencyAddress'])}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              </CustomDialogContent>
              <CustomDialogFooter>
                <Button variant="outlined" onClick={onClose} size="small">
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={submitForm}
                  size="small"
                  disabled={isSubmitting}
                  endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                >
                  Save
                </Button>
              </CustomDialogFooter>
            </>
          )}
        </Formik>
      ) : (
        <Box height={500} bgcolor={mode === 'dark' ? 'gray.900' : 'white'}>
          <CommonSkeleton lenArray={[...Array(10).keys()]} />
        </Box>
      )}
    </Dialog>
  );
};

export default ManageUserDialog;
