import React from 'react';
import { Box, Button, Dialog, Grid, TextField, Autocomplete, CircularProgress, FormControlLabel, Checkbox, useTheme } from '@mui/material';
import { Formik } from 'formik';
import { isMobile, isTablet } from 'react-device-detect';
import CustomDialogHeader from 'src/components/CustomDialog/CustomDialogHeader';
import CustomDialogContent from 'src/components/CustomDialog/CustomDialogContent';
import CustomDialogFooter from 'src/components/CustomDialog/CustomDialogFooter';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import CommonSkeleton from 'src/components/Helpers/CommonSkeleton';
import routes from 'src/components/Helpers/Routes';

interface ManageOffersProps {
  onClose: () => void;
  handleSucess: () => void;
  offersId?: any;
  courseId?: any;
}

const ManageOffers = ({ onClose, handleSucess, offersId, courseId }: ManageOffersProps) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const formikRef = React.useRef(null);
  const toastConfig = React.useContext(CustomToastContext);
  const [fullScreen, setFullScreen] = React.useState(isMobile || isTablet);
  const [initialValues, setInitialValues] = React.useState<any>(null);

  React.useEffect(() => {
    if (offersId) {
      axiosInstance()
        .get(`${routes.offers.api}/${offersId}`)
        .then(({ data: { data } }) => {
          setInitialValues({
            qty: data?.qty,
            discount: data?.discount,
            discountType: data?.discountType,
            maxDiscount: data?.maxDiscount,
            status: data?.status
          });
        })
        .catch((error) => {});
    } else {
      setInitialValues({
        qty: 0,
        discount: 0,
        discountType: '',
        maxDiscount: 0,
        status: true
      });
    }
  }, [offersId]);

  const handleSubmit = async (values: any) => {
    const payload = {
      course: courseId,
      qty: values?.qty,
      discount: values?.discount,
      discountType: values?.discountType,
      maxDiscount: values?.maxDiscount,
      status: values?.status
    };

    if (offersId) {
      await axiosInstance()
        .put(`${routes.offers.api}`, { ...payload, _id: offersId })
        .then(({ data }) => {
          toastConfig.setToastConfig({
            open: true,
            type: 'success',
            message: data.message
          });
          handleSucess();
        })
        .catch((err) => {
          toastConfig.setToastConfig(err);
        });
    } else {
      await axiosInstance()
        .post(`${routes.offers.api}`, payload)
        .then(({ data }) => {
          toastConfig.setToastConfig({
            open: true,
            type: 'success',
            message: data.message
          });
          handleSucess();
        })
        .catch((err) => {
          toastConfig.setToastConfig(err);
        });
    }
  };

  const validate = (values: any) => {
    let errors: any = {};
    if (!values.qty) {
      errors.qty = 'Please enter qty';
    }
    if (!values.discountType) {
      errors.discountType = 'Please select discount type';
    }
    if (!values.discount) {
      errors.discount = 'Please enter discount';
    }
    return errors;
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen || isMobile || isTablet}
      aria-labelledby="customized-dialog-title"
      onClose={(e, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      open
    >
      {initialValues ? (
        <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={handleSubmit} validate={validate} enableReinitialize>
          {({ values, setFieldValue, submitForm, errors, touched, isSubmitting }) => (
            <>
              <CustomDialogHeader
                title={offersId ? 'Update Offers' : 'Add Offers'}
                onClose={onClose}
                isMinimized={!fullScreen}
                onMinimizeMaximize={() => {
                  setFullScreen((prevState) => !prevState);
                }}
                showManimizeMaximize={true}
              />
              <CustomDialogContent>
                <Box sx={{ pt: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        size="small"
                        variant="outlined"
                        fullWidth
                        type="number"
                        label={'Qty'}
                        name="qty"
                        value={values.qty}
                        onChange={(e) => {
                          const value = parseFloat(e.target.value);
                          setFieldValue('qty', !isNaN(value) ? value : 0);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        size="small"
                        fullWidth
                        // options={["Percentage"]}
                        options={['Flat', 'Percentage']}
                        isOptionEqualToValue={(option, value) => option === value}
                        getOptionLabel={(option: any) => option}
                        value={values.discountType}
                        onChange={(_, newVal: any) => setFieldValue('discountType', newVal)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            required
                            name={'discountType'}
                            label={'Discount Type'}
                            error={touched['discountType'] && Boolean(errors['discountType'])}
                            helperText={touched['discountType'] && errors['discountType']?.toString()}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        size="small"
                        variant="outlined"
                        fullWidth
                        type="number"
                        label={'Discount'}
                        name="discount"
                        value={values.discount}
                        onChange={(e) => {
                          const value = parseFloat(e.target.value);
                          setFieldValue('discount', !isNaN(value) ? value : 0);
                        }}
                      />
                    </Grid>
                    {/* 
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                type="number"
                                                label={"Max Discount"}
                                                name="maxDiscount"
                                                value={values.maxDiscount}
                                                onChange={(e) => {
                                                    const value = parseFloat(e.target.value);
                                                    setFieldValue("maxDiscount", !isNaN(value) ? value : 0);
                                                }}
                                            />
                                        </Grid> */}

                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            onChange={(e: { target: { checked: any } }) => setFieldValue('status', e.target.checked)}
                            checked={values.status}
                            defaultChecked
                          />
                        }
                        label="Active"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </CustomDialogContent>
              <CustomDialogFooter>
                <Button variant="outlined" size="small" onClick={onClose} disabled={isSubmitting}>
                  Close
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={submitForm}
                  disabled={isSubmitting}
                  endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                >
                  Save
                </Button>
              </CustomDialogFooter>
            </>
          )}
        </Formik>
      ) : (
        <Box height={500} bgcolor={mode === 'dark' ? 'gray.900' : 'white'}>
          <CommonSkeleton lenArray={[...Array(10).keys()]} />
        </Box>
      )}
    </Dialog>
  );
};

export default ManageOffers;
