import { Box, TextField, Typography, Button, CircularProgress, Link as MuiLink, InputAdornment, Paper, useTheme } from '@mui/material';
import { useContext } from 'react';
import { Formik, Form } from 'formik';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { logo } from 'src/config';
import { AiOutlineMail } from 'react-icons/ai';
import { Link } from 'react-router-dom';

interface ForgetPasswordData {
  email: string;
}

const ForgetPassword = () => {
  const theme = useTheme();
  const toastConfig = useContext(CustomToastContext);
  const handleSubmit = async (values: ForgetPasswordData) => {
    axiosInstance()
      .post('/users/forget-password', {
        email: values.email
      })
      .then(({ data }) => {
        toastConfig.setToastConfig({
          open: true,
          message: data?.message,
          type: 'success'
        });
      })
      .catch((error) => {
        toastConfig.setToastConfig(error);
      });
  };

  const validateForm = (values: ForgetPasswordData) => {
    const errors: any = {};
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    return errors;
  };

  return (
    <Box sx={{ ...customStyles.container }}>
      <Paper sx={{ flexBasis: '487px', maxWidth: '487px' }}>
        <Box sx={{ ...customStyles.loginContainer, backgroundColor: theme.palette.mode === 'light' ? 'white' : theme.palette.background.paper }}>
          <Box
            sx={{
              maxWidth: '250px',
              maxHeight: '46px',
              margin: { xs: '0px auto 15px', sm: '0px auto 20px', md: '0px auto 25px', lg: '0px auto 30px' }
            }}
          >
            <img src={logo} alt="" style={{ maxWidth: '250px', maxHeight: '46px' }} />
          </Box>
          <Typography
            sx={{ ...customStyles.logoText, color: theme.palette.mode === 'light' ? '#3A3A3A' : theme.palette.getContrastText('#121212') }}
            mb={1}
          >
            Forget Password
          </Typography>
          <Box mt={'10px'}>
            <Formik
              initialValues={{
                email: ''
              }}
              validate={validateForm}
              onSubmit={handleSubmit}
            >
              {({ submitForm, values, errors, touched, setFieldValue, isSubmitting }) => (
                <Form>
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                      <Typography
                        style={{
                          ...customStyles.lebel,
                          color: theme.palette.mode === 'light' ? '#3A3A3A' : theme.palette.getContrastText(theme.palette.background.paper)
                        }}
                      >
                        Email
                      </Typography>
                      <div className="error">{touched.email && errors.email && <div>{errors.email}</div>}</div>
                    </Box>
                    <TextField
                      fullWidth
                      data-testid="email"
                      variant="outlined"
                      type="email"
                      placeholder="example@gmail.com"
                      name="email"
                      size="small"
                      onChange={(e) => setFieldValue('email', e.target.value)}
                      value={values['email']}
                      error={touched['email'] && Boolean(errors['email'])}
                      // helperText={touched['email'] && errors['email']?.toString()}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AiOutlineMail color={'#B0B6BF'} />
                          </InputAdornment>
                        )
                      }}
                    />
                    <MuiLink component={Link} to="/" sx={{ ...customStyles.forgotPassword }} mt={1} textAlign="right">
                      Back To Login Page
                    </MuiLink>
                    <Box>
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ ...customStyles.buttons }}
                        disabled={isSubmitting}
                        endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default ForgetPassword;

const customStyles = {
  container: {
    width: '100%',
    minHeight: '90vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 20px'
  },
  loginContainer: {
    maxWidth: '487px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    boxShadow: '0px 4px 60px rgba(0, 0, 0, 0.12)',
    borderRadius: '10px',
    padding: { xs: '35px', sm: '45px', md: '45px 50px', lg: '45px 70px' },
    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))'
  },
  logoText: {
    fontWeight: 600,
    fontSize: '28px',
    lineHeight: '42px',
    textAlign: 'center'
  },
  lebel: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
    marginBottom: '5px'
  },
  fields: {
    '> div': {
      height: '48px',
      input: {
        fontSize: '16px !important'
      },
      borderRadius: '8px !important'
    }
  },
  buttons: {
    padding: '10px 16px',
    borderRadius: '10px !important',
    fontSize: '14px',
    marginTop: '20px',
    boxShadow: '0px 4px 30px rgba(22, 51, 64, 0.3)'
  },
  forgotPassword: {
    fontSize: '12px',
    color: '#878787',
    textDecoration: 'none',
    display: 'block'
  }
};
