import React, { Fragment } from 'react';
import { Button, Dialog, Box, Grid, TextField, Typography, CircularProgress, Autocomplete, useTheme } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers';
import moment from 'moment-timezone';
import { min, max } from 'lodash';

import { CustomDialogHeader, CustomDialogContent, CustomDialogFooter } from 'src/components/CustomDialog';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import CommonSkeleton from 'src/components/Helpers/CommonSkeleton';
import { useData } from 'src/context/Provider';

const RescheduleDialog = ({ onClose, instructorId, scheduleId, date, order, allClose }: any) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const { setToastConfig } = React.useContext(CustomToastContext);
  const {
    state: { brand }
  } = useData();
  const [loading, setLoading] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  const [selectedTimeSlot, setSelectedTimeSlot] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [courseData, setCourseData] = React.useState(null);
  const [blockDates, setBlockDates] = React.useState(null);
  const [calanderMinMax, setCalendarMinMax] = React.useState({ min: new Date(), max: new Date() });
  const [scheduleMaster, setScheduleMaster] = React.useState([]);
  const [schedules, setSchedules] = React.useState([]);

  React.useEffect(() => {
    fetchSchedule();
  }, []);

  const fetchSchedule = async () => {
    setLoading(true);

    axiosInstance()
      .get('/courses')
      .then(({ data: { data } }) => {
        const course = data?.find((e: any) => e?._id === order?.course?._id);
        setCourseData(course);
        const schedules = course?.schedule.filter((s: any) => s.instructor?.optionValue === instructorId);
        setScheduleMaster(schedules);

        let minDate: any = min(schedules.map((e: any) => moment(e.startDate)));
        minDate = max([minDate, new Date().setDate(new Date().getDate() + 1)]);
        let maxDate: any = max(schedules.map((e: any) => moment(e.endDate)));

        setCalendarMinMax({ min: new Date(minDate), max: new Date(maxDate) });

        axiosInstance()
          .get(`/sessions/blocked/${course._id}`)
          .then(({ data: { data } }) => {
            setBlockDates(data?.blockDates);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            setToastConfig(err);
          });
      })
      .catch((err) => {
        setToastConfig(err);
        setLoading(false);
      });
  };

  const isDisableDate = (date: any) => {
    if (scheduleMaster?.length) {
      let isBlock = true;
      scheduleMaster?.forEach((schedule: any) => {
        if (!schedule?.blockDayNames?.includes(moment(date).format('dddd'))) {
          isBlock = false;
        }
      });
      if (isBlock) {
        return isBlock;
      }
    }
    const sheduleBooked = blockDates?.filter((e: any) => moment(e?.date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'));
    if (sheduleBooked?.length === 0) {
      return false;
    }
    if (sheduleBooked?.length === scheduleMaster?.length) {
      return true;
    }
    return false;
  };

  const handleSelectDate = (date: any) => {
    setSelectedDate(date);
    setSelectedTimeSlot(null);
    let schedule = scheduleMaster?.filter((o: any) => {
      return (
        moment(moment(date), 'YYYY-MM-DD').isBetween(moment(o?.startDate, 'YYYY-MM-DD'), moment(o?.endDate, 'YYYY-MM-DD'), undefined, '[]') &&
        !o?.blockDayNames?.includes(moment(date).format('dddd'))
      );
    });
    schedule = schedule.filter(
      (s: any) =>
        blockDates.findIndex((b: any) => moment(b.date).format('DD/MM/YYYY') === moment(date).format('DD/MM/YYYY') && b.scheduleId === s._id) < 0
    );
    setSchedules(schedule);
  };

  const handleSave = () => {
    setSaving(true);
    axiosInstance()
      .put(`/re-schedule`, {
        orderId: order._id,
        new: {
          scheduleId: selectedTimeSlot?._id,
          startDate: selectedDate,
          endDate: selectedDate,
          startTime: selectedTimeSlot.startTime,
          endTime: selectedTimeSlot.endTime
        },
        old: {
          scheduleId: scheduleId,
          startDate: date,
          instructor: instructorId
        }
      })
      .then(() => {
        setSaving(false);
        allClose();
      })
      .catch((err) => {
        setSaving(false);
        setToastConfig(err);
      });
  };

  const getAutoCompleteLabels = (item: any) => {
    const condition = blockDates?.some(
      (e: any) =>
        moment(e?.date).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD') &&
        item?._id === e?.scheduleId &&
        item?.instructor?.optionValue === e?.instructor
    );
    if (!condition) {
      return moment(item.startTime).format('h:mm A') + '-' + moment(item.endTime).format('h:mm A');
    }
  };

  const renderOptions = (props: any, item: any) => {
    const condition = blockDates?.some(
      (e: any) =>
        moment(e?.date).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD') &&
        item?._id === e?.scheduleId &&
        item?.instructor?.optionValue === e?.instructor
    );
    if (!condition) {
      return (
        <Box
          {...props}
          component="li"
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '20px',
            width: '100%'
          }}
        >
          <Typography color={'inherit'} flexGrow={1}>
            {moment(item.startTime).format('h:mm A')} - {moment(item.endTime).format('h:mm A')}{' '}
          </Typography>
          <Typography variant="body1" component={'span'} textAlign="right" flexGrow={1}>
            {item.instructor?.optionLabel}
          </Typography>
        </Box>
      );
    }
  };

  return (
    <Dialog
      open
      maxWidth="md"
      fullWidth
      onClose={(e, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
    >
      <CustomDialogHeader showRequiredLabel={false} onClose={onClose} title={'Re-schedule ' + courseData ? courseData?.courseName : ''} />
      <CustomDialogContent>
        {loading || !courseData ? (
          <Box height={500} bgcolor={mode === 'dark' ? 'gray.900' : 'white'}>
            <CommonSkeleton lenArray={[...Array(10).keys()]} />
          </Box>
        ) : (
          <Box pt={3}>
            <Grid container spacing={{ xs: 2, sm: 3, md: 3 }}>
              <Grid item xs={12} md={6}>
                <span>Select Date</span>
                <Box
                  sx={{
                    maxWidth: 'max-content',
                    margin: { xs: '0 auto', md: 'unset' },
                    boxShadow: '21px 31px 52px rgba(0, 0, 0, 0.05)',
                    borderRadius: '36px',
                    overflow: 'hidden'
                  }}
                >
                  <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    value={selectedDate}
                    onChange={(newValue) => {
                      handleSelectDate(newValue);
                    }}
                    shouldDisableDate={isDisableDate}
                    minDate={calanderMinMax.min}
                    maxDate={calanderMinMax.max}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                {selectedDate && (
                  <Fragment>
                    <span>Select Time Slot</span>
                    <Autocomplete
                      value={selectedTimeSlot}
                      onChange={(event: any, newValue: any | null) => {
                        setSelectedTimeSlot(newValue);
                      }}
                      id="combo-box-demo"
                      options={schedules}
                      sx={{ width: '100%', mt: 1 }}
                      renderInput={(params) => <TextField {...params} placeholder="Select Time Slot" />}
                      getOptionLabel={getAutoCompleteLabels}
                      renderOption={renderOptions}
                    />
                  </Fragment>
                )}
              </Grid>
            </Grid>
          </Box>
        )}
      </CustomDialogContent>
      <CustomDialogFooter>
        <Button variant="outlined" size="small" onClick={onClose} disabled={saving}>
          Close
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={handleSave}
          disabled={saving}
          endIcon={saving && <CircularProgress size={18} color="inherit" />}
        >
          Save
        </Button>
      </CustomDialogFooter>
    </Dialog>
  );
};

export default RescheduleDialog;
