import { SET_USER, SET_BRAND, USER_LOADING} from './ActionTypes';

export interface InitialStateType {
  user: any;
  userLoading: boolean;
  brand:any;
}

export interface ActionTypes {
  type: string;
  payload: any;
}

export const initialState: InitialStateType = {
  user: null,
  userLoading: true,
  brand:null
};

const reducer = (state = initialState, action: ActionTypes) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload };
    case SET_BRAND:
      return { ...state, brand: action.payload };
    case USER_LOADING:
      return { ...state, userLoading: action.payload };
    default:
      return state;
  }
};

export default reducer;
