import React, { useContext, useEffect, useState } from 'react';
import { Box, Divider, IconButton, List, ListItem, ListItemText, Paper, Typography, useTheme } from '@mui/material';
import { axiosInstance } from 'src/axios';
import routes from 'src/components/Helpers/Routes';
import { Email } from '@mui/icons-material';
import customToast from 'components/Helpers/CustomToast';
import { CustomToastContext } from 'src/context/CustomToastContext';

interface WaitlistProps {
  courseId?: any;
}
const Waitlist = ({ courseId }: WaitlistProps) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const toastConfig = useContext(CustomToastContext);

  const [waitlists, setWaitlists] = useState(null);
  const [waitlistButton, setWaitlistButton] = useState(false);

  useEffect(() => {
    if (courseId) {
      fetchWaitlists().then();
    }
  }, [courseId]);

  const fetchWaitlists = async () => {
    await axiosInstance()
      .get(`${routes.courses.api}/${courseId}/waitlist`)
      .then(({ data: { data } }) => {
        setWaitlists(data);
      })
      .catch((error) => {});
  };

  const handleWaitlistEmail = async () => {
    setWaitlistButton(true);
    await axiosInstance()
      .get(`${routes.courses.api}/${courseId}/waitlist/send-email`)
      .then(() => {
        toastConfig.setToastConfig({
          open: true,
          message: 'Email sent to all the Waitlisted users',
          type: 'success'
        });
        setWaitlistButton(false);
      })
      .catch((error) => {
        toastConfig.setToastConfig(error);
        setWaitlistButton(false);
      });
  };

  return (
    <Box sx={{ p: 1 }}>
      <Box mb={2}>
        <Paper style={{ overflow: 'hidden' }}>
          <Box padding={1} bgcolor={mode === 'dark' ? 'grey.800' : 'grey.200'} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle2">Waitlists</Typography>
            <IconButton title="Send Email" color="primary" size="small" disabled={waitlistButton} onClick={handleWaitlistEmail}>
              <Email fontSize="small" />
            </IconButton>
          </Box>
          {waitlists?.length ? (
            <Box width="100%">
              {waitlists.map((obj: any) => (
                <List dense key={obj?._id}>
                  <ListItem>
                    <ListItemText primary={obj?.studentId?.optionLabel} secondary={`Email : ${obj?.studentId?.email}`} />
                    <ListItemText primary={`Contact No. : ${obj?.studentId?.phone}`} />
                  </ListItem>
                  <Divider light />
                </List>
              ))}
            </Box>
          ) : (
            <Box textAlign="center" padding={2} minHeight={100}>
              <Typography>No Waitlist Found</Typography>
            </Box>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default Waitlist;
