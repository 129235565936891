import { useState, useContext, useEffect } from 'react';
import { Button, Dialog, TextField, Grid, CircularProgress, FormControlLabel, Box, Checkbox, useTheme } from '@mui/material';
import { Formik } from 'formik';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { CustomDialogHeader, CustomDialogContent, CustomDialogFooter } from 'src/components/CustomDialog';
import { isMobile, isTablet } from 'react-device-detect';
import CommonSkeleton from 'src/components/Helpers/CommonSkeleton';
import routes from 'src/components/Helpers/Routes';

const ManagePaymentLinksDialog = ({ onClose, handleSucess }: any) => {
  const theme = useTheme();
  const mode = theme.palette.mode;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const toastConfig = useContext(CustomToastContext);
  const [initialValues, setInitialValues] = useState(null);
  const [fullScreen, setFullScreen] = useState(isMobile || isTablet);

  useEffect(() => {
    setInitialValues({
      name: '',
      email: '',
      phone: '',
      amount: 0,
      detail: ''
    });
  }, []);

  const handleSubmit = (values: any) => {
    setIsSubmitting(true);
    axiosInstance()
      .post(`${routes.paymentLinks.api}`, values)
      .then(({ data }) => {
        setIsSubmitting(false);
        toastConfig.setToastConfig({
          open: true,
          type: 'success',
          message: data.message
        });
        handleSucess();
      })
      .catch((error) => {
        toastConfig.setToastConfig(error);
        setIsSubmitting(false);
      });
  };

  const validate = (values: any) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = 'Please enter name';
    }
    if (!values.email) {
      errors.email = 'Please enter email';
    }
    if (!values.amount) {
      errors.amount = 'Please enter amount';
    }
    if (!values.phone) {
      errors.phone = 'Please enter phone';
    }
    if (!values.detail) {
      errors.detail = 'Please enter detail';
    }
    return errors;
  };

  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth
        fullScreen={fullScreen || isMobile || isTablet}
        aria-labelledby="customized-dialog-title"
        onClose={(e, reason) => {
          if (reason !== 'backdropClick') {
            onClose();
          }
        }}
        open
      >
        {initialValues ? (
          <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
            {({ submitForm, values, errors, touched, setFieldValue }) => (
              <>
                <CustomDialogHeader
                  title={'Create'}
                  onClose={onClose}
                  isMinimized={!fullScreen}
                  onMinimizeMaximize={() => {
                    setFullScreen((prevState) => !prevState);
                  }}
                  showManimizeMaximize={true}
                />
                <CustomDialogContent>
                  <Box sx={{ pt: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          type="text"
                          size="small"
                          required
                          name={'name'}
                          label={'Name'}
                          value={values.name}
                          onChange={(e) => setFieldValue('name', e.target.value)}
                          error={touched.name && Boolean(errors.name)}
                          helperText={(touched.name && errors.name)?.toString()}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          type="text"
                          size="small"
                          required
                          name={'email'}
                          label={'Email'}
                          value={values.email}
                          onChange={(e) => setFieldValue('email', e.target.value)}
                          error={touched.email && Boolean(errors.email)}
                          helperText={(touched.email && errors.email)?.toString()}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          type="text"
                          size="small"
                          required
                          name={'phone'}
                          label={'Phone'}
                          value={values.phone}
                          onChange={(e) => setFieldValue('phone', e.target.value)}
                          error={touched.phone && Boolean(errors.phone)}
                          helperText={(touched.phone && errors.phone)?.toString()}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          type="number"
                          size="small"
                          required
                          name={'amount'}
                          label={'Amount'}
                          value={values.amount}
                          onChange={(e) => {
                            const value = parseFloat(e.target.value);
                            setFieldValue('amount', !isNaN(value) ? value : 0);
                          }}
                          error={touched.amount && Boolean(errors.amount)}
                          helperText={(touched.amount && errors.amount)?.toString()}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          type="text"
                          size="small"
                          required
                          name={'detail'}
                          label={'Detail'}
                          multiline
                          value={values.detail}
                          onChange={(e) => setFieldValue('detail', e.target.value)}
                          error={touched.detail && Boolean(errors.detail)}
                          helperText={(touched.detail && errors.detail)?.toString()}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </CustomDialogContent>
                <CustomDialogFooter>
                  <Button variant="outlined" onClick={onClose} size="small">
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={submitForm}
                    size="small"
                    disabled={isSubmitting}
                    endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                  >
                    Save
                  </Button>
                </CustomDialogFooter>
              </>
            )}
          </Formik>
        ) : (
          <Box height={500} bgcolor={mode === 'dark' ? 'gray.900' : 'white'}>
            <CommonSkeleton lenArray={[...Array(10).keys()]} />
          </Box>
        )}
      </Dialog>
    </>
  );
};

export default ManagePaymentLinksDialog;
