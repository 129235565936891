import React from 'react';
import { Typography, Box, Button, Menu, MenuItem, IconButton } from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import CoursesIcon from '@mui/icons-material/LibraryBooks';
import ArrowDownIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { MdDelete } from 'react-icons/md';
import { Link } from 'react-router-dom';
import ManageCourses from './ManageCourses';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import routes from 'src/components/Helpers/Routes';
import CustomBreadcrumbs from 'src/components/Helpers/CustomBreadcrumbs';
import CustomToolbar from 'src/components/Helpers/CustomToolbar';
import ConfirmationDialog from 'src/components/Helpers/ConfirmationDialog';
import { siteName } from 'src/config';
import { COURSE_TYPES_ARRAY_SITE1, COURSE_TYPES_ARRAY_SITE2 } from 'src/constants/helpers';

const Courses = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { setToastConfig } = React.useContext(CustomToastContext);
  const [openDialog, setOpenDialog] = React.useState({
    open: false,
    courseId: null
  });
  const [gridState, setGridState] = React.useState({
    limit: 20,
    page: 0
  });
  const [searchValue, setSearchValue] = React.useState('');
  const [rows, setRows] = React.useState([]);
  const [searchedRows, setSearchedRows] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [count, setCount] = React.useState(1);
  const [deleting, setDeleting] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showDeleteConfirmBox, setShowDeleteConfirmBox] = React.useState(false);
  const [deleteRecord, setDeleteRecord] = React.useState(null);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = () => {
    setLoading(true);
    axiosInstance()
      .get(routes.courses.api)
      .then(({ data }) => {
        const newData = data.data.map((d: any) => ({
          ...d,
          id: d._id
        }));
        setRows(newData);
        setCount(data.count);
        setLoading(false);
      })
      .catch((err) => {
        setToastConfig(err);
        setLoading(false);
      });
  };

  const columns: GridColDef[] = [
    {
      field: 'courseName',
      headerName: 'Course Name',
      width: 250,
      renderCell: (params: GridValueGetterParams) => {
        return <Link to={`${routes.coursesDetails.path}/${params.row._id}`}>{params.row.courseName}</Link>;
      }
    },
    {
      field: 'details',
      headerName: 'Details',
      width: 250
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 250,
      renderCell: (params: GridValueGetterParams) => {
        const typeArray = siteName === 'Site1' ? COURSE_TYPES_ARRAY_SITE1 : COURSE_TYPES_ARRAY_SITE2;
        return typeArray?.find((t) => t.value === params.row.type)?.label || '';
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      valueGetter: (params) => {
        if (params.value) {
          return 'Active';
        } else {
          return 'Inactive';
        }
      },
      width: 150
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params: any) => {
        return (
          <Box>
            <IconButton
              onClick={() => {
                setDeleteRecord([params.row._id]);
                setShowDeleteConfirmBox(true);
              }}
            >
              <MdDelete size={18} color="red" />
            </IconButton>
          </Box>
        );
      },
      headerAlign: 'left',
      align: 'left'
    }
  ];

  const handleDelete = () => {
    setDeleting(true);
    axiosInstance()
      .put(`${routes.courses.api}/remove`, {
        ids: deleteRecord
      })
      .then(() => {
        setDeleting(false);
        setDeleteRecord(null);
        setShowDeleteConfirmBox(false);
        fetchCourses();
      })
      .catch((err) => {
        setDeleting(false);
        setToastConfig(err);
      });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trimStart();
    setSearchValue(value);
    const findRows = rows.filter((row: any) => {
      const searchTerm = value.toLowerCase();
      return row?.courseName.toLowerCase().includes(searchTerm);
    });
    setSearchedRows(findRows);
  };

  return (
    <React.Fragment>
      <Box sx={{ p: 1 }}>
        <CustomBreadcrumbs routes={[routes.courses]} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 42,
            pl: 1,
            pr: 1
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CoursesIcon fontSize="small" color="primary" />
            <Box mr={1} />
            <Typography variant="h6" color="GrayText">
              Courses
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              id="add-button"
              aria-controls={'add-button'}
              onClick={() => setOpenDialog({ open: true, courseId: null })}
              size="small"
              variant="contained"
              endIcon={<AddIcon />}
            >
              Add
            </Button>
            <Box mr={1} />
            <Button
              id="actions-button"
              aria-controls={open ? 'actions-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleOpen}
              size="small"
              disabled={selectedRows.length === 0 || deleting}
              variant="outlined"
              endIcon={<ArrowDownIcon />}
            >
              Actions
            </Button>
            <Menu
              id="actions-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'actions-button'
              }}
            >
              <MenuItem
                onClick={() => {
                  setDeleteRecord(selectedRows);
                  setShowDeleteConfirmBox(true);
                  handleClose();
                }}
              >
                Delete
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <Box pt={1}>
          <DataGrid
            sx={{ height: 'calc(100vh - 150px)' }}
            loading={loading}
            rows={searchedRows.length > 0 ? searchedRows : rows}
            columns={columns}
            pagination
            rowCount={count}
            paginationMode="client"
            pageSize={gridState.limit}
            onPageSizeChange={(newSize) => setGridState((s) => ({ ...s, limit: newSize }))}
            onPageChange={(newPage) => setGridState((s) => ({ ...s, page: newPage }))}
            page={gridState.page}
            components={{ Toolbar: CustomToolbar }}
            rowsPerPageOptions={[5, 20, 50, 100]}
            componentsProps={{
              toolbar: {
                value: searchValue,
                onChange: handleSearch,
                onRefresh: fetchCourses
              }
            }}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(selection) => {
              setSelectedRows(selection);
            }}
          />
        </Box>
      </Box>
      {openDialog.open && (
        <ManageCourses
          courseId={openDialog.courseId}
          onClose={() => {
            setOpenDialog({ open: false, courseId: null });
          }}
          handleSucess={() => {
            setOpenDialog({ open: false, courseId: null });
            fetchCourses();
          }}
        />
      )}
      {showDeleteConfirmBox && (
        <ConfirmationDialog
          open={showDeleteConfirmBox}
          message={`Are you sure you want to delete course ?`}
          onClose={() => {
            setDeleteRecord(null);
            setShowDeleteConfirmBox(false);
          }}
          onOk={handleDelete}
        />
      )}
    </React.Fragment>
  );
};

export default Courses;
