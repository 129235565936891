import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, IconButton, Paper, Typography, useTheme } from '@mui/material';
import { axiosInstance } from 'src/axios';
import routes from 'src/components/Helpers/Routes';
import { ControlPoint } from '@mui/icons-material';
import ManageSchedule from './ManageSchedule';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { CustomToastContext } from 'src/context/CustomToastContext';
import CommonSkeleton from 'src/components/Helpers/CommonSkeleton';
import moment from 'moment-timezone';
import ConfirmationDialog from 'src/components/Helpers/ConfirmationDialog';
import { DataGrid, GridColDef, GridToolbar, GridValueGetterParams } from '@mui/x-data-grid';
import { useData } from 'src/context/Provider';

interface ScheduleProps {
  courseId?: any;
}

const Schedule = ({ courseId }: ScheduleProps) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const {
    state: { brand }
  } = useData();
  const [openDialog, setOpenDialog] = useState({ open: false, clone: false });
  const [scheduleId, setScheduleId] = useState(null);
  const toastConfig = useContext(CustomToastContext);
  const [showDeleteConfirmBox, setShowDeleteConfirmBox] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [count, setCount] = React.useState(1);
  const [gridState, setGridState] = React.useState({
    limit: 20,
    page: 0
  });

  useEffect(() => {
    if (courseId) {
      fetchSchedule();
    }
  }, [courseId]);

  const fetchSchedule = async () => {
    setLoading(true);
    await axiosInstance()
      .get(`${routes.courses.api}/${courseId}/schedule`)
      .then(({ data: { data } }) => {
        setRows(data);
        setCount(data?.length);
        setLoading(false);
      })
      .catch((error) => {
        toastConfig.setToastConfig(error);
      });
  };

  const handleDelete = async () => {
    await axiosInstance()
      .put(`${routes.courses.api}/${courseId}/schedule/remove`, { ids: deleteRecord })
      .then(({ data }) => {
        toastConfig.setToastConfig({
          open: true,
          type: 'success',
          message: data.message
        });
        fetchSchedule();
        setDeleteRecord(null);
        setShowDeleteConfirmBox(false);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  const columns: GridColDef[] = [
    {
      field: 'instructor',
      headerName: 'Instructor',
      width: 120,
      valueGetter: (params: GridValueGetterParams) => {
        return params.value.optionLabel;
      }
    },
    {
      field: 'startDate',
      headerName: 'Date',
      width: 210,
      valueGetter: (params: GridValueGetterParams) => {
        return `${moment(params.row.startDate).format('Do MMM YY')} - ${moment(params.row.endDate).format('Do MMM YY')}`;
      }
    },
    {
      field: 'startTime',
      headerName: 'Time',
      width: 190,
      valueGetter: (params: GridValueGetterParams) => {
        return `${moment(params.row.startTime).format('h:mm A')} - ${moment(params.row.endTime).format('h:mm A')}`;
      }
    },
    {
      field: 'blockDayNames',
      headerName: 'Block Day Names',
      width: 210
    },
    {
      field: 'label',
      headerName: 'Label',
      width: 120
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            <IconButton
              onClick={() => {
                setScheduleId(params.row?._id);
                setOpenDialog({ open: true, clone: false });
              }}
              edge="end"
              color="primary"
              aria-label="delete"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setScheduleId(params.row?._id);
                setOpenDialog({ open: true, clone: true });
              }}
              edge="end"
              color="primary"
              aria-label="delete"
            >
              <FileCopyIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setDeleteRecord([params.row._id]);
                setShowDeleteConfirmBox(true);
              }}
              edge="end"
              aria-label="delete"
            >
              <DeleteIcon color="error" />
            </IconButton>
          </>
        );
      },
      headerAlign: 'left',
      align: 'left'
    }
  ];

  return (
    <Box sx={{ pt: 1 }}>
      <Box mb={2}>
        <Paper style={{ overflow: 'hidden' }}>
          <Box padding={1} bgcolor={mode === 'dark' ? 'grey.800' : 'grey.200'} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle2">Schedule</Typography>
            <Box>
              <IconButton
                title="Add Schedule"
                color="primary"
                size="small"
                onClick={() => {
                  setOpenDialog({ open: true, clone: false });
                }}
              >
                <ControlPoint fontSize="small" />
              </IconButton>
              <IconButton
                title="Add Schedule"
                color="primary"
                size="small"
                disabled={selectedRows?.length === 0}
                onClick={() => {
                  setDeleteRecord(selectedRows);
                  setShowDeleteConfirmBox(true);
                }}
              >
                <DeleteIcon color={selectedRows?.length === 0 ? 'disabled' : 'error'} fontSize="small" />
              </IconButton>
            </Box>
          </Box>
          {rows ? (
            rows?.length ? (
              <Box width="100%">
                <DataGrid
                  sx={{ height: 'calc(100vh - 150px)' }}
                  loading={loading}
                  rows={rows}
                  columns={columns}
                  pagination
                  pageSize={gridState.limit}
                  page={gridState.page}
                  onPageSizeChange={(pageSize) => setGridState((s) => ({ ...s, limit: pageSize }))}
                  onPageChange={(page) => setGridState((s) => ({ ...s, page }))}
                  rowCount={count}
                  components={{ Toolbar: GridToolbar }}
                  rowsPerPageOptions={[5, 20, 50, 100]}
                  getRowId={(row) => row._id}
                  checkboxSelection
                  disableSelectionOnClick
                  onSelectionModelChange={(selection) => {
                    setSelectedRows(selection);
                  }}
                />
              </Box>
            ) : (
              <Box textAlign="center" padding={2} minHeight={100}>
                <Typography>No Schedule Found</Typography>
              </Box>
            )
          ) : (
            <Grid container spacing={2}>
              <CommonSkeleton lenArray={[...Array(5).keys()]} />
            </Grid>
          )}
        </Paper>
      </Box>
      {openDialog.open && (
        <ManageSchedule
          scheduleId={scheduleId}
          courseId={courseId}
          isClone={openDialog.clone}
          onClose={() => {
            setOpenDialog({ open: false, clone: false });
            setScheduleId(null);
          }}
          handleSucess={() => {
            setOpenDialog({ open: false, clone: false });
            setScheduleId(null);
            fetchSchedule();
          }}
        />
      )}
      {showDeleteConfirmBox && (
        <ConfirmationDialog
          open={showDeleteConfirmBox}
          message={`Are you sure you want to delete the schedule ?`}
          onClose={() => {
            setDeleteRecord(null);
            setShowDeleteConfirmBox(false);
            setScheduleId(null);
          }}
          onOk={handleDelete}
        />
      )}
    </Box>
  );
};

export default Schedule;
