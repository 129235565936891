import React from 'react';
import { Autocomplete, Box, TextField, Typography, Button, CircularProgress, Menu, MenuItem } from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import ReportIcon from '@mui/icons-material/ListAlt';
import moment from 'moment-timezone';

import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import routes from 'src/components/Helpers/Routes';
import CustomBreadcrumbs from 'src/components/Helpers/CustomBreadcrumbs';
import { getQueryParams } from 'src/utils';
import CustomToolbar from 'src/components/Helpers/CustomToolbar';
import { useData } from 'src/context/Provider';
import ArrowDownIcon from '@mui/icons-material/ExpandMore';

const Report = () => {
  const toastConfig = React.useContext(CustomToastContext);
  const [loadingOptions, setLoadingOptions] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [selectedOptions, setSelectedOptions] = React.useState({
    instructor: null,
    course: null,
    reportType: null,
    student: null
  });

  const [searchValue, setSearchValue] = React.useState('');
  const [instructors, setInstructors] = React.useState([]);
  const [courses, setCourses] = React.useState([]);
  const [students, setStudents] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [paymentStatusChanging, setPaymentStatusChanging] = React.useState(false);
  const [selectedRecords, setSelectedRecords] = React.useState([]);

  const openMenu = Boolean(anchorEl);

  const [reportType, setReportType] = React.useState([
    { reportName: 'Date Wise', value: 'dateWise' },
    { reportName: 'Order Wise', value: 'orderWise' }
  ]);

  const [report, setReport] = React.useState([]);
  const [count, setCount] = React.useState(0);

  const [gridState, setGridState] = React.useState({
    limit: 20,
    page: 0
  });

  const {
    state: { brand }
  } = useData();

  React.useEffect(() => {
    fetchOptionsData();
  }, []);

  const fetchOptionsData = async () => {
    setLoadingOptions(true);
    try {
      let {
        data: { data: instructors }
      } = await axiosInstance().get(`${routes.users.api}?role=Instructor`);
      let {
        data: { data: students }
      } = await axiosInstance().get(`${routes.users.api}?role=Student`);
      let {
        data: { data: courses }
      } = await axiosInstance().get(`${routes.courses.api}`);
      instructors = instructors.map((user: any) => ({
        ...user,
        fullName: `${user.firstName} ${user.lastName}`
      }));
      students = students.map((user: any) => ({
        ...user,
        fullName: `${user.firstName} ${user.lastName}`
      }));
      setCourses(courses);
      setInstructors(instructors);
      setStudents(students);
      setLoadingOptions(false);
    } catch (error) {
      setLoadingOptions(false);
      toastConfig.setToastConfig(error);
    }
  };

  React.useEffect(() => {
    if (selectedOptions?.course || selectedOptions?.instructor || selectedOptions?.reportType || selectedOptions.student || searchValue) {
      let timeout = setTimeout(fetchReport, 500);
      return () => clearTimeout(timeout);
    }
  }, [gridState, searchValue]);

  const getParams = () => {
    let params: string = '';
    if (selectedOptions?.instructor) {
      params = `${params}instructor=${selectedOptions.instructor._id}&`;
    }
    if (selectedOptions?.course) {
      params = `${params}course=${selectedOptions.course._id}&`;
    }
    if (selectedOptions?.student) {
      params = `${params}student=${selectedOptions.student._id}&`;
    }
    if (selectedOptions?.reportType) {
      params = `${params}reportType=${selectedOptions.reportType}&`;
    }
    return params;
  };

  const fetchReport = async () => {
    let query = getQueryParams(gridState);
    query = query + getParams();
    setLoading(true);
    try {
      const {
        data: { data }
      } = await axiosInstance().get(`${routes.report.api}?search=${searchValue}&${query}`);
      if (data) {
        let report = data.data.map((d: any, index: number) => ({
          ...d,
          id: d?._id + '-' + index,
          courseName: d?.course?.optionLabel,
          date: d?.bookDates?.startDate,
          time: d?.bookDates?.startTime,
          instructorName: d?.bookDates?.instructor?.optionLabel,
          totalStudents: d?.students?.length,
          totalBookDates: d?.bookDates?.length,
          studentsName: d?.students?.map((s: any) => `${s?.firstName} ${s?.lastName}`)?.join(', '),
          user: d?.user?.optionLabel,
          email: d?.user?.email,
          phone: d?.user?.phone
        }));
        setReport(report);
      }
      setCount(data.count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastConfig.setToastConfig(error);
    }
  };

  const handleSearch = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value.trimStart());
  }, []);

  const columnsDateWise: GridColDef[] = [
    { field: 'orderNumber', headerName: 'Order Number', width: 180 },
    { field: 'courseName', headerName: 'Course', width: 150 },
    {
      field: 'date',
      headerName: 'Date',
      width: 180,
      renderCell(params: GridValueGetterParams) {
        return moment(params.row.date).format('MM/DD/YYYY');
      }
    },
    {
      field: 'time',
      headerName: 'Time',
      width: 180,
      renderCell(params: GridValueGetterParams) {
        return moment(params.row.time).format('hh:mm A');
      }
    },
    { field: 'instructorName', headerName: 'Instructor', width: 250 },
    { field: 'totalStudents', headerName: 'Total Students', width: 150 },
    { field: 'studentsName', headerName: 'Students Name', width: 300 },
    {
      field: 'registrationDate',
      headerName: 'Registration Date',
      width: 200,
      renderCell(params: GridValueGetterParams) {
        return moment(params.row.registrationDate).format('MM/DD/YYYY hh:mm A');
      }
    },
    { field: 'paymentStatus', headerName: 'Payment Status', width: 120 },
    { field: 'paymentType', headerName: 'Payment Type', width: 120 },
    { field: 'netAmount', headerName: `Net Amount ${brand?.currency}`, width: 120 },
    { field: 'user', headerName: `User`, width: 250 },
    { field: 'email', headerName: `Email`, width: 250 },
    { field: 'phone', headerName: `Phone`, width: 250 }
  ];

  const columnsOrderWise: GridColDef[] = [
    { field: 'orderNumber', headerName: 'Order Number', width: 180 },
    { field: 'courseName', headerName: 'Course', width: 150 },
    { field: 'totalBookDates', headerName: 'Total Dates', width: 150 },
    { field: 'totalStudents', headerName: 'Total Students', width: 150 },
    {
      field: 'registrationDate',
      headerName: 'Registration Date',
      width: 200,
      renderCell(params: GridValueGetterParams) {
        return moment(params.row.registrationDate).format('MM/DD/YYYY hh:mm A');
      }
    },
    { field: 'paymentStatus', headerName: 'Payment Status', width: 120 },
    { field: 'paymentType', headerName: 'Payment Type', width: 120 },
    { field: 'netAmount', headerName: `Net Amount ${brand?.currency}`, width: 120 },
    { field: 'user', headerName: `User`, width: 250 },
    { field: 'email', headerName: `Email`, width: 250 },
    { field: 'phone', headerName: `Phone`, width: 250 }
  ];

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePaymentStatus = (status: string) => {
    setPaymentStatusChanging(true);
    const selectedIds = selectedRows.map((row) => row.split('-')[0]);
    axiosInstance()
      .put(`/orders/payment-status`, { status, ids: [...selectedIds] })
      .then((res) => {
        setSelectedRecords([]);
        setSelectedRows([]);
        setPaymentStatusChanging(false);
        fetchReport();
        toastConfig.setToastConfig({
          open: true,
          message: 'Payment status updated successfully',
          type: 'success'
        });
      })
      .catch((err) => {
        setPaymentStatusChanging(false);
        toastConfig.setToastConfig(err);
      });
    handleCloseMenu();
  };

  React.useEffect(() => {
    {
      const selected = report.filter((r) => selectedRows?.includes(`${r._id}`));
      setSelectedRecords(selected);
    }
  }, [selectedRows]);

  return (
    <React.Fragment>
      <Box sx={{ p: 1 }}>
        <CustomBreadcrumbs routes={[routes.report]} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 42,
            pl: 1,
            pr: 1
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ReportIcon fontSize="small" color="primary" />
            <Box mr={1} />
            <Typography variant="h6" color="GrayText">
              Report
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: '4px' }}>
          <Autocomplete
            loading={loadingOptions || loading}
            options={instructors}
            onChange={(_, newVal: any) => {
              setSelectedOptions((ps) => ({ ...ps, instructor: newVal }));
            }}
            getOptionLabel={(option) => option.fullName}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            sx={{ width: 280 }}
            renderInput={(params) => <TextField {...params} label={'Instructors'} size="small" />}
          />
          <Box mr={1} />
          <Autocomplete
            loading={loadingOptions || loading}
            options={courses}
            onChange={(_, newVal: any) => {
              setSelectedOptions((ps) => ({ ...ps, course: newVal }));
            }}
            getOptionLabel={(option) => option.courseName}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            sx={{ width: 280 }}
            renderInput={(params) => <TextField {...params} label={'Courses'} size="small" />}
          />
          <Box mr={1} />
          <Autocomplete
            loading={loadingOptions || loading}
            options={students}
            onChange={(_, newVal: any) => {
              setSelectedOptions((ps) => ({ ...ps, student: newVal }));
            }}
            getOptionLabel={(option) => option.fullName}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            sx={{ width: 280 }}
            renderInput={(params) => <TextField {...params} label={'Students'} size="small" />}
          />
          <Box mr={1} />
          <Autocomplete
            options={reportType}
            onChange={(_, newVal: any) => {
              if (newVal) {
                setSelectedOptions((ps) => ({ ...ps, reportType: newVal?.value || null }));
                setReport([]);
                setCount(0);
              }
            }}
            getOptionLabel={(option: any) => option.reportName}
            sx={{ width: 280 }}
            renderInput={(params) => <TextField {...params} label={'Report Type'} size="small" />}
          />
          <Box mr={1} />
          <Button
            id="submit-button"
            size="medium"
            disabled={loading}
            variant="contained"
            onClick={fetchReport}
            endIcon={loading && <CircularProgress size={20} color="inherit" />}
          >
            Show
          </Button>
          <Box mr={3} />

          <Button
            id="actions-button"
            aria-controls={open ? 'actions-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleOpenMenu}
            size="medium"
            disabled={selectedRows.length === 0 || selectedRecords?.some((row) => row?.paymentStatus === 'Refunded') || paymentStatusChanging}
            variant="outlined"
            endIcon={<ArrowDownIcon />}
          >
            Actions
          </Button>

          <Menu
            id="actions-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleCloseMenu}
            MenuListProps={{
              'aria-labelledby': 'actions-button'
            }}
          >
            <MenuItem
              disabled={
                !(selectedRecords?.filter((row) => row?.paymentStatus === 'Pending')?.length === selectedRecords?.length) || paymentStatusChanging
              }
              onClick={() => {
                handlePaymentStatus('Received');
              }}
            >
              Payment Received
            </MenuItem>
            <MenuItem
              disabled={
                !(
                  selectedRecords?.filter((row) => row?.paymentStatus === 'Received' && row.paymentType === 'Online')?.length ===
                  selectedRecords?.length
                ) || paymentStatusChanging
              }
              onClick={() => {
                handlePaymentStatus('Refunded');
              }}
            >
              Refund
            </MenuItem>
          </Menu>
        </Box>
        <Box mt={1}>
          <DataGrid
            sx={{ height: 'calc(100vh - 190px)' }}
            loading={loading}
            rows={report}
            columns={selectedOptions?.reportType === 'orderWise' ? columnsOrderWise : columnsDateWise}
            pageSize={gridState.limit}
            pagination
            paginationMode={'server'}
            page={gridState.page}
            onPageSizeChange={(pageSize) => setGridState((s) => ({ ...s, limit: pageSize }))}
            onPageChange={(page) => {
              setGridState((s) => ({ ...s, page }));
            }}
            rowCount={count}
            components={{ Toolbar: CustomToolbar }}
            checkboxSelection
            isRowSelectable={(params) => !params.row.superAdmin}
            componentsProps={{
              toolbar: {
                value: searchValue,
                onChange: handleSearch,
                onRefresh: fetchReport,
                hasExport: true,
                exportApi: `${routes.report.api}?${getParams()}`,
                fileName: 'SNEI-Report',
                header: [
                  'Order Number',
                  'Course',
                  'Date',
                  'Time',
                  'Instructor',
                  'Total Students',
                  'Students Name',
                  'Registration Date',
                  'Payment Status',
                  'Payment Type',
                  'Net Amount'
                ]
              }
            }}
            rowsPerPageOptions={[5, 20, 50, 100]}
            disableSelectionOnClick
            selectionModel={selectedRows}
            onSelectionModelChange={(selection: any) => {
              // const select = selection?.map((s: any) => `${s?.split('-')[0]}`);
              setSelectedRows(selection);
            }}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Report;
