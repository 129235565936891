import React from 'react';
import {
  Box,
  Button,
  Dialog,
  Grid,
  TextField,
  Autocomplete,
  CircularProgress,
  IconButton,
  Typography,
  Divider,
  FormControlLabel,
  Switch,
  Checkbox,
  useTheme
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Formik } from 'formik';
import { isMobile, isTablet } from 'react-device-detect';
import CustomDialogHeader from 'src/components/CustomDialog/CustomDialogHeader';
import CustomDialogContent from 'src/components/CustomDialog/CustomDialogContent';
import CustomDialogFooter from 'src/components/CustomDialog/CustomDialogFooter';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import CommonSkeleton from 'src/components/Helpers/CommonSkeleton';
import routes from 'src/components/Helpers/Routes';
import moment from 'moment-timezone';
import { useData } from 'src/context/Provider';

interface ManageScheduleProps {
  onClose: () => void;
  handleSucess: () => void;
  eventId?: any;
}

const ManageEventDialog = ({ eventId, onClose, handleSucess }: ManageScheduleProps) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const formikRef = React.useRef(null);
  const toastConfig = React.useContext(CustomToastContext);
  const {
    state: { brand }
  } = useData();
  const [users, setUsers] = React.useState(null);
  const [fullScreen, setFullScreen] = React.useState(isMobile || isTablet);
  const [initialValues, setInitialValues] = React.useState<any>(null);

  React.useEffect(() => {
    if (eventId) {
      axiosInstance()
        .get(`${routes.report.api}/events/${eventId}`)
        .then(({ data: { data } }) => {
          setInitialValues({
            startTime: data?.startTime,
            endTime: data?.endTime,
            startDate: data?.startDate,
            endDate: data?.endDate,
            blockDayNames: data?.blockDayNames || [],
            label: data?.label,
            information: data?.information,
            status: data?.status
          });
        })
        .catch((error) => {
          toastConfig.setToastConfig({
            open: true,
            type: 'error',
            message: error?.response?.data?.message
          });
        });
    } else {
      setInitialValues({
        startDate: new Date(),
        endDate: new Date(),
        startTime: new Date(),
        endTime: new Date(),
        status: true,
        blockDayNames: [],
        label: '',
        information: '',
        frequency: 0
      });
    }
  }, [eventId]);

  const handleSubmit = async (values: any) => {
    const payload: any = {
      startTime: values?.startTime,
      endTime: values?.endTime,
      startDate: values?.startDate,
      endDate: values?.endDate,
      blockDayNames: values?.blockDayNames,
      label: values?.label,
      information: values?.information,
      status: values?.status
    };
    if (eventId) {
      payload._id = eventId;
      await axiosInstance()
        .put(`${routes.report.api}/events`, payload)
        .then(({ data: data }) => {
          toastConfig.setToastConfig({
            open: true,
            type: 'success',
            message: data.message
          });
          handleSucess();
        })
        .catch((err) => {
          toastConfig.setToastConfig(err);
        });
    } else {
      payload.frequency = values?.frequency;
      await axiosInstance()
        .post(`${routes.report.api}/events`, payload)
        .then(({ data: data }) => {
          toastConfig.setToastConfig({
            open: true,
            type: 'success',
            message: data.message
          });
          handleSucess();
        })
        .catch((err) => {
          toastConfig.setToastConfig(err);
        });
    }
  };

  const validate = (values: any) => {
    let errors: any = {};
    const startDate = new Date(values?.startDate);
    const endDate = new Date(values?.endDate);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
    if (startDate > endDate) {
      errors['endDate'] = 'End date should be greater then start date';
    }
    if (moment(values?.startTime).hours() > moment(values?.endTime).hours()) {
      errors.endTime = 'End time should be greater then start time';
    }
    if (values?.label === '') {
      errors.label = 'Label is required';
    }
    return errors;
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen || isMobile || isTablet}
      aria-labelledby="customized-dialog-title"
      onClose={(e, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      open
    >
      {initialValues ? (
        <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={handleSubmit} validate={validate} enableReinitialize>
          {({ values, setFieldValue, submitForm, errors, touched, isSubmitting }) => (
            <>
              <CustomDialogHeader
                title={eventId ? 'Update Event' : 'Add Event'}
                onClose={onClose}
                isMinimized={!fullScreen}
                onMinimizeMaximize={() => {
                  setFullScreen((prevState) => !prevState);
                }}
                showManimizeMaximize={true}
              />
              <CustomDialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <DesktopDatePicker
                      label={'Start Date'}
                      value={values.startDate}
                      onChange={(newValue) => {
                        setFieldValue('startDate', newValue);
                      }}
                      inputFormat="MM/DD/YYYY"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="startDate"
                          size="small"
                          variant="outlined"
                          error={touched['startDate'] && Boolean(errors['startDate'])}
                          helperText={touched['startDate'] && errors['startDate']?.toString()}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DesktopDatePicker
                      label={'End Date'}
                      value={values.endDate}
                      onChange={(newValue) => {
                        setFieldValue('endDate', newValue);
                      }}
                      inputFormat="MM/DD/YYYY"
                      minDate={values.startDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="endDate"
                          size="small"
                          variant="outlined"
                          error={touched['endDate'] && Boolean(errors['endDate'])}
                          helperText={touched['endDate'] && errors['endDate']?.toString()}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TimePicker
                      value={values?.startTime}
                      onChange={(newValue) => {
                        setFieldValue('startTime', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={'Start Time'}
                          size="small"
                          variant="outlined"
                          error={touched['startTime'] && Boolean(errors['startTime'])}
                          helperText={touched['startTime'] && errors['startTime']?.toString()}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TimePicker
                      value={values?.endTime}
                      onChange={(newValue) => {
                        setFieldValue('endTime', newValue);
                      }}
                      minTime={moment(values?.startTime)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={'End Time'}
                          size="small"
                          variant="outlined"
                          error={touched['endTime'] && Boolean(errors['endTime'])}
                          helperText={touched['endTime'] && errors['endTime']?.toString()}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      type="text"
                      label="Label"
                      autoComplete="off"
                      name="label"
                      value={values.label}
                      onChange={(e) => {
                        setFieldValue('label', e.target.value);
                      }}
                      error={touched['label'] && Boolean(errors['label'])}
                      helperText={touched['label'] && errors['label']?.toString()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      type="text"
                      autoComplete="off"
                      label="Information"
                      name="information"
                      value={values.information}
                      onChange={(e) => {
                        setFieldValue('information', e.target.value);
                      }}
                      error={touched['information'] && Boolean(errors['information'])}
                      helperText={touched['information'] && errors['information']?.toString()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      value={values.blockDayNames}
                      multiple
                      onChange={(event: any, newValue: any) => {
                        setFieldValue('blockDayNames', newValue);
                      }}
                      id="controllable-states-demo"
                      options={['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']}
                      renderInput={(params) => <TextField {...params} size="small" label="Block Day Names" />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          onChange={(e: { target: { checked: any } }) => setFieldValue('status', e.target.checked)}
                          checked={values.status}
                          defaultChecked
                        />
                      }
                      label="Active"
                    />
                  </Grid>
                </Grid>
                {!eventId && (
                  <Box pt={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          size="small"
                          variant="outlined"
                          fullWidth
                          type="number"
                          label={'Frequency(minutes)'}
                          required
                          name="frequency"
                          value={values.frequency}
                          onChange={(e) => {
                            const value = parseFloat(e.target.value);
                            setFieldValue('frequency', !isNaN(value) ? value : 0);
                          }}
                          error={touched['frequency'] && Boolean(errors['frequency'])}
                          helperText={touched['frequency'] && errors['frequency']?.toString()}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </CustomDialogContent>
              <CustomDialogFooter>
                <Button variant="outlined" size="small" onClick={onClose} disabled={isSubmitting}>
                  Close
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={submitForm}
                  disabled={isSubmitting}
                  endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                >
                  Save
                </Button>
              </CustomDialogFooter>
            </>
          )}
        </Formik>
      ) : (
        <Box height={500} bgcolor={mode === 'dark' ? 'gray.900' : 'white'}>
          <CommonSkeleton lenArray={[...Array(10).keys()]} />
        </Box>
      )}
    </Dialog>
  );
};

export default ManageEventDialog;
