import React from 'react';
import { SvgIcon } from '@mui/material';

export const LoginIcon = (props: React.SVGAttributes<SVGSVGElement>) => {
  return (
    <svg {...props} viewBox="0 0 229 229">
      <path
        d="M147.999 47.5255V195.081C147.999 198.761 145.012 201.748 141.332 201.748H74.6653C70.9853 201.748 67.9986 198.761 67.9986 195.081V47.5255C67.9986 43.8455 70.9853 40.8589 74.6653 40.8589H141.332C145.012 40.8589 147.999 43.8455 147.999 47.5255Z"
        fill="#3A3A3A"
      />
      <path
        d="M147.999 114.637V195.081C147.999 198.761 145.012 201.748 141.332 201.748H74.6653C70.9853 201.748 67.9986 198.761 67.9986 195.081V114.637H147.999Z"
        fill="#06101F"
      />
      <path
        d="M224.746 41.5566L144.301 1.55653C139.861 -0.652363 134.665 2.59209 134.665 7.52543V221.748C134.665 226.29 139.119 229.504 143.43 228.077L223.875 201.41C226.603 200.504 228.443 197.957 228.443 195.081V47.5255C228.443 44.9966 227.012 42.6811 224.746 41.5566Z"
        fill="#FFB800"
      />
      <path
        d="M228.443 114.637V195.081C228.443 197.957 226.603 200.504 223.875 201.41L143.43 228.077C139.119 229.504 134.665 226.29 134.665 221.748V114.637H228.443Z"
        fill="#FFB800"
      />
      <path
        d="M174.666 101.303V127.97C174.666 131.65 171.679 134.637 167.999 134.637C164.319 134.637 161.332 131.65 161.332 127.97V101.303C161.332 97.6234 164.319 94.6368 167.999 94.6368C171.679 94.6368 174.666 97.6234 174.666 101.303Z"
        fill="#3A3A3A"
      />
      <path
        d="M174.666 114.637V127.97C174.666 131.65 171.679 134.637 167.999 134.637C164.319 134.637 161.332 131.65 161.332 127.97V114.637H174.666Z"
        fill="#06101F"
      />
      <path
        d="M105.332 109.303L51.9986 69.3034C47.6074 66.01 41.3319 69.1567 41.3319 74.6367V94.6368H7.55401C3.874 94.6368 0.887329 97.6234 0.887329 101.303V127.97C0.887329 131.65 3.874 134.637 7.55401 134.637H41.3319V154.637C41.3319 160.144 47.6386 163.241 51.9986 159.97L105.332 119.97C107.11 118.637 107.999 116.637 107.999 114.637C107.999 112.637 107.11 110.637 105.332 109.303Z"
        fill="#FFDA2D"
      />
      <path
        d="M107.999 114.637C107.999 116.637 107.11 118.637 105.332 119.97L51.9986 159.97C47.6386 163.241 41.3319 160.144 41.3319 154.637V134.637H7.55401C3.874 134.637 0.887329 131.65 0.887329 127.97V114.637H107.999Z"
        fill="#FDBF00"
      />
    </svg>
  );
};

export const StudentsIcon = (props: React.SVGAttributes<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 78" fill="none" {...props}>
      <path d="M67.398 36.034a.905.905 0 01-.825-.53l-4.082-8.978a.905.905 0 111.648-.75l4.082 8.979a.905.905 0 01-.823 1.28z" fill="#3C5A73" />
      <path
        d="M63.788 64.503L59.783 46.64H45.85a3.699 3.699 0 110-7.398h16.896c1.731 0 3.23 1.2 3.61 2.89l4.652 20.753a3.699 3.699 0 11-7.22 1.618z"
        fill="#414B82"
      />
      <path
        d="M37.543 67.35a1.508 1.508 0 01-1.106-1.823L40.781 47.8a1.509 1.509 0 012.93.718l-4.344 17.727a1.509 1.509 0 01-1.824 1.105zM57.22 66.244l-4.343-17.727a1.508 1.508 0 012.93-.718l4.344 17.727a1.508 1.508 0 01-2.93.718z"
        fill="#6C9BAA"
      />
      <path
        d="M47.679 18.283h-.345a7.842 7.842 0 00-7.842 7.842v13.077a8.025 8.025 0 008.025 8.025l9.075-7.985V27.196a8.913 8.913 0 00-8.913-8.913z"
        fill="#6EB416"
      />
      <path
        d="M57.047 15.34A6.843 6.843 0 1048.08 5.002a6.843 6.843 0 008.968 10.338zM66.632 36.19l-10.33-.761a3.083 3.083 0 01-1.99-.933l-2.51-2.597.785-3.928 3.796-.204 1.545 1.6 9.157.674a3.083 3.083 0 11-.453 6.149z"
        fill="#FFB69E"
      />
      <path d="M56.383 27.767l-6.125-6.339a3.083 3.083 0 00-4.433 4.284l5.977 6.187 4.58-4.132z" fill="#4C8603" />
      <path
        d="M58.25 50.777H38.597a2.64 2.64 0 01-2.606-2.227l-4.515-28.504a2.64 2.64 0 115.214-.826l4.162 26.278H58.25a2.64 2.64 0 110 5.279zM77.36 40.484H59.057a2.64 2.64 0 010-5.28H77.36a2.64 2.64 0 010 5.28z"
        fill="#965028"
      />
      <path
        d="M53.684 49.195a1.131 1.131 0 01-.164-1.59l8.516-10.474a1.131 1.131 0 011.755 1.427L55.275 49.03a1.131 1.131 0 01-1.591.164z"
        fill="#6C9BAA"
      />
      <path d="M35.954 46.326a.905.905 0 01-.825-.53l-4.082-8.978a.905.905 0 111.648-.75l4.082 8.978a.905.905 0 01-.823 1.28z" fill="#3C5A73" />
      <path
        d="M32.344 74.795l-4.005-17.863H14.406a3.699 3.699 0 110-7.398H31.3c1.731 0 3.23 1.2 3.61 2.89l4.652 20.753a3.699 3.699 0 11-7.22 1.618z"
        fill="#414B82"
      />
      <path
        d="M6.1 77.642a1.508 1.508 0 01-1.107-1.824l4.344-17.727a1.509 1.509 0 012.93.718L7.923 76.536a1.508 1.508 0 01-1.824 1.106zM25.777 76.536l-4.344-17.727a1.508 1.508 0 012.93-.718l4.343 17.727a1.508 1.508 0 01-2.93.718z"
        fill="#6C9BAA"
      />
      <path
        d="M16.235 28.575h-.345a7.842 7.842 0 00-7.842 7.842v13.076a8.025 8.025 0 008.025 8.026l9.075-7.985V37.488a8.913 8.913 0 00-8.913-8.913z"
        fill="#64AFFF"
      />
      <path
        d="M25.613 25.62a6.843 6.843 0 10-8.969-10.339 6.843 6.843 0 008.97 10.338zM35.188 46.481l-10.33-.76a3.083 3.083 0 01-1.99-.933l-2.51-2.597.785-3.929 3.796-.203 1.545 1.6 9.157.674a3.082 3.082 0 11-.453 6.148z"
        fill="#FFB69E"
      />
      <path d="M24.938 38.059l-6.124-6.34a3.083 3.083 0 00-4.434 4.285l5.978 6.187 4.58-4.132z" fill="#4699F2" />
      <path
        d="M26.805 61.068H7.155a2.64 2.64 0 01-2.607-2.226L.033 30.338a2.64 2.64 0 115.214-.826l4.162 26.277h17.397a2.64 2.64 0 110 5.28zM45.917 50.775H27.612a2.64 2.64 0 010-5.279h18.305a2.64 2.64 0 010 5.28z"
        fill="#A56941"
      />
      <path
        d="M22.24 59.487a1.131 1.131 0 01-.164-1.591l8.516-10.474a1.131 1.131 0 011.755 1.428l-8.516 10.473a1.131 1.131 0 01-1.591.164z"
        fill="#6C9BAA"
      />
    </svg>
  );
};

export const Instructors = (props: React.SVGAttributes<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 73" fill="none" {...props}>
      <script type="text/javascript" />
      <script type="text/javascript" />
      <path d="M59.914 40.626c-.605 0-1.094-.49-1.094-1.094v-3.964a1.094 1.094 0 112.187 0v3.964c0 .604-.49 1.094-1.093 1.094z" fill="#80B6FC" />
      <path
        d="M59.914 45.865a3.757 3.757 0 01-3.753-3.752 3.757 3.757 0 013.753-3.753 3.757 3.757 0 013.752 3.753 3.757 3.757 0 01-3.752 3.752zm0-5.161a1.41 1.41 0 00-1.409 1.409 1.41 1.41 0 001.409 1.409 1.41 1.41 0 001.408-1.41 1.41 1.41 0 00-1.408-1.408z"
        fill="#4980AC"
      />
      <path
        d="M27.07.808h-8.448a5.579 5.579 0 00-5.146 3.425h-1.878a5.089 5.089 0 00-5.09 5.089v15.184h28.804V9.05A8.242 8.242 0 0027.07.808z"
        fill="#785252"
      />
      <path d="M16.464 28.444h8.892V43.83h-8.892V28.444z" fill="#FFCDBE" />
      <path d="M25.356 28.444h-8.892v10.522a9.124 9.124 0 003.912.877h1.066c1.4 0 2.727-.316 3.914-.877V28.444z" fill="#EBAE9D" />
      <path d="M4.91 24.26a3.117 3.117 0 003.116 3.117h25.769a3.117 3.117 0 000-6.234H8.025A3.117 3.117 0 004.91 24.26z" fill="#FFCDBE" />
      <path
        d="M30.608 14.237V27.46a9.165 9.165 0 01-9.165 9.164h-1.066a9.163 9.163 0 01-9.164-9.163V12.447a2.526 2.526 0 012.527-2.526h1.58c.545 0 1.08.14 1.562.397A23.573 23.573 0 0029.32 13.03a1.21 1.21 0 011.288 1.206z"
        fill="#FFCDBE"
      />
      <path
        d="M21.1 31.7h-.38a4.377 4.377 0 01-3.055-1.244 1.172 1.172 0 111.63-1.684c.389.377.895.584 1.426.584h.378c.53 0 1.037-.207 1.427-.584a1.171 1.171 0 111.63 1.684 4.375 4.375 0 01-3.057 1.244z"
        fill="#E09380"
      />
      <path
        d="M41.82 48.735v21.03c0 .43-.35.78-.78.78H7.415L4.918 60.168 0 59.177V48.735a5.943 5.943 0 015.943-5.943h4.91l5.066 1.875 4.985-1.875 4.925 2.5 5.117-2.5h4.931a5.943 5.943 0 015.944 5.943z"
        fill="#FDA87D"
      />
      <path
        d="M35.905 42.792a1.485 1.485 0 00-1.501 1.48v26.274h6.635c.432 0 .782-.35.782-.781v-21.03a5.943 5.943 0 00-5.916-5.943z"
        fill="#FD7A37"
      />
      <path d="M41.902 8.467v26.296c0 .444.36.805.804.805h34.075c.445 0 .805-.36.805-.805V8.467H41.902z" fill="#DDEBFD" />
      <path
        d="M76.781 31.541H42.706a.805.805 0 01-.804-.804v4.026c0 .444.36.805.804.805h34.075c.445 0 .805-.36.805-.805v-4.026c0 .444-.36.805-.805.805z"
        fill="#BED9FD"
      />
      <path
        d="M39.488 7.662V5.416c0-.444.36-.805.804-.805h38.903c.445 0 .805.36.805.805v2.246c0 .445-.36.805-.805.805H40.293a.805.805 0 01-.805-.805z"
        fill="#80B6FC"
      />
      <path
        d="M23.61 53.865L7.417 63.214V44.265c0-.813-.66-1.473-1.474-1.473A5.944 5.944 0 000 48.73v18.667a4.795 4.795 0 004.795 4.795h.62c.842 0 1.668-.222 2.397-.643l19.506-11.261-3.708-6.423z"
        fill="#FD7A37"
      />
      <path
        d="M25.902 58.869L6.396 70.13A4.794 4.794 0 014 70.773h-.62a4.77 4.77 0 01-2.61-.774 4.79 4.79 0 004.026 2.193h.62c.842 0 1.668-.222 2.397-.643l19.506-11.261-.969-1.678-.447.259z"
        fill="#DF6937"
      />
      <path
        d="M31.777 55.127a1.172 1.172 0 01-.935-1.877l17.646-23.427a1.172 1.172 0 111.872 1.41L32.713 54.66a1.17 1.17 0 01-.936.467z"
        fill="#407194"
      />
      <path d="M23.61 53.865l5.41-3.123a3.708 3.708 0 013.707 6.423l-5.409 3.123-3.708-6.423z" fill="#FFCDBE" />
      <path
        d="M34.085 52.099a3.69 3.69 0 00-1.395-1.378 3.708 3.708 0 01-1.38 5.025l-4.96 2.864.968 1.678 5.41-3.123a3.708 3.708 0 001.357-5.066z"
        fill="#EBAE9D"
      />
      <path
        d="M20.91 42.792l2.965 4.979a.805.805 0 001.223.193l5.883-5.172h-10.07zM20.91 42.792l-2.964 4.979a.805.805 0 01-1.223.193l-5.884-5.172H20.91z"
        fill="#4980AC"
      />
    </svg>
  );
};

export const Parents = (props: React.SVGAttributes<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 81" fill="none" {...props}>
      <path
        d="M51.72 39.102h-7.05a1.946 1.946 0 01-1.942-1.94l-.97-16.444s-1.57-13.62 6.574-15.41c0 0 3.31-6.443 13.915-4.379.145.028.285.08.425.124a16.19 16.19 0 019.692 8.26c2.556 5.159 1.721 8.987.699 27.849 0 1.067-.873 1.94-1.94 1.94h-7.501"
        fill="#7F7369"
      />
      <path
        d="M52.946 39.102v-2.307c-4.356-1.925-7.116-6.073-7.311-10.656-.015-.338-.017-7.343-.006-7.473-.073-3.875.527-12.003 6.69-13.358 0 0 2.029-3.947 7.996-4.673-5.363-.589-9.197 1.16-11.314 3.715-.47.567-.658.942-.67.958-5.548 1.22-6.857 8.067-6.68 13.722 0 .023 1.076 18.114 1.078 18.132 0 1.067.873 1.94 1.94 1.94h8.277z"
        fill="#72655A"
      />
      <path
        d="M35.666 75.073l2.266-20.254c.555-3.848.231-6.153 2.067-8.38a8.344 8.344 0 00-1.89-1.641l-.057-.015c-1.964-1.176-3.618-1.225-7.267-2.23l-3.732-1.785V36.31a12.225 12.225 0 01-9.731.025l-.016 4.45-3.716 1.769c-3.626.997-5.3 1.05-7.267 2.23l-.058.014c-.76.463-1.667 1.248-2.282 2.167-1.316 1.966-1.144 3.112-2.446 14.751C.01 75.577-.005 75.004.01 75.945v.006C-.013 77.763 1.1 80.5 4.477 80.5c.255 0 35.305 0 35.522-.003-2.587-.043-4.64-1.887-4.333-5.424z"
        fill="#DD9A5E"
      />
      <path
        d="M4.065 75.073L5.56 61.716C6.893 48.29 6.63 47.248 8.006 45.195a6.933 6.933 0 011.54-1.635c-.279.089-1.69.305-3.223 1.223l-.058.015C3.979 46.193 3.085 48.11 2.78 50.6L.041 75.073c-.034.402-.03.649-.03.877-.025 1.813 1.087 4.55 4.466 4.55H8.5c-3.048 0-4.708-2.271-4.435-5.427zM21.33 44.592v-7.29a11.908 11.908 0 01-4.008-.967l-.017 4.45 4.025 3.807z"
        fill="#D38D54"
      />
      <path
        d="M77.218 50.601c-.29-2.376-1.127-4.364-3.484-5.803l-.058-.015c-2.068-1.238-3.571-1.188-10.548-3.046-.267-.12-.45-.517-.45-.97v-3.952a12.229 12.229 0 01-9.732-.02v3.972c0 .453-.185.85-.45.97-6.932 1.846-8.473 1.803-10.549 3.046l-.058.015c-2.375 1.45-3.198 3.451-3.484 5.803-2.87 25.872-2.786 24.39-2.77 25.344-.041 2.41 1.589 4.555 4.466 4.555h35.42c2.941 0 4.527-2.24 4.467-4.555.016-.952.089.427-2.77-25.344z"
        fill="#EAB883"
      />
      <path
        d="M39.659 75.945c-.016-.928-.07.277 2.769-26.792.292-2.4 1.11-4.297 3.416-5.76-.677.205-2.22.386-3.897 1.39l-.058.015c-2.375 1.45-3.198 3.452-3.485 5.803-2.87 26.04-2.769 24.087-2.769 25.35-.024 1.812 1.087 4.549 4.466 4.549h4.023c-2.918 0-4.49-2.174-4.465-4.555z"
        fill="#D8A373"
      />
      <path
        d="M78.462 61.713c-1.292-11.464-1.236-11.33-1.441-12.323-.415-2.008-1.632-3.582-3.287-4.592l-.058-.015c-2.068-1.238-3.571-1.188-10.548-3.046-.267-.12-.45-.517-.45-.97v-3.972a12.119 12.119 0 01-9.732 0v3.972c0 .453-.185.85-.45.97-6.932 1.846-8.473 1.803-10.549 3.046a5.199 5.199 0 00-.058.015c-2.242 1.369-3.177 3.286-3.484 5.803L37.16 61.713l7.838.822a.789.789 0 01.706.825l-.86 17.14h25.981l-.902-17.074a.857.857 0 01.766-.898l7.772-.815z"
        fill="#D83D3D"
      />
      <path
        d="M37.16 61.713l3.898.409c1.385-12.84 1.3-12.894 1.567-14.18.405-1.96 1.55-3.49 3.219-4.55-.498.162-2.22.387-3.897 1.391a5.973 5.973 0 00-.058.015c-1.646 1.005-2.87 2.57-3.287 4.592-.253 1.22-.205 1.486-1.441 12.323z"
        fill="#A82626"
      />
      <path
        d="M38.08 44.798a31.663 31.663 0 00-.057-.015c-1.969-1.179-3.649-1.234-7.242-2.223l.004-.007-3.732-1.786-.027.041v-.015l-4.868 4.583-4.853-4.59-3.716 1.767c-3.626.998-5.299 1.051-7.266 2.23l-.058.014c-2.293 1.4-3.18 3.3-3.485 5.804L1.536 61.716l7.689.806a.918.918 0 01.82.959l-.854 17.018h25.982l-.903-17.071a.86.86 0 01.769-.9l2.055-.216.838-7.493c.561-3.952.241-6.145 2.053-8.363-.4-.49-1.468-1.392-1.904-1.658z"
        fill="#FFC400"
      />
      <path
        d="M5.56 61.716c1.378-13.869 1.035-14.841 2.824-17.03.32-.393.697-.765 1.163-1.126-.366.104-1.74.334-3.224 1.223l-.058.015c-1.025.625-1.953 1.538-2.558 2.622-1.148 2.053-.799 3.335-2.17 14.296l3.976.417.047-.417z"
        fill="#D8A602"
      />
      <path
        d="M66.409 42.554l-3.281-.817c-.267-.12-.45-.518-.45-.97v-4.455a12.12 12.12 0 01-9.732 0V40.767c0 .452-.185.85-.45.97l-3.331.83c4.002 6.731 13.244 6.734 17.244-.013z"
        fill="#EAB883"
      />
      <path
        d="M6.905 19.969c.004-4.442-.01-4.263.015-4.852C7.428 3.139 20.993-3.627 30.88 3.26c6.648 4.641 6.526 10.387 6.532 16.709"
        fill="#4C4846"
      />
      <path
        d="M6.905 19.969c.004-4.442-.01-4.263.015-4.852C7.428 3.139 20.993-3.627 30.88 3.26c6.648 4.641 6.526 10.387 6.532 16.709"
        fill="#4C4846"
      />
      <path
        d="M23.679.597c-8.76-.87-16.459 5.81-16.764 14.646-.018.55-.006.423-.01 4.726h3.042v-4.195c0-7.841 5.955-14.404 13.732-15.177z"
        fill="#3F3B39"
      />
      <path
        d="M38.17 21.333c0 1.758-1.702 3.182-3.8 3.182V18.15c2.098 0 3.8 1.425 3.8 3.183zM6.146 21.333c0 1.758 1.702 3.182 3.8 3.182V18.15c-2.098 0-3.8 1.425-3.8 3.183z"
        fill="#DD9A5E"
      />
      <path
        d="M34.37 21.655v2.86c2.852 0 4.654-2.52 3.394-4.613-.625 1.04-1.91 1.753-3.395 1.753zM6.552 19.902c-1.261 2.095.548 4.613 3.395 4.613v-2.86c-1.485 0-2.77-.713-3.395-1.753z"
        fill="#D38D54"
      />
      <path
        d="M33.227 17.49c-1.104-.956-1.618-2.295-1.671-3.762-.045-1.223-1.016-1.892-2.105-1.723-.881.135-1.792.25-2.681.336a47.138 47.138 0 01-11.992-.35c-.961-.15-1.973.508-2.017 1.738-.036.969-.27 1.9-.776 2.713-.756 1.218-1.716 1.578-2.038 1.764 0 7.666-.154 8.705.518 10.931 2.156 7.15 10.194 10.662 16.875 7.527a12.208 12.208 0 006.99-10.084c.055-.7.04-.404.04-8.374-.058-.03-.543-.196-1.143-.715z"
        fill="#EDAE6B"
      />
      <path
        d="M14.759 31.48c-.122-.331-.28-.821-.412-1.338-.628-2.454-.438-3.878-.441-10.472 0-1.187.798-1.26 1.6-2.25.825-1.017 1.187-2.307 1.217-3.638a1.884 1.884 0 01.717-1.451 49.884 49.884 0 01-2.662-.34c-1.034-.16-1.987.616-2.014 1.663-.033 1.282-.416 2.494-1.272 3.442-.71.787-1.391 1.02-1.546 1.11 0 7.601-.323 9.612 1.264 12.814.897 1.81 1.942 2.807 2.017 2.912 1.706 1.82 3.873 3.019 5.929 3.51-.226-.169-3.393-3.231-4.397-5.962z"
        fill="#DD9A5E"
      />
      <path d="M23.365 54.147v-9.908l-1.207 1.137-1.207-1.142v9.912a1.207 1.207 0 102.414 0z" fill="#D8A602" />
      <path d="M27.053 40.767l-4.895 4.609 2.642 2.663a1.19 1.19 0 001.782-.106l4.203-5.38-3.732-1.786z" fill="#DADADA" />
      <path d="M28.932 41.667l-1.88-.9-4.894 4.609 1.749 1.762 5.025-5.471zM25.16 48.286c.221.098.465.126.698.084l-.698-.084z" fill="#CECCCA" />
      <path d="M17.305 40.786l4.853 4.59-2.642 2.663a1.19 1.19 0 01-1.782-.106l-4.203-5.38 3.774-1.767z" fill="#DADADA" />
      <path d="M18.737 45.358l1.673 1.78 1.748-1.762-4.852-4.59-1.99.932 3.421 3.64z" fill="#CECCCA" />
      <path
        d="M56.99 43.226v-5.925a12.041 12.041 0 01-4.044-.99c-.123 3.52.319 5.077-.45 5.426l-3.331.829a10.57 10.57 0 003.271 3.44l3.458-1.27s1.095-.544 1.095-1.51z"
        fill="#D8A373"
      />
      <path
        d="M68.818 21.61a1.288 1.288 0 011.228 1.286v.923c-.003 1.702.551 7.051-4.405 11.176-5.659 4.71-14.28 3.362-18.198-2.973-2.393-3.867-1.811-7.263-1.82-12.612v-.618c0-.719.507-1.333 1.21-1.482 1.247-.264 3.262-.98 5.09-2.926a1.113 1.113 0 011.67.054c1.774 2.15 6.564 6.767 15.225 7.172z"
        fill="#F7CF9C"
      />
      <path
        d="M49.657 26.086c-.014-.376-.01.481-.01-9.878a9.48 9.48 0 01-2.814 1.102 1.517 1.517 0 00-1.21 1.482v.618c.01 5.827-.493 8.874 1.82 12.612a12.21 12.21 0 0012.404 5.626c-5.592-.927-9.956-5.677-10.19-11.562z"
        fill="#EAB883"
      />
      <path d="M68.763 43.14l-2.354-.586c-1.803 3.04-4.991 5.063-8.626 5.063 0 0 11.029 11.03 10.98-4.477z" fill="#B72C2C" />
      <path d="M46.855 43.14l2.354-.586c1.803 3.04 4.991 5.063 8.626 5.063 0 0-11.029 11.03-10.98-4.477z" fill="#B72C2C" />
      <path d="M52.589 46.073c-1.946 1.036-4.189 1.53-5.625-.391 1.143 11.664 10.87 1.935 10.87 1.935a9.696 9.696 0 01-5.245-1.544z" fill="#A82626" />
      <path d="M63.03 46.073c1.945 1.036 4.187 1.53 5.623-.391-1.142 11.664-10.87 1.935-10.87 1.935 1.92 0 3.714-.564 5.246-1.544z" fill="#A82626" />
    </svg>
  );
};
