import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useData } from 'src/context/Provider';
import Unauthorized from 'src/pages/Unauthorized';
import Layout from './Layout';

const PrivateRoute = () => {
  const {
    state: { user, userLoading }
  }: any = useData();
  const token = localStorage.getItem('token');
  const location = useLocation();
  const { pathname } = location;

  const isInstructor = user?.role === 'Instructor';
  const instructorAccess = ['dashboard', 'calendar', 'profile', 'schedule'];

  let route = pathname.split('/').filter((x) => x)[0];

  return user || token ? (
    isInstructor && !instructorAccess.includes(route) ? (
      <Unauthorized />
    ) : userLoading ? (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          padding: '1rem'
        }}
      >
        <p>Checking Credentials...</p>
      </div>
    ) : (
      <Layout>
        <Outlet />
      </Layout>
    )
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
