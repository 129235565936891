export const COURSE_TYPES = {
  individual: 'Individual',
  group: 'Group',
  batch: 'Batch'
};

export const COURSE_TYPES_ARRAY_SITE1 = [
  { value: 'Individual', label: 'Individual' },
  { value: 'Group', label: 'Group' },
  { value: 'Batch', label: 'Batch' }
];

export const COURSE_TYPES_ARRAY_SITE2 = [
  { value: 'Individual', label: 'Private' },
  { value: 'Group', label: 'Session' },
  { value: 'Batch', label: 'Semester' }
];
