import React from 'react';
import { Autocomplete, Box, TextField, Typography, CircularProgress, Button, Checkbox, FormControlLabel, useTheme } from '@mui/material';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import { Calendar as MyCalendar, momentLocalizer, View } from 'react-big-calendar';
import moment from 'moment';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import CustomBreadcrumbs from 'src/components/Helpers/CustomBreadcrumbs';
import { useData } from 'src/context/Provider';
import BookingInfoDialog from './BookingInfoDialog';
import { COURSE_TYPES } from 'src/constants/helpers';
import ManageEventDialog from './ManageEventDialog';
import routes from 'src/components/Helpers/Routes';

const Calendar = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const { setToastConfig } = React.useContext(CustomToastContext);
  const {
    state: { user, brand }
  } = useData();

  const localizer = momentLocalizer(moment);

  const [instructorList, setInstructorList] = React.useState([]);
  const [instructor, setInstructor] = React.useState(null);

  const [events, setEvents] = React.useState([]);
  const [openBookingInfo, setOpenBookingInfo] = React.useState({ open: false, data: null, type: null });
  const [loadingOptions, setLoadingOptions] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [view, setView] = React.useState<View>('week');
  const [birthdayEvents, setBirthdayEvents] = React.useState([]);
  const [showEvent, setShowEvent] = React.useState(false);
  const [eventsData, setEventsData] = React.useState([]);

  React.useEffect(() => {
    if (user) {
      if (user?.role === 'Admin') {
        fetchOptions();
      } else {
        fetchReport();
        setInstructor(user?._id);
      }
    }
    fetchBirthdays();
  }, [user]);

  React.useEffect(() => {
    if (user && instructor) {
      fetchReport();
    }
  }, [instructor]);

  React.useEffect(() => {
    fetchEvents();
  }, [showEvent]);

  const fetchOptions = async () => {
    setLoadingOptions(true);
    try {
      let {
        data: { data: instructors }
      } = await axiosInstance().get('/users?role=Instructor');
      instructors = instructors.map((user: any) => ({
        ...user,
        fullName: `${user.firstName} ${user.lastName}`
      }));
      setInstructorList(instructors);
      setLoadingOptions(false);
    } catch (error) {
      setLoadingOptions(false);
      setToastConfig(error);
    }
  };

  const fetchReport = async () => {
    if (instructor) {
      setLoading(true);
      let query = `instructor=${instructor}`;
      try {
        const {
          data: { data: scheduleData }
        } = await axiosInstance().get(`/report/instructor/calender?${query}`);
        let newEvents: any[] = [];
        scheduleData?.schedule.forEach((d: any) => {
          const startDate = moment(d.startDate);
          const endDate = moment(d.endDate);
          const daysDiff = endDate.diff(startDate, 'days');
          if (daysDiff === 0) {
            const newStartDate = new Date(d.startDate);
            const newEndDate = new Date(d.startDate);
            const newStartTime = new Date(d.startTime);
            const newEndTime = new Date(d.endTime);

            newStartDate.setHours(newStartTime.getHours());
            newStartDate.setMinutes(newStartTime.getMinutes());
            newStartDate.setSeconds(0);

            newEndDate.setHours(newEndTime.getHours());
            newEndDate.setMinutes(newEndTime.getMinutes());
            newEndDate.setSeconds(0);

            let event: any = {
              ...d,
              title: d.courseName,
              start: newStartDate,
              end: newEndDate
            };
            newEvents.push(event);
          } else {
            for (let i = 0; i <= daysDiff + 1; i++) {
              const newDate: any = moment(d.startDate).add(i, 'days');
              if (d?.blockDayNames?.includes(moment(newDate).format('dddd'))) {
              } else {
                const newStartDate = new Date(newDate);
                const newEndDate = new Date(newDate);
                const newStartTime = new Date(d.startTime);
                const newEndTime = new Date(d.endTime);

                newStartDate.setHours(newStartTime.getHours());
                newStartDate.setMinutes(newStartTime.getMinutes());
                newStartDate.setSeconds(0);

                newEndDate.setHours(newEndTime.getHours());
                newEndDate.setMinutes(newEndTime.getMinutes());
                newEndDate.setSeconds(0);

                let event: any = {
                  ...d,
                  title: d.courseName,
                  start: newStartDate,
                  end: newEndDate
                };
                newEvents.push(event);
              }
            }
          }
        });
        scheduleData?.orders?.forEach((d: any) => {
          newEvents = newEvents.map((e) => {
            if (
              e.scheduleId === d.scheduleId &&
              (e.type === COURSE_TYPES.batch || moment(e?.start).format('MM-DD-YYYY') === moment(d?.startDate).format('MM-DD-YYYY'))
            ) {
              return { ...e, orderStartDate: d?.startDate, orderEndDate: d?.endDate, isBooked: true };
            }
            return e;
          });
        });
        scheduleData?.blockSchedule?.forEach((d: any) => {
          newEvents = newEvents.map((e) => {
            if (e.scheduleId === d.scheduleId && moment(e?.start).format('MM-DD-YYYY') === moment(d?.startDate).format('MM-DD-YYYY')) {
              return { ...e, isBlocked: true, blockId: d._id, title: `Blocked by Teacher - ${e.title}` };
            }
            return e;
          });
        });
        newEvents = newEvents.map((d: any, index: number) => ({ ...d, id: index }));
        setEvents(newEvents);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setToastConfig(error);
      }
    }
  };

  const onEventClick = (event: any) => {
    if (event.isBooked) {
      setOpenBookingInfo({ open: true, data: event, type: event.type });
      return;
    } else if (event.type === 'Event') {
      setOpenBookingInfo({ open: true, data: event, type: event.type });
      return;
    }
    if (user?.role === 'Instructor') {
      if (event.isBlocked) {
        axiosInstance()
          .put(`/block-schedule/remove`, {
            ids: [event.blockId]
          })
          .then(() => {
            setToastConfig({
              open: true,
              message: 'Slot Unblocked',
              type: 'success'
            });
            fetchReport();
          })
          .catch((err) => {
            setToastConfig(err);
          });
      } else {
        axiosInstance()
          .put(`/block-schedule`, {
            schedule: [
              {
                course: event._id,
                scheduleId: event.scheduleId,
                startDate: event.start,
                endDate: event.start,
                startTime: event.start,
                endTime: event.end
              }
            ]
          })
          .then(() => {
            setToastConfig({
              open: true,
              message: 'Slot Blocked',
              type: 'success'
            });
            fetchReport();
          })
          .catch((err) => {
            setToastConfig(err);
          });
      }
    }
  };

  const fetchEvents = async () => {
    setLoadingOptions(true);
    try {
      const {
        data: { data }
      } = await axiosInstance().get(`${routes.report.api}/events/calender`);
      let newEvents: any[] = [];
      data?.forEach((d: any) => {
        const newDate: any = moment(d.startDate);
        const newStartDate = new Date(newDate);
        const newEndDate = new Date(newDate);
        const newStartTime = new Date(d.startTime);
        const newEndTime = new Date(d.endTime);

        newStartDate.setHours(newStartTime.getHours());
        newStartDate.setMinutes(newStartTime.getMinutes());
        newStartDate.setSeconds(0);

        newEndDate.setHours(newEndTime.getHours());
        newEndDate.setMinutes(newEndTime.getMinutes());
        newEndDate.setSeconds(0);

        let event: any = {
          ...d,
          title: d.label || '',
          start: newStartDate,
          end: newEndDate,
          type: 'Event'
        };
        newEvents.push(event);
      });
      setEventsData(newEvents);
    } catch (error) {
      setLoading(false);
      setToastConfig(error);
    }
  };

  const fetchBirthdays = async () => {
    setLoadingOptions(true);
    try {
      const {
        data: { data }
      } = await axiosInstance().get('/report/birthday/calender/');
      let newEvents: any[] = [];
      data?.forEach((d: any) => {
        const newDate: any = moment(d.startDate);
        const newStartDate = new Date(newDate);
        const newEndDate = new Date(newDate);

        newStartDate.setHours(10);
        newStartDate.setMinutes(0);
        newStartDate.setSeconds(0);

        newEndDate.setHours(12);
        newEndDate.setMinutes(0);
        newEndDate.setSeconds(0);

        let event: any = {
          ...d,
          title: `\n ${d.courseName}, \n ${moment(d.startTime).format('Do MMMM YYYY')}`,
          start: newStartDate,
          end: newEndDate
        };
        newEvents.push(event);
      });

      setLoadingOptions(false);
      setBirthdayEvents(newEvents);
    } catch (error) {
      setLoadingOptions(false);
      // setToastConfig(error);
    }
  };
  const formats = {
    eventTimeRangeFormat: (data: any) => {
      return '';
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ p: 1 }}>
        <CustomBreadcrumbs routes={[{ title: 'Calendar', path: '/calendar' }]} />

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBlock: '12px', gap: '10px' }} flexWrap="wrap">
          <Box>
            <CalendarIcon fontSize="small" color="primary" />
            <Box mr={1} display="inline-block" />
            <Typography variant="h6" color="GrayText" display="inline-block">
              Calendar
            </Typography>
          </Box>
          <Box display="flex" flexWrap="wrap" gap="30px">
            {['Available', 'Booked', 'Blocked', 'Birthday', 'Event'].map((item) => (
              <>
                <Box
                  style={{
                    color: mode === 'light' ? 'black' : 'white'
                  }}
                >
                  <span
                    style={{
                      backgroundColor:
                        (item === 'Booked' && '#90EE90') ||
                        (item === 'Blocked' && '#D3D3D3') ||
                        (item === 'Available' && 'white') ||
                        (item === 'Birthday' && '#678983') ||
                        (item === 'Event' && '#7EC2FD'),
                      width: '26px',
                      height: '26px',
                      display: 'inline-block',
                      border: mode === 'light' ? '1px solid #7EC2FD' : '1px solid gray',
                      borderRadius: '4px',
                      verticalAlign: 'middle',
                      marginInlineEnd: '8px'
                    }}
                  ></span>
                  {item}
                </Box>
              </>
            ))}
          </Box>
        </Box>

        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: '4px' }}>
            {user?.role === 'Admin' && (
              <>
                <Autocomplete
                  loading={loadingOptions}
                  options={instructorList}
                  onChange={(_, newVal: any) => {
                    if (!newVal) return;
                    setInstructor(newVal._id);
                  }}
                  getOptionLabel={(option) => option.fullName}
                  isOptionEqualToValue={(option, value) => option._id === value._id}
                  sx={{ width: 250 }}
                  renderInput={(params) => <TextField {...params} label={'Instructors'} size="small" />}
                />
                <Box mr={1} />
                <FormControlLabel
                  label="Show Events"
                  onChange={() => {
                    setShowEvent(!showEvent);
                  }}
                  control={<Checkbox checked={showEvent} />}
                />

                <Box mr={1} />
                {loading && <CircularProgress size={20} />}
              </>
            )}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: '4px' }}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => {
                setOpenBookingInfo({ open: true, data: null, type: 'Event' });
              }}
            >
              Add Event
            </Button>
          </Box>
        </div>
        <Box sx={{ position: 'relative', height: user?.role === 'Instructor' ? '78vh' : 'calc(75vh - 20px)', mt: 1 }}>
          <MyCalendar
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            events={[...events, ...birthdayEvents, ...(showEvent ? eventsData : [])]}
            popup={true}
            view={view}
            formats={formats}
            onView={(newView) => setView(newView)}
            views={['month', 'week', 'day']}
            eventPropGetter={(data) => {
              return {
                style: {
                  backgroundColor: data.isBooked
                    ? '#90EE90'
                    : data.isBlocked
                      ? '#D3D3D3'
                      : data.type === 'birthday'
                        ? '#678983'
                        : data.type === 'Event'
                          ? '#7EC2FD'
                          : 'white',
                  color: data.isBooked || data.isBlocked ? 'black' : data.type === 'birthday' ? '#fdfdfd' : 'black'
                }
              };
            }}
            onSelectEvent={onEventClick}
          />
        </Box>
      </Box>
      {openBookingInfo.open && openBookingInfo.type !== 'Event' && (
        <BookingInfoDialog
          instructorId={instructor}
          scheduleId={openBookingInfo.data.scheduleId}
          date={openBookingInfo.data.orderStartDate}
          bookingInfo={openBookingInfo.data}
          fetchData={fetchReport}
          selectedDate={openBookingInfo.data.start}
          onClose={() => setOpenBookingInfo({ open: false, data: null, type: null })}
        />
      )}
      {openBookingInfo.open && openBookingInfo.type === 'Event' && (
        <ManageEventDialog
          eventId={openBookingInfo?.data?._id}
          onClose={() => setOpenBookingInfo({ open: false, data: null, type: null })}
          handleSucess={() => {
            fetchEvents();
            setOpenBookingInfo({ open: false, data: null, type: null });
          }}
        />
      )}
    </React.Fragment>
  );
};

export default Calendar;
