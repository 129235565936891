import React, { Fragment, useEffect, useState } from 'react';
import { Box, Button, Grid, Paper, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import PolicyDialog from './PolicyDialog';
import { axiosInstance } from 'src/axios';
import CustomBreadcrumbs from 'src/components/Helpers/CustomBreadcrumbs';
import routes from 'src/components/Helpers/Routes';
import { CustomToastContext } from 'src/context/CustomToastContext';
import PolicyIcon from '@mui/icons-material/Policy';
import { siteName } from 'src/config';

const Policy = () => {
  const [open, setOpen] = useState(false);
  const [policyData, setPolicyData] = useState(null);
  const toastConfig = React.useContext(CustomToastContext);

  useEffect(() => {
    fetchPolicy();
  }, []);

  const fetchPolicy = () => {
    axiosInstance()
      .get(routes.policy.api)
      .then(({ data: { data } }) => {
        setPolicyData(data);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  return (
    <Fragment>
      <Box sx={{ p: 1 }}>
        <CustomBreadcrumbs routes={[routes.policy]} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 42,
            pl: 1,
            pr: 1
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PolicyIcon fontSize="small" color="primary" />
            <Box mr={1} />
            <Typography variant="h6" color="GrayText">
              Policy
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              id="edit-button"
              aria-controls={'edit-button'}
              onClick={() => {
                setOpen(true);
              }}
              size="small"
              variant="contained"
            >
              Edit Policy
            </Button>
          </Box>
        </Box>
        <Box pt={1}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <TableContainer component={Paper}>
                <TableHead>
                  <TableRow>
                    <TableCell>Online</TableCell>
                    <TableCell component="td" scope="row">
                      {policyData?.isOnline === true ? 'Active' : 'Inactive'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Offline</TableCell>
                    <TableCell component="td" scope="row">
                      {policyData?.isOffline === true ? 'Active' : 'Inactive'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Payment Gateway</TableCell>
                    <TableCell component="td" scope="row">
                      {policyData?.paymentGateway}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>API Key</TableCell>
                    <TableCell component="td" scope="row">
                      {policyData?.apiKey}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Processing Fee(Percentage)</TableCell>
                    <TableCell component="td" scope="row">
                      {policyData?.paymentGatewayCharge}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Currency</TableCell>
                    <TableCell component="td" scope="row">
                      {policyData?.currency}
                    </TableCell>
                  </TableRow>
                  {/* <TableRow >
                    <TableCell>Extra Charge</TableCell>
                    <TableCell component="td" scope="row">
                      {policyData?.extraCharge}
                    </TableCell>
                  </TableRow> */}
                  <TableRow>
                    <TableCell>Time Zone</TableCell>
                    <TableCell component="td" scope="row">
                      {policyData?.timeZone}
                    </TableCell>
                  </TableRow>
                  {!siteName.includes('Site1') && (
                    <>
                      <TableRow>
                        <TableCell>Subheader Title</TableCell>
                        <TableCell component="td" scope="row">
                          {policyData?.subheaderTitle}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Subheader Button Label</TableCell>
                        <TableCell component="td" scope="row">
                          {policyData?.subheaderButtonLabel}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Subheader Button Link</TableCell>
                        <TableCell component="td" scope="row">
                          {policyData?.subheaderButtonLink}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableHead>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {open && (
        <PolicyDialog
          onClose={() => {
            setOpen(false);
          }}
          handleSuccess={() => {
            setOpen(false);
            fetchPolicy();
          }}
        />
      )}
    </Fragment>
  );
};

export default Policy;
