import React from 'react';
import { Button, Dialog, Box, CircularProgress } from '@mui/material';
import { CustomDialogHeader, CustomDialogContent, CustomDialogFooter } from 'src/components/CustomDialog';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import RescheduleDialog from './RescheduleDialog';
import { COURSE_TYPES } from 'src/constants/helpers';
import AttendanceReasonDialog from './AttendanceReasonDialog';

const BookingInfoDialog = ({
  onClose,
  instructorId,
  scheduleId,
  date,
  bookingInfo,
  selectedDate,
  fetchData
}: {
  onClose: () => void;
  scheduleId: string;
  instructorId: string;
  date: string;
  bookingInfo: any;
  selectedDate: any;
  fetchData: () => void;
}) => {
  const { setToastConfig } = React.useContext(CustomToastContext);
  const [bookingData, setBookingData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [rescheduleDialog, setRescheduleDialog] = React.useState(false);
  const [settingAttendance, setSettingAttendance] = React.useState(false);
  const [order, setOrder] = React.useState(null);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [attendanceReason, setAttendanceReason] = React.useState(false);
  const [status, setStatus] = React.useState('present');
  const [absentReason, setAbsentReason] = React.useState('');

  React.useEffect(() => {
    fetchOrder();
  }, []);

  const fetchOrder = () => {
    setLoading(true);
    axiosInstance()
      .get(`report/instructor/order?date=${date}&instructor=${instructorId}&scheduleId=${scheduleId}&selectedDate=${selectedDate}`)
      .then(({ data: { data } }) => {
        setOrder(data[0]);
        const rows: any = [];

        data?.forEach((obj: any, index: number) => {
          rows.push({
            ...obj,
            index: index + 1,
            orderId: obj._id,
            courseId: obj.course._id,
            id: obj.studentId,
            studentName: `${obj.firstName} ${obj.lastName}`,
            user: obj?.user?.optionLabel,
            attended: Boolean(obj?.attendance?.find((d: any) => d.studentId === obj.studentId)) ? 'Yes' : 'No'
          });
        });

        setBookingData(rows);
        setLoading(false);
      })
      .catch((err) => {
        setToastConfig(err);
        setLoading(false);
      });
  };

  const columns: GridColDef[] = [
    {
      field: 'index',
      headerName: '#',
      width: 50
    },
    {
      field: 'studentName',
      headerName: 'Student Name',
      width: 200
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200
    },
    {
      field: 'phone',
      headerName: 'Phone',
      width: 200
    },
    {
      field: 'attended',
      headerName: 'Attended',
      width: 200
    },
    {
      field: 'orderNumber',
      headerName: 'Order Number',
      width: 200
    },
    {
      field: 'user',
      headerName: 'Booked By',
      width: 200
    },
    {
      field: 'paymentType',
      headerName: 'Payment Type',
      width: 200
    },
    {
      field: 'paymentStatus',
      headerName: 'Payment Status',
      width: 200
    }
  ];

  const handleAttendance = (status: any, absentReason: any) => {
    const studentsData = bookingData.filter((d) => selectedRows.includes(d.studentId));
    const postData = studentsData.map((studentData) => ({
      scheduleId,
      status: status,
      absentReason: absentReason,
      instructor: instructorId,
      studentId: studentData.studentId,
      startDate: bookingInfo.orderStartDate,
      startTime: bookingInfo.startTime,
      endDate: bookingInfo.orderEndDate,
      endTime: bookingInfo.endTime,
      course: studentData.courseId,
      order: studentData.orderId,
      date: selectedDate
    }));
    setSettingAttendance(true);
    axiosInstance().post('/student-attendance', postData).then(() => {
      setToastConfig({
        open: true,
        message: 'Attendance create succesfully',
        type: 'success'
      });
      setAttendanceReason(false);
      onClose();
      setSettingAttendance(false);
      fetchOrder();
    }).catch((err) => {
      setSettingAttendance(false);
      setToastConfig(err);
    });
  };

  return (
    <>
      <Dialog open onClose={onClose} fullScreen maxWidth="md" fullWidth>
        <CustomDialogHeader showRequiredLabel={false} onClose={onClose} title="Booking Info" />
        <CustomDialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
            <Button
              size="small"
              variant="contained"
              disabled={
                settingAttendance ||
                selectedRows.length === 0 ||
                bookingData[0]?.attendance?.filter((a: any) => selectedRows.includes(a.studentId)).length > 0
              }
              onClick={() => {
                setAttendanceReason(true);
              }}
              endIcon={settingAttendance && <CircularProgress size={20} color="inherit" />}
            >
              Attendance
            </Button>
            <Box mr={1} />
            {bookingInfo?.type === COURSE_TYPES.individual && (
              <Button
                disabled={selectedRows.length === 0 ||
                  bookingData[0]?.attendance?.filter((a: any) => selectedRows.includes(a.studentId)).length > 0
                }
                size="small"
                variant="contained"
                onClick={() => setRescheduleDialog(true)}>
                Re-schedule
              </Button>
            )}
          </Box>
          <DataGrid
            loading={loading}
            sx={{ height: 'calc(100vh - 195px)' }}
            rows={bookingData}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 20, 50, 100]}
            checkboxSelection
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            onSelectionModelChange={(rows) => setSelectedRows(rows)}
          />
        </CustomDialogContent>
        <CustomDialogFooter>
          <Button variant="outlined" size="small" onClick={onClose}>
            Close
          </Button>
        </CustomDialogFooter>
      </Dialog>
      {rescheduleDialog && (
        <RescheduleDialog
          onClose={() => setRescheduleDialog(false)}
          allClose={() => {
            setRescheduleDialog(false);
            onClose();
            fetchData();
          }}
          scheduleId={scheduleId}
          instructorId={instructorId}
          date={date}
          order={order}
        />
      )}
      {attendanceReason && (
        <AttendanceReasonDialog
          open={attendanceReason}
          settingAttendance={settingAttendance}
          status={status}
          setStatus={setStatus}
          absentReason={absentReason}
          setAbsentReason={setAbsentReason}
          onClose={() => setAttendanceReason(false)}
          onConfirm={handleAttendance}
        />
      )}
    </>
  );
};

export default BookingInfoDialog;
