import { useState, useContext } from 'react';
import { Box, Card, TextField, Typography, InputAdornment, IconButton, Link as MuiLink, Button, CircularProgress, useTheme } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { SET_USER } from 'src/context/ActionTypes';
import { useData } from '../../context/Provider';
import { logo } from 'src/config';
import { AiOutlineMail } from 'react-icons/ai';
import { HiOutlineKey } from 'react-icons/hi';
import * as Yup from 'yup';

interface LoginData {
  email: string;
  password: string;
}

const errorSchema = Yup.object().shape({
  password: Yup.string().min(4, 'Too Short!').max(50, 'Too Long!').required('Required'),
  email: Yup.string().email('Invalid email').required('Required')
});

const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const toastConfig = useContext(CustomToastContext);
  const { dispatch }: any = useData();
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (values: LoginData) => {
    setIsSubmitting(true);

    axiosInstance()
      .post('/auth/admin/login', {
        email: values.email,
        password: values.password
      })
      .then(({ data: { data } }) => {
        localStorage.setItem('token', data?.token);
        dispatch({ type: SET_USER, payload: data?.user });
        dispatch({ type: SET_USER, payload: data?.brand });
        navigate('/dashboard', { replace: true });
        // if (location.state?.from?.pathname) {
        //   navigate(location.state?.from?.pathname, { replace: true });
        // } else {
        //   navigate('/dashboard', { replace: true });
        // }
        setIsSubmitting(false);
      })
      .catch((error) => {
        setIsSubmitting(false);
        toastConfig.setToastConfig(error);
      });
  };

  return (
    <Box sx={{ ...customStyles.container }}>
      <Card variant="outlined" sx={{ flexBasis: '487px', maxWidth: '487px' }}>
        <Box sx={{ ...customStyles.loginContainer, backgroundColor: theme.palette.mode === 'light' ? 'white' : theme.palette.background.paper }}>
          <Box
            sx={{
              maxWidth: '250px',
              maxHeight: '46px',
              margin: { xs: '0px auto 15px', sm: '0px auto 18px', md: '0px auto 20px', lg: '0px auto 20px' }
            }}
          >
            <img src={logo} alt="" style={{ maxWidth: '250px', maxHeight: '46px' }} />
          </Box>
          <Typography
            variant="h2"
            sx={{
              ...customStyles.logoText,
              color: theme.palette.mode === 'light' ? '#3A3A3A' : theme.palette.getContrastText(theme.palette.background.paper)
            }}
            mb={2}
          >
            Login
          </Typography>
          <Box>
            <Formik
              initialValues={{
                email: '',
                password: ''
              }}
              validationSchema={errorSchema}
              onSubmit={handleSubmit}
            >
              {({ submitForm, values, errors, touched, setFieldValue }) => (
                <Form>
                  <Box>
                    <Box mb={'22px'}>
                      <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <Typography
                          style={{
                            ...customStyles.lebel,
                            color: theme.palette.mode === 'light' ? '#3A3A3A' : theme.palette.getContrastText('#121212')
                          }}
                        >
                          Email
                        </Typography>
                        <div className="error">{touched.email && errors.email && <div>{errors.email}</div>}</div>
                      </Box>
                      <TextField
                        required
                        fullWidth
                        data-testid="email"
                        variant="outlined"
                        type="email"
                        placeholder="example@gmail.com"
                        name="email"
                        size="small"
                        onChange={(e) => setFieldValue('email', e.target.value)}
                        value={values['email']}
                        error={touched['email'] && Boolean(errors['email'])}
                        sx={{ ...customStyles.fields }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AiOutlineMail color={'#B0B6BF'} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </Box>

                    <Box>
                      <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <Typography
                          style={{
                            ...customStyles.lebel,
                            color: theme.palette.mode === 'light' ? '#3A3A3A' : theme.palette.getContrastText(theme.palette.background.paper)
                          }}
                        >
                          Password
                        </Typography>
                        <div className="error">{touched.password && errors.password && <div>{errors.password}</div>}</div>
                      </Box>
                      <TextField
                        fullWidth
                        data-testid="password"
                        variant="outlined"
                        type={showPassword ? 'text' : 'password'}
                        size="small"
                        placeholder="Enter password"
                        name="password"
                        value={values['password']}
                        error={touched['password'] && Boolean(errors['password'])}
                        onChange={(e) => setFieldValue('password', e.target.value)}
                        sx={{ ...customStyles.fields }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <HiOutlineKey color={'#B0B6BF'} />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton className="p-0" onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Box>

                    <Box mt={1}>
                      <Box textAlign="right">
                        <MuiLink component={Link} to="/forget-password" sx={{ ...customStyles.forgotPassword }}>
                          Forgot Password?
                        </MuiLink>
                      </Box>
                    </Box>
                    <Box className="mt-2">
                      <Button
                        sx={{ ...customStyles.buttons }}
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                        endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                        onClick={submitForm}
                      >
                        Login
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default Login;

const customStyles = {
  container: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 20px'
  },
  loginContainer: {
    maxWidth: '487px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 4px 60px rgba(0, 0, 0, 0.12)',
    borderRadius: '10px',
    padding: { xs: '35px', sm: '45px', md: '45px 50px', lg: '45px 70px' },
    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))'
  },
  logoText: {
    fontWeight: 600,
    fontSize: '28px',
    lineHeight: '42px',

    textAlign: 'center'
  },
  lebel: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
    marginBottom: '5px'
  },
  fields: {
    '> div': {
      height: '48px',
      input: {
        fontSize: '16px !important'
      },
      borderRadius: '8px !important'
    }
  },
  buttons: {
    padding: '10px 16px',
    borderRadius: '10px !important',
    fontSize: '14px',
    marginTop: '20px',
    boxShadow: '0px 4px 30px rgba(22, 51, 64, 0.3)'
  },
  forgotPassword: {
    fontSize: '12px',
    color: '#878787',
    textDecoration: 'none'
  }
};
