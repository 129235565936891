import React, { Fragment, useEffect, useState } from 'react';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { axiosInstance } from 'src/axios';
import ManageCourses from './ManageCourses';
import { useParams } from 'react-router-dom';
import CommonSkeleton from 'src/components/Helpers/CommonSkeleton';
import { isMobile, isTablet } from 'react-device-detect';
import { BiEdit } from 'react-icons/bi';
import routes from 'src/components/Helpers/Routes';
import CoursesIcon from '@mui/icons-material/LibraryBooks';
import Schedule from './Schedule';
import CustomBreadcrumbs from 'src/components/Helpers/CustomBreadcrumbs';
import Offers from './Offers';
import Waitlist from './Waitlist';
import { COURSE_TYPES_ARRAY_SITE1, COURSE_TYPES_ARRAY_SITE2 } from 'src/constants/helpers';
import { siteName } from 'src/config';

const CoursesDetails = () => {
  const { id } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [initialValues, setInitialValues] = useState<any>(null);
  const siteType = siteName === 'Site1' ? COURSE_TYPES_ARRAY_SITE1 : COURSE_TYPES_ARRAY_SITE2;

  useEffect(() => {
    if (id) {
      fetchCourseDetails();
    }
  }, [id]);

  const fetchCourseDetails = async () => {
    await axiosInstance()
      .get(`${routes.courses.api}/${id}`)
      .then(({ data: { data } }) => {
        setInitialValues({
          courseName: data?.courseName,
          type: data?.type,
          details: data?.details,
          instructor: data?.instructor,
          startDate: data?.startDate,
          endDate: data?.endDate,
          fee: data?.fee,
          color: data?.color,
          registrationEndDate: data?.registrationEndDate,
          timeSlot: data.timeSlot || []
        });
      })
      .catch((error) => {});
  };

  return (
    <Box sx={{ p: 1 }}>
      <CustomBreadcrumbs routes={[routes.courses, { title: initialValues?.courseName }]} />
      <Fragment>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: 42,
                pl: 1,
                pr: 1
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CoursesIcon fontSize="small" color="primary" />
                <Box mr={1} />
                <Typography variant="h6" color="GrayText">
                  Course Details
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  variant={isMobile && !isTablet ? 'text' : 'contained'}
                  color="primary"
                  size="small"
                  onClick={() => {
                    setOpenDialog(true);
                  }}
                >
                  {isMobile && !isTablet ? <BiEdit size={20} /> : 'Edit'}
                </Button>
              </Box>
            </Box>
            <Box pt={2}>
              <Paper>
                {!initialValues ? (
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <CommonSkeleton lenArray={[...Array(7).keys()]} />
                  </Grid>
                ) : (
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Typography variant="subtitle1" display="inline">
                        Course Name &nbsp;&nbsp;&nbsp;
                      </Typography>
                      <Typography variant="subtitle2" display="inline">
                        {initialValues.courseName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Typography variant="subtitle1" display="inline">
                        Type &nbsp;&nbsp;&nbsp;
                      </Typography>
                      <Typography variant="subtitle2" display="inline">
                        {siteType?.find((item) => item.value === initialValues.type)?.label}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Typography variant="subtitle1" display="inline">
                        Details &nbsp;&nbsp;&nbsp;
                      </Typography>
                      <Typography variant="subtitle2" display="inline">
                        {initialValues.details}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Paper>
              <Schedule courseId={id} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Offers courseId={id} />
            <Waitlist courseId={id} />
          </Grid>
        </Grid>
      </Fragment>
      {openDialog && (
        <ManageCourses
          courseId={id}
          onClose={() => {
            setOpenDialog(false);
          }}
          handleSucess={() => {
            setOpenDialog(false);
            fetchCourseDetails();
          }}
        />
      )}
    </Box>
  );
};

export default CoursesDetails;
