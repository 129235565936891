import { useContext, useMemo, useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { PaletteMode, createTheme, useMediaQuery } from '@mui/material';
import { getDesignTokens } from './constants/AppConfig';
import { Box } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';

import { CustomToastContext } from './context/CustomToastContext';
import CustomToaster from './components/Helpers/CustomToast';
import Login from './pages/Auth/Login';
import PrivateRoute from './components/PrivateRoute';
import Dashboard from './pages/Dashboard';
import Courses from './pages/Courses';
import CoursesDetails from './pages/Courses/CoursesDetails';
import Users from './pages/Users';
import ForgetPassword from './pages/Auth/ForgetPassword';
import ResetPassword from './pages/Auth/ResetPassword';
import Report from './pages/Report';
import Calendar from './pages/Calendar';
import Policy from './pages/Policy';
import Brands from './pages/Brands';
import routes from './components/Helpers/Routes';
import Profile from './pages/Profile';
import ContributeMaster from './pages/ContributeMaster';
import PaymentLinks from './pages/PaymentLinks/Index';
import Donation from './pages/Donation';
import Schedule from './pages/Schedules';
import AttendanceReport from './pages/AttendanceReport';
import StudentsUser from './pages/Users/Students';
import ColorModeContext from './context/ColorModeContext';
import Broadcast from './pages/Broadcast';

function App() {
  const toast = useContext(CustomToastContext);

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const [mode, setMode] = useState<PaletteMode>(prefersDarkMode ? 'dark' : 'light');

  useEffect(() => {
    const theme = localStorage.getItem('theme');
    if (theme) {
      setMode(theme as PaletteMode);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', mode);
  }, [mode]);

  const colorMode = useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) => (prevMode === 'light' ? 'dark' : 'light'));
      }
    }),
    []
  );
  useEffect(() => {
    const body = document.querySelector('body');
    if (mode === 'dark') {
      body.classList.add(mode);
    } else {
      body.classList.remove('dark');
    }
  }, [mode]);

  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route element={<PrivateRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path={routes.courses.path} element={<Courses />} />
              <Route path={`${routes.coursesDetails.path}/:id`} element={<CoursesDetails />} />
              <Route path={routes.users.path} element={<Users />} />
              <Route path={`${routes.users.path}/detail/:id`} element={<StudentsUser />} />
              <Route path={routes.contributeMaster.path} element={<ContributeMaster />} />
              <Route path={routes.report.path} element={<Report />} />
              <Route path={routes.brands.path} element={<Brands />} />
              <Route path={routes.profile.path} element={<Profile />} />
              <Route path={routes.schedule.path} element={<Schedule />} />

              <Route path="/calendar" element={<Calendar />} />
              <Route path="/policy" element={<Policy />} />
              <Route path="/payment-links" element={<PaymentLinks />} />
              <Route path={routes.donation.path} element={<Donation />} />
              <Route path={routes.attendanceReport.path} element={<AttendanceReport />} />
              <Route path={routes.broadcast.path} element={<Broadcast />} />
            </Route>
          </Routes>
        </Box>
        {toast?.toastConfig?.open && (
          <CustomToaster
            type={toast.toastConfig.type}
            message={toast.toastConfig.message}
            anchorOrigin={toast.toastConfig?.anchorOrigin || null}
            open={toast.toastConfig.open}
            close={() => {
              toast.setToastConfig({ open: false, message: null, type: null });
            }}
          />
        )}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
