import { Box, Button, Checkbox, Dialog, FormControlLabel, FormGroup, TextField } from '@mui/material';
import React from 'react';
import { CustomDialogContent, CustomDialogFooter, CustomDialogHeader } from 'src/components/CustomDialog';

function AttendanceReasonDialog({
  open,
  settingAttendance,
  status,
  setStatus,
  absentReason,
  setAbsentReason,
  onClose,
  onConfirm
}: {
  open: boolean;
  settingAttendance: boolean;
  status: any;
  setStatus: any;
  absentReason: any;
  setAbsentReason: any;
  onClose: any;
  onConfirm: any;
}) {
  return (<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
    <CustomDialogHeader showRequiredLabel={false} onClose={onClose} title="Attendance" />
    <CustomDialogContent>
      <Box>
        <FormGroup>
          <FormControlLabel
            checked={status === 'present'}
            control={<Checkbox />}
            onChange={(e) => {
              setStatus('present');
            }}
            label="Present"
          />
          <FormControlLabel
            checked={status === 'absent'}
            control={
              <Checkbox
                onChange={(e) => {
                  setStatus('absent');
                }}
              />
            }
            label="Excuse Absence"
          />
        </FormGroup>
      </Box>
      <Box mt={2} />
      <Box>
        {status === 'absent' && (
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            name={'excuse'}
            label={'Excuse'}
            multiline
            rows={3}
            value={absentReason || ''}
            onChange={(e) => setAbsentReason(e.target.value)}
          />
        )}
      </Box>
    </CustomDialogContent>
    <CustomDialogFooter>
      <Button variant="outlined" size="small" onClick={onClose}>
        Close
      </Button>
      <Box mr={1} />
      <Button
        disabled={settingAttendance || !(status === 'present' || (status === 'absent' && absentReason !== ''))}
        variant="contained"
        size="small"
        onClick={() => {
          onConfirm(status, absentReason);
        }}
      >
        Save
      </Button>
    </CustomDialogFooter>
  </Dialog>

  );
}

export default AttendanceReasonDialog;
