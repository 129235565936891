import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, Typography, useTheme } from '@mui/material';
import { axiosInstance } from 'src/axios';
import routes from 'src/components/Helpers/Routes';
import { ControlPoint } from '@mui/icons-material';
import ManageOffers from './ManageOffers';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { CustomToastContext } from 'src/context/CustomToastContext';
import ConfirmationDialog from 'src/components/Helpers/ConfirmationDialog';

interface OffersProps {
  courseId?: any;
}
const Offers = ({ courseId }: OffersProps) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const [openDialog, setOpenDialog] = useState(false);
  const [offers, setOffers] = useState(null);
  const [offersId, setOffersId] = useState(null);
  const toastConfig = useContext(CustomToastContext);
  const [showDeleteConfirmBox, setShowDeleteConfirmBox] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);

  useEffect(() => {
    if (courseId) {
      fetchOffers();
    }
  }, [courseId]);

  const fetchOffers = async () => {
    await axiosInstance()
      .get(`${routes.offers.api}?course=${courseId}`)
      .then(({ data: { data } }) => {
        setOffers(data.data);
      })
      .catch((error) => {});
  };

  const handleDelete = async () => {
    await axiosInstance()
      .put(`${routes.offers.api}/remove`, { ids: [deleteRecord] })
      .then(({ data: data }) => {
        toastConfig.setToastConfig({
          open: true,
          type: 'success',
          message: data.message
        });
        fetchOffers();
        setDeleteRecord(null);
        setShowDeleteConfirmBox(false);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  return (
    <Box sx={{ p: 1 }}>
      <Box mb={2}>
        <Paper style={{ overflow: 'hidden' }}>
          <Box padding={1} bgcolor={mode === 'dark' ? 'grey.800' : 'grey.200'} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle2">Offers </Typography>
            <IconButton
              title="Add Offers"
              color="primary"
              size="small"
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              <ControlPoint fontSize="small" />
            </IconButton>
          </Box>
          {offers?.length ? (
            <Box width="100%">
              {offers.map((obj: any) => (
                <List dense key={obj?._id}>
                  <ListItem
                    secondaryAction={
                      <>
                        <IconButton edge="end" aria-label="delete">
                          <EditIcon
                            onClick={() => {
                              setOffersId(obj?._id);
                              setOpenDialog(true);
                            }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setDeleteRecord(obj?._id);
                            setShowDeleteConfirmBox(true);
                          }}
                          edge="end"
                          aria-label="delete"
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </>
                    }
                  >
                    <ListItemText primary={`Qty : ${obj?.qty}`} secondary={`Discount Type : ${obj?.discountType}`} />
                    <ListItemText primary={`Discount : ${obj?.discount}`} />
                  </ListItem>
                  <Divider light />
                </List>
              ))}
            </Box>
          ) : (
            <Box textAlign="center" padding={2} minHeight={100}>
              <Typography>No Offers Found</Typography>
            </Box>
          )}
        </Paper>
      </Box>
      {openDialog && (
        <ManageOffers
          offersId={offersId}
          courseId={courseId}
          onClose={() => {
            setOpenDialog(false);
            setOffersId(null);
          }}
          handleSucess={() => {
            setOpenDialog(false);
            setOffersId(null);
            fetchOffers();
          }}
        />
      )}
      {showDeleteConfirmBox && (
        <ConfirmationDialog
          open={showDeleteConfirmBox}
          message={`Are you sure you want to delete the offer ?`}
          onClose={() => {
            setDeleteRecord(null);
            setShowDeleteConfirmBox(false);
          }}
          onOk={handleDelete}
        />
      )}
    </Box>
  );
};

export default Offers;
