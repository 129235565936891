import { useState, useEffect, useContext, Fragment, useCallback } from 'react';
import { axiosInstance } from 'src/axios';
import { Box, Button, IconButton, Typography, Menu, MenuItem, Tooltip, Paper, Skeleton, Grid } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { CustomToastContext } from 'src/context/CustomToastContext';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AddIcon from '@mui/icons-material/Add';
import ArrowDownIcon from '@mui/icons-material/ExpandMore';
import CustomBreadcrumbs from 'src/components/Helpers/CustomBreadcrumbs';
import { getQueryParams } from 'src/utils';
import routes from 'src/components/Helpers/Routes';
import CustomToolbar from 'src/components/Helpers/CustomToolbar';
import ConfirmationDialog from 'src/components/Helpers/ConfirmationDialog';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import ManageStudentDialog from './ManageStudentDialog';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ManageUserDialog from './ManageUserDialog';
import CommonSkeleton from 'src/components/Helpers/CommonSkeleton';

const Students = () => {
  const { id } = useParams();
  const { setToastConfig } = useContext(CustomToastContext);

  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [userId, setUserId] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [gridState, setGridState] = useState({
    limit: 20,
    page: 0
  });

  const [searchValue, setSearchValue] = useState('');
  const [rowCount, setRowCount] = useState(0);
  const openMenu = Boolean(anchorEl);
  const [showDeleteConfirmBox, setShowDeleteConfirmBox] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [studentsShowDialog, setStudentsShowDialog] = useState({ open: false, data: null });
  const [pDataLoading, setPDataLoading] = useState(false);
  const [parentData, setParentData] = useState(null);
  const [openStudentDialog, setOpenStudentDialog] = useState(false);

  useEffect(() => {
    let timeout = setTimeout(fetchUserData, 500);

    return () => clearTimeout(timeout);
  }, [gridState, searchValue]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const fetchUserData = async () => {
    setPDataLoading(true);
    if (id) {
      await axiosInstance()
        .get(`/users/${id}`)
        .then(({ data: { data } }) => {
          setParentData(data);
          data.role === 'Parent' && fetchUsers(data._id);
          setPDataLoading(false);
        })
        .catch((err) => {
          setToastConfig(err);
          setPDataLoading(false);
        });
    }
  };

  const fetchUsers = (id: any) => {
    const query = getQueryParams(gridState);
    setLoading(true);
    axiosInstance()
      .get(`/students/students/${id}?search=${searchValue}&${query}`)
      .then(({ data: { data } }) => {
        setRowCount(data?.length);
        setRows(data);
        setLoading(false);
      })
      .catch((err) => {
        setToastConfig(err);
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);
    axiosInstance()
      .put(`/students/remove`, { ids: deleteRecord })
      .then(({ data: data }) => {
        setToastConfig({
          open: true,
          type: 'success',
          message: data.message
        });

        setDeleteRecord(null);
        setShowDeleteConfirmBox(false);
        fetchUsers(id);
        setLoading(false);
      })
      .catch((error) => {
        setToastConfig(error);
        setLoading(false);
      });
  };

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'First name',
      width: 150,
      renderCell: (params: any) => {
        if (params.row.role === 'Parent' && params.row.students.length)
          return (
            <div
              style={{ cursor: 'pointer', color: 'blue' }}
              onClick={() => {
                setStudentsShowDialog({ open: true, data: params.row });
              }}
            >
              {params?.row?.firstName}
            </div>
          );
        return <>{params?.row?.firstName}</>;
      }
    },
    {
      field: 'middleName',
      headerName: 'Middle name',
      width: 150,
      renderCell: (params: any) => {
        if (params.row.role === 'Parent' && params.row.students.length)
          return (
            <div
              style={{ cursor: 'pointer', color: 'blue' }}
              onClick={() => {
                setStudentsShowDialog({ open: true, data: params.row });
              }}
            >
              {params?.row?.middleName}
            </div>
          );
        return <>{params?.row?.middleName}</>;
      }
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      width: 150,
      renderCell: (params: any) => {
        if (params.row.role === 'Parent' && params.row.students.length)
          return (
            <div
              style={{ cursor: 'pointer', color: 'blue' }}
              onClick={() => {
                setStudentsShowDialog({ open: true, data: params.row });
              }}
            >
              {params?.row?.lastName}
            </div>
          );
        return <>{params?.row?.lastName}</>;
      }
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250
    },
    {
      field: 'phone',
      headerName: 'Phone',
      width: 250
    },
    {
      field: 'dob',
      headerName: 'Date of Birth',
      type: 'string',
      renderCell: (params: any) => {
        return <>{params?.row?.dob ? moment(params?.row?.dob).format('DD/MM/YYYY') : ''}</>;
      },
      width: 150
    },
    {
      field: 'healthCareNumber',
      headerName: 'Health Care Number',
      width: 250
    },
    {
      field: 'allergies',
      headerName: 'Allergies',
      renderCell: (params: any) => {
        if (!params?.row?.allergies?.length) return '';
        return (
          <>
            {params?.row?.allergies?.map((d: any, idx: number) => {
              if (idx !== 0) return <div>{`, ${d}`}</div>;
              return d;
            })}
          </>
        );
      },
      width: 250
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      headerAlign: 'left',
      renderCell: (params: any) => {
        return (
          <>
            <div style={{ display: 'flex' }}>
              {params?.row?.superAdmin === true ? null : (
                <IconButton
                  onClick={() => {
                    setUserId(params.id);
                    setOpen(true);
                  }}
                >
                  <EditIcon color="primary" />
                </IconButton>
              )}
              {/* <Tooltip title="Send password reset link">
                <IconButton
                  onClick={() => {
                    handleSendPasswordReset([params.id]);
                  }}
                >
                  <SendIcon />
                </IconButton>
              </Tooltip> */}
              {params?.row?.superAdmin === true ? null : (
                <IconButton
                  onClick={() => {
                    setDeleteRecord([params.id]);
                    setShowDeleteConfirmBox(true);
                  }}
                >
                  <DeleteIcon color="error" />
                </IconButton>
              )}
            </div>
          </>
        );
      },
      align: 'left'
    }
  ];

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value.trimStart());
  }, []);

  const handleSendPasswordReset = async (ids: any) => {
    axiosInstance()
      .post(`/users/send-password-reset`, { ids })
      .then(({ data }) => {
        setToastConfig({
          open: true,
          message: data.message,
          type: 'success'
        });
      })
      .catch((err) => {
        setToastConfig(err);
      });
  };
  return (
    <Fragment>
      <Box sx={{ p: 1 }}>
        <CustomBreadcrumbs
          routes={[
            routes.users,
            {
              title: `${parentData?.firstName || ''} ${parentData?.lastName || ''}`,
              path: '/users',
              api: '/users'
            }
          ]}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 42,
            pl: 1,
            pr: 1
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PeopleAltIcon fontSize="small" color="primary" />
            <Box mr={1} />
            <Typography variant="h6" color="GrayText">
              {parentData?.firstName} {parentData?.lastName}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {parentData ? (
              <Button
                id="add-button"
                aria-controls={'add-button'}
                onClick={() => {
                  setOpenStudentDialog(true);
                }}
                size="small"
                variant="contained"
              >
                Edit
              </Button>
            ) : (
              <CommonSkeleton lenArray={[1]} />
            )}
          </Box>
        </Box>
        <Box pt={2}>
          <Paper>
            {!pDataLoading && parentData ? (
              <Grid container spacing={2} style={{ padding: '8px' }}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography variant="subtitle1" display="inline">
                    Name&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography variant="subtitle2" display="inline">
                    {parentData?.firstName} {parentData?.lastName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography variant="subtitle1" display="inline">
                    Role&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography variant="subtitle2" display="inline">
                    {parentData?.role || ''}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography variant="subtitle1" display="inline">
                    Email&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography variant="subtitle2" display="inline">
                    {parentData?.email || ''}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography variant="subtitle1" display="inline">
                    Phone&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography variant="subtitle2" display="inline">
                    {parentData?.phone || ''}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography variant="subtitle1" display="inline">
                    Date of Birth&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography variant="subtitle2" display="inline">
                    {parentData?.dob ? moment(parentData?.dob).format('DD/MM/YYYY') : ''}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography variant="subtitle1" display="inline">
                    Gender &nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography variant="subtitle2" display="inline">
                    {parentData?.gender || ''}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography variant="subtitle1" display="inline">
                    Status &nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography variant="subtitle2" display="inline">
                    {parentData?.status ? 'Active' : 'Inactive' || ''}
                  </Typography>
                </Grid>

                {parentData?.healthCareNumber !== (null || 0) && (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant="subtitle1" display="inline">
                      Health Care No &nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography variant="subtitle2" display="inline">
                      {parentData?.healthCareNumber || ''}
                    </Typography>
                  </Grid>
                )}
                {parentData?.allergies && (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant="subtitle1" display="inline">
                      Allergies &nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography variant="subtitle2" display="inline">
                      {parentData?.allergies?.length ? parentData?.allergies?.map((d: any) => d)?.join(', ') : 'None'}
                    </Typography>
                  </Grid>
                )}
                {parentData?.city && (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant="subtitle1" display="inline">
                      City &nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography variant="subtitle2" display="inline">
                      {parentData?.city || ''}
                    </Typography>
                  </Grid>
                )}
                {parentData?.country && (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant="subtitle1" display="inline">
                      Country &nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography variant="subtitle2" display="inline">
                      {parentData?.country || ''}
                    </Typography>
                  </Grid>
                )}
                {parentData?.zipCode && (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant="subtitle1" display="inline">
                      Zip Code &nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography variant="subtitle2" display="inline">
                      {parentData?.zipCode || ''}
                    </Typography>
                  </Grid>
                )}
                {parentData?.address && (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant="subtitle1" display="inline">
                      Address &nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography variant="subtitle2" display="inline">
                      {parentData?.address || ''}
                    </Typography>
                  </Grid>
                )}
                {parentData?.emergencyFirstName && (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant="subtitle1" display="inline">
                      Emergency Name &nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography variant="subtitle2" display="inline">
                      {`${parentData?.emergencyFirstName || ''} ${parentData?.emergencyMiddleName || ''} ${parentData?.emergencyLastName || ''}`}
                    </Typography>
                  </Grid>
                )}
                {parentData?.emergencyGender && (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant="subtitle1" display="inline">
                      Emergency Gender &nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography variant="subtitle2" display="inline">
                      {parentData?.emergencyGender || ''}
                    </Typography>
                  </Grid>
                )}
                {parentData?.emergencyEmail && (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant="subtitle1" display="inline">
                      Emergency Email &nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography variant="subtitle2" display="inline">
                      {parentData?.emergencyEmail || ''}
                    </Typography>
                  </Grid>
                )}
                {parentData?.emergencyPhone && (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant="subtitle1" display="inline">
                      Emergency Phone &nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography variant="subtitle2" display="inline">
                      {parentData?.emergencyPhone || ''}
                    </Typography>
                  </Grid>
                )}
                {parentData?.emergencyCountry && (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant="subtitle1" display="inline">
                      Emergency Country &nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography variant="subtitle2" display="inline">
                      {parentData?.emergencyCountry?.label || ''}
                    </Typography>
                  </Grid>
                )}
                {parentData?.emergencyState && (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant="subtitle1" display="inline">
                      Emergency State &nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography variant="subtitle2" display="inline">
                      {parentData?.emergencyState?.label || ''}
                    </Typography>
                  </Grid>
                )}
                {parentData?.emergencyCity && (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant="subtitle1" display="inline">
                      Emergency City &nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography variant="subtitle2" display="inline">
                      {parentData?.emergencyCity?.label || ''}
                    </Typography>
                  </Grid>
                )}
                {parentData?.emergencyZipCode && (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant="subtitle1" display="inline">
                      Emergency Zip Code &nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography variant="subtitle2" display="inline">
                      {parentData?.emergencyZipCode || ''}
                    </Typography>
                  </Grid>
                )}
                {parentData?.emergencyAddress && (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant="subtitle1" display="inline">
                      Emergency Address &nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography variant="subtitle2" display="inline">
                      {parentData?.emergencyAddress || ''}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            ) : (
              <Box sx={{ p: 2 }}>
                <Skeleton variant="rectangular" height={42} />
              </Box>
            )}
          </Paper>
        </Box>

        {parentData?.role === 'Parent' && (
          <>
            <Paper>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: 42,
                  pl: 1,
                  pr: 1
                }}
                style={{ padding: '8px', marginTop: '8px' }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {/* <PeopleAltIcon fontSize="small" color="primary" /> */}
                  <Box mr={1} />
                  <Typography variant="h6" color="GrayText">
                    Students
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    id="add-button"
                    aria-controls={'add-button'}
                    onClick={() => {
                      setOpen(true);
                      setUserId(null);
                    }}
                    size="small"
                    variant="contained"
                    endIcon={<AddIcon />}
                  >
                    Add
                  </Button>
                  <Box mr={1} />
                  <Button
                    id="actions-button"
                    aria-controls={open ? 'actions-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleOpenMenu}
                    size="small"
                    disabled={selectedRows.length === 0 || deleting}
                    variant="outlined"
                    endIcon={<ArrowDownIcon />}
                  >
                    Actions
                  </Button>
                  <Menu
                    id="actions-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                      'aria-labelledby': 'actions-button'
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        setDeleteRecord(selectedRows);
                        setShowDeleteConfirmBox(true);
                        handleCloseMenu();
                      }}
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
              <Box pt={1}>
                <Paper style={{ overflow: 'hidden' }}>
                  <DataGrid
                    sx={{ height: 'calc(100vh - 150px)' }}
                    rowCount={rowCount}
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    pageSize={gridState.limit}
                    onPageSizeChange={(pageSize) => setGridState((s) => ({ ...s, limit: pageSize }))}
                    page={gridState.page}
                    onPageChange={(page) => setGridState((s) => ({ ...s, page }))}
                    rowsPerPageOptions={[5, 20, 50, 100]}
                    checkboxSelection
                    getRowId={(row) => row?._id}
                    disableSelectionOnClick
                    paginationMode="server"
                    components={{
                      Toolbar: CustomToolbar
                    }}
                    componentsProps={{
                      toolbar: {
                        value: searchValue,
                        onChange: handleSearch,
                        onRefresh: fetchUsers,
                        hasExport: true,
                        exportApi: routes.users.api,
                        fileName: 'SNEI-Users',
                        header: ['First Name', 'Last Name', 'Email', 'Gender', 'Phone', 'Role', 'Status']
                      }
                    }}
                    isRowSelectable={(params) => !params.row.superAdmin}
                    onSelectionModelChange={(selection) => {
                      setSelectedRows(selection);
                    }}
                  />
                </Paper>
              </Box>
            </Paper>
          </>
        )}
      </Box>
      {openStudentDialog && (
        <ManageUserDialog
          userId={id}
          onClose={() => {
            setOpenStudentDialog(false);
          }}
          handleSucess={() => {
            setOpenStudentDialog(false);
            fetchUserData();
          }}
        />
      )}
      {open && (
        <ManageStudentDialog
          parentId={id}
          userId={userId}
          onClose={() => {
            setOpen(false);
          }}
          handleSucess={() => {
            setOpen(false);
            fetchUsers(id);
          }}
        />
      )}
      {showDeleteConfirmBox && (
        <ConfirmationDialog
          open={showDeleteConfirmBox}
          message={`Are you sure you want to delete student ?`}
          onClose={() => {
            setDeleteRecord(null);
            setShowDeleteConfirmBox(false);
          }}
          onOk={handleDelete}
        />
      )}
    </Fragment>
  );
};

export default Students;
