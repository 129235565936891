import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  TextField,
  Grid,
  CircularProgress,
  Box,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Typography,
  Avatar,
  useTheme
} from '@mui/material';
import { Formik } from 'formik';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { CustomDialogContent, CustomDialogFooter, CustomDialogHeader } from 'src/components/CustomDialog';
import { isMobile, isTablet } from 'react-device-detect';
import CommonSkeleton from 'src/components/Helpers/CommonSkeleton';
import routes from 'src/components/Helpers/Routes';
import moment from 'moment-timezone';
import Autocomplete from '@mui/material/Autocomplete';
import currencies from '../../constants/currency_with_country.json';
import { useData } from 'src/context/Provider';
import { SET_BRAND } from 'src/context/ActionTypes';
import { siteName } from 'src/config';
import { isValidUrl } from 'src/utils';

const PolicyDialog = ({ onClose, handleSuccess }: any) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const [fullScreen, setFullScreen] = useState(isMobile || isTablet);
  const [initialValues, setInitialValues] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toastConfig = React.useContext(CustomToastContext);
  const [currencyData, setCurrencyData] = React.useState([]);
  const { dispatch } = useData();

  useEffect(() => {
    axiosInstance()
      .get(routes.policy.api)
      .then(({ data: { data } }) => {
        setInitialValues(data);
      })
      .catch((err) => {});
    const sortedArr = currencies.sort((a, b) =>
      a.name.toUpperCase() < b.name.toUpperCase() ? -1 : a.name.toUpperCase() > b.name.toUpperCase() ? 1 : 0
    );
    setCurrencyData(sortedArr);
  }, []);

  const handleSubmit = (values: any) => {
    setIsSubmitting(true);
    axiosInstance()
      .post(routes.policy.api, { ...values })
      .then(({ data }) => {
        toastConfig.setToastConfig({
          open: true,
          type: 'success',
          message: data.message
        });
        setIsSubmitting(false);
        handleSuccess();
        dispatch({ type: SET_BRAND, payload: values });
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
        setIsSubmitting(false);
      });
  };

  const validate = (values: any) => {
    const errors: any = {};

    if (values.subheaderButtonLink?.length && !isValidUrl(values.subheaderButtonLink)) errors.subheaderButtonLink = 'Please enter valid URL';

    return errors;
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen || isMobile || isTablet}
      aria-labelledby="customized-dialog-title"
      onClose={(e, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      open
    >
      {initialValues ? (
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
          {({ submitForm, values, errors, touched, setFieldValue }) => (
            <>
              <CustomDialogHeader
                onClose={onClose}
                title={'Update Policy'}
                onMinimizeMaximize={() => {
                  setFullScreen((prevState) => !prevState);
                }}
                showManimizeMaximize={true}
              />
              <CustomDialogContent>
                <Box sx={{ pt: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            onChange={(e: { target: { checked: any } }) => setFieldValue('isOnline', e.target.checked)}
                            checked={values.isOnline}
                            defaultChecked
                          />
                        }
                        label="Online"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            onChange={(e: { target: { checked: any } }) => setFieldValue('isOffline', e.target.checked)}
                            checked={values.isOffline}
                            defaultChecked
                          />
                        }
                        label="Offline"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        variant="outlined"
                        size="small"
                        required
                        name={'paymentGateway'}
                        label="Payment Gateway"
                        placeholder="Payment Gateway"
                        onChange={(e) => setFieldValue('paymentGateway', e.target.value)}
                        value={values.paymentGateway}
                        error={touched.paymentGateway && Boolean(errors.paymentGateway)}
                      >
                        <MenuItem value={'Stripe'}>Stripe</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        size="small"
                        required
                        name={'apiKey'}
                        label={'Api Key'}
                        value={values.apiKey}
                        onChange={(e) => setFieldValue('apiKey', e.target.value)}
                        error={touched.apiKey && Boolean(errors.apiKey)}
                        helperText={(touched.apiKey && errors.apiKey)?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="number"
                        size="small"
                        required
                        name={'paymentGatewayCharge'}
                        label={'Processing Fee'}
                        value={values.paymentGatewayCharge}
                        onChange={(e) => setFieldValue('paymentGatewayCharge', e.target.value)}
                        error={touched.paymentGatewayCharge && Boolean(errors.paymentGatewayCharge)}
                        helperText={(touched.paymentGatewayCharge && errors.paymentGatewayCharge)?.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        size="small"
                        fullWidth
                        options={currencyData}
                        isOptionEqualToValue={(option, value) => option === value}
                        getOptionLabel={(option: any) => (option ? `${option?.currencyCode} - ${option?.name}` : '')}
                        value={
                          currencyData.filter((data) => data.currencyCode === values['currency']).length
                            ? currencyData.filter((data) => data.currencyCode === values['currency'])[0]
                            : ''
                        }
                        onChange={(_, newVal: any) => setFieldValue('currency', newVal?.currencyCode)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            required
                            name={'currency'}
                            label={'Currency'}
                            error={touched['currency'] && Boolean(errors['currency'])}
                            helperText={touched['currency'] && errors['currency']?.toString()}
                          />
                        )}
                        renderOption={(props, option: any) => {
                          const { currencyCode, name, countryCode } = option;
                          return (
                            <li {...props}>
                              <Grid container alignItems="center">
                                <Grid item>
                                  <Avatar
                                    variant="rounded"
                                    src={`https://flagcdn.com/w80/${countryCode?.toLowerCase()}.png`}
                                    style={{
                                      marginRight: 20,
                                      width: '40px',
                                      height: '30px'
                                    }}
                                  />
                                </Grid>
                                <Grid item xs>
                                  <Typography>{currencyCode}</Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    {name}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </li>
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        value={values.timeZone}
                        onChange={(event: any, newValue: any) => {
                          setFieldValue('timeZone', newValue);
                        }}
                        id="controllable-states-demo"
                        options={moment.tz.names()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="timeZone"
                            required
                            size="small"
                            label="Time Zone"
                            error={touched.timeZone && Boolean(errors.timeZone)}
                            helperText={(touched.timeZone && errors.timeZone)?.toString()}
                          />
                        )}
                      />
                    </Grid>
                    {!siteName.includes('Site1') && (
                      <>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            size="small"
                            name={'subheaderTitle'}
                            label={'Subheader Title'}
                            value={values.subheaderTitle}
                            onChange={(e) => setFieldValue('subheaderTitle', e.target.value)}
                            error={touched.subheaderTitle && Boolean(errors.subheaderTitle)}
                            helperText={(touched.subheaderTitle && errors.subheaderTitle)?.toString()}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            size="small"
                            name={'subheaderButtonLabel'}
                            label={'Subheader Button Label'}
                            value={values.subheaderButtonLabel}
                            onChange={(e) => setFieldValue('subheaderButtonLabel', e.target.value)}
                            error={touched.subheaderButtonLabel && Boolean(errors.subheaderButtonLabel)}
                            helperText={(touched.subheaderButtonLabel && errors.subheaderButtonLabel)?.toString()}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            size="small"
                            name={'subheaderButtonLink'}
                            label={'Subheader Button Link'}
                            value={values.subheaderButtonLink}
                            onChange={(e) => setFieldValue('subheaderButtonLink', e.target.value)}
                            error={touched.subheaderButtonLink && Boolean(errors.subheaderButtonLink)}
                            helperText={(touched.subheaderButtonLink && errors.subheaderButtonLink)?.toString()}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              </CustomDialogContent>
              <CustomDialogFooter>
                <Button variant="contained" size="small" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={submitForm}
                  size="small"
                  disabled={isSubmitting}
                  endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                >
                  Save
                </Button>
              </CustomDialogFooter>
            </>
          )}
        </Formik>
      ) : (
        <Box height={500} bgcolor={mode === 'dark' ? 'gray.900' : 'white'}>
          <CommonSkeleton lenArray={[...Array(10).keys()]} />
        </Box>
      )}
    </Dialog>
  );
};

export default PolicyDialog;
