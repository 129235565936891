import React, { useContext } from 'react';
import { AppBar, IconButton, Toolbar, Box, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { SET_USER, SET_BRAND } from 'src/context/ActionTypes';
import { useData } from 'src/context/Provider';
import { logo } from 'src/config';
import { Link, useNavigate } from 'react-router-dom';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import ColorModeContext from '../../context/ColorModeContext';

interface HeaderProps {
  toggleDrawer: boolean;
  setToggleDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header = ({ toggleDrawer, setToggleDrawer }: HeaderProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { dispatch }: any = useData();
  const navigate = useNavigate();

  const colorMode = useContext(ColorModeContext);

  const theme = useTheme();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    handleClose();
    localStorage.removeItem('token');
    dispatch({ type: SET_USER, payload: null });
    dispatch({ type: SET_BRAND, payload: null });
  };

  return (
    <AppBar position="fixed" sx={{ zIndex: 1201 }}>
      <Toolbar sx={{ maxHeight: '50px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <IconButton onClick={() => setToggleDrawer((ps) => !ps)} aria-label="open drawer" edge="start" sx={{ color: 'white' }}>
            {toggleDrawer ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          <Link to={'/dashboard'} style={{ marginLeft: '14px', display: 'block' }}>
            <img src={logo} alt="" style={{ maxWidth: '300px', maxHeight: '40px', filter: 'brightness(0) invert(1)' }} />
          </Link>
        </Box>
        <IconButton onClick={colorMode.toggleColorMode} color="inherit">
          {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              navigate('/profile');
            }}
          >
            Profile
          </MenuItem>
          <MenuItem onClick={handleLogOut}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};
export default Header;
