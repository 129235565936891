import { useState, useEffect, useContext, Fragment, useCallback } from 'react';
import { axiosInstance } from 'src/axios';
import { Box, Button, IconButton, Typography, Menu, MenuItem, Tooltip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import ManageUserDialog from './ManageUserDialog';
import { CustomToastContext } from 'src/context/CustomToastContext';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AddIcon from '@mui/icons-material/Add';
import ArrowDownIcon from '@mui/icons-material/ExpandMore';
import CustomBreadcrumbs from 'src/components/Helpers/CustomBreadcrumbs';
import { getQueryParams } from 'src/utils';
import routes from 'src/components/Helpers/Routes';
import CustomToolbar from 'src/components/Helpers/CustomToolbar';
import ConfirmationDialog from 'src/components/Helpers/ConfirmationDialog';
import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import StudentDialog from './Students';
import { Link } from 'react-router-dom';
import moment from 'moment';

const Users = () => {
  const { setToastConfig } = useContext(CustomToastContext);

  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [userId, setUserId] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [gridState, setGridState] = useState({
    limit: 20,
    page: 0
  });

  const [searchValue, setSearchValue] = useState('');
  const [rowCount, setRowCount] = useState(0);
  const openMenu = Boolean(anchorEl);
  const [showDeleteConfirmBox, setShowDeleteConfirmBox] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [studentsShowDialog, setStudentsShowDialog] = useState({ open: false, data: null });

  useEffect(() => {
    let timeout = setTimeout(fetchUsers, 500);

    return () => clearTimeout(timeout);
  }, [gridState, searchValue]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const fetchUsers = () => {
    const query = getQueryParams(gridState);
    setLoading(true);
    axiosInstance()
      .get(`/users?search=${searchValue}&${query}`)
      .then(({ data: { data, count } }) => {
        setRowCount(count);

        const a = moment();

        const newDataWithAge = data.map((item: any) => {
          if (!item.dateOfBirth) {
            return { ...item, age: '-' };
          }
          const b = moment(item.dateOfBirth);
          const diff = a.diff(b, 'years');
          return { ...item, age: diff };
        });

        setRows(newDataWithAge);
        setLoading(false);
      })
      .catch((err) => {
        setToastConfig(err);
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);
    axiosInstance()
      .put(`${routes.users.api}/remove`, { ids: deleteRecord })
      .then(({ data: data }) => {
        setToastConfig({
          open: true,
          type: 'success',
          message: data.message
        });

        setDeleteRecord(null);
        setShowDeleteConfirmBox(false);
        fetchUsers();
        setLoading(false);
      })
      .catch((error) => {
        setToastConfig(error);
        setLoading(false);
      });
  };

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'First name',
      width: 150,
      renderCell: (params: any) => {
        return (
          <Link to={`${routes.users.path}/detail/${params.row._id}`} style={{ cursor: 'pointer' }}>
            {params?.row?.firstName}
          </Link>
        );
      }
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      width: 150,
      renderCell: (params: any) => {
        return (
          <Link to={`${routes.users.path}/detail/${params.row._id}`} style={{ cursor: 'pointer' }}>
            {params?.row?.lastName}
          </Link>
        );
      }
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250
    },
    {
      field: 'gender',
      headerName: 'Gender',
      width: 150
    },
    {
      field: 'age',
      headerName: 'Age',
      width: 150
    },
    {
      field: 'phone',
      headerName: 'Phone',
      width: 250
    },
    {
      field: 'role',
      headerName: 'Role',
      width: 150
    },
    {
      field: 'students',
      headerName: 'No of Students',
      width: 150,
      renderCell: (params: any) => {
        return <div>{params?.row.students?.length}</div>;
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      valueGetter: (params) => {
        if (params.value) {
          return 'Active';
        } else {
          return 'Inactive';
        }
      },
      width: 150
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      headerAlign: 'left',
      renderCell: (params: any) => {
        return (
          <>
            <div style={{ display: 'flex' }}>
              {params?.row?.superAdmin === true ? null : (
                <IconButton
                  onClick={() => {
                    setUserId(params.id);
                    setOpen(true);
                  }}
                >
                  <EditIcon color="primary" />
                </IconButton>
              )}
              <Tooltip title="Send password reset link">
                <IconButton
                  onClick={() => {
                    handleSendPasswordReset([params.id]);
                  }}
                >
                  <SendIcon />
                </IconButton>
              </Tooltip>
              {params?.row?.superAdmin === true ? null : (
                <IconButton
                  onClick={() => {
                    setDeleteRecord([params.id]);
                    setShowDeleteConfirmBox(true);
                  }}
                >
                  <DeleteIcon color="error" />
                </IconButton>
              )}
            </div>
          </>
        );
      },
      align: 'left'
    }
  ];

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value.trimStart());
  }, []);

  const handleSendPasswordReset = async (ids: any) => {
    axiosInstance()
      .post(`/users/send-password-reset`, { ids })
      .then(({ data }) => {
        setToastConfig({
          open: true,
          message: data.message,
          type: 'success'
        });
      })
      .catch((err) => {
        setToastConfig(err);
      });
  };

  return (
    <Fragment>
      <Box sx={{ p: 1 }}>
        <CustomBreadcrumbs routes={[routes.users]} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 42,
            pl: 1,
            pr: 1
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PeopleAltIcon fontSize="small" color="primary" />
            <Box mr={1} />
            <Typography variant="h6" color="GrayText">
              Users
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              id="add-button"
              aria-controls={'add-button'}
              onClick={() => {
                setOpen(true);
                setUserId(null);
              }}
              size="small"
              variant="contained"
              endIcon={<AddIcon />}
            >
              Add
            </Button>
            <Box mr={1} />
            <Button
              id="actions-button"
              aria-controls={open ? 'actions-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleOpenMenu}
              size="small"
              disabled={selectedRows.length === 0 || deleting}
              variant="outlined"
              endIcon={<ArrowDownIcon />}
              color="primary"
            >
              Actions
            </Button>
            <Menu
              id="actions-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleCloseMenu}
              MenuListProps={{
                'aria-labelledby': 'actions-button'
              }}
            >
              <MenuItem
                onClick={() => {
                  setDeleteRecord(selectedRows);
                  setShowDeleteConfirmBox(true);
                  handleCloseMenu();
                }}
              >
                Delete
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <Box pt={1}>
          <DataGrid
            sx={{ height: 'calc(100vh - 150px)' }}
            rowCount={rowCount}
            loading={loading}
            rows={rows}
            columns={columns}
            pageSize={gridState.limit}
            onPageSizeChange={(pageSize) => setGridState((s) => ({ ...s, limit: pageSize }))}
            page={gridState.page}
            onPageChange={(page) => setGridState((s) => ({ ...s, page }))}
            rowsPerPageOptions={[5, 20, 50, 100]}
            checkboxSelection
            getRowId={(row) => row?._id}
            disableSelectionOnClick
            paginationMode="server"
            components={{
              Toolbar: CustomToolbar
            }}
            componentsProps={{
              toolbar: {
                value: searchValue,
                onChange: handleSearch,
                onRefresh: fetchUsers,
                hasExport: true,
                exportApi: routes.users.api,
                fileName: 'SNEI-Users',
                header: ['First Name', 'Last Name', 'Email', 'Gender', 'Phone', 'Role', 'Status']
              }
            }}
            isRowSelectable={(params) => !params.row.superAdmin}
            onSelectionModelChange={(selection) => {
              setSelectedRows(selection);
            }}
          />
        </Box>
      </Box>
      {open && (
        <ManageUserDialog
          userId={userId}
          onClose={() => {
            setOpen(false);
          }}
          handleSucess={() => {
            setOpen(false);
            fetchUsers();
          }}
        />
      )}
      {showDeleteConfirmBox && (
        <ConfirmationDialog
          open={showDeleteConfirmBox}
          message={`Are you sure you want to delete user ?`}
          onClose={() => {
            setDeleteRecord(null);
            setShowDeleteConfirmBox(false);
          }}
          onOk={handleDelete}
        />
      )}
    </Fragment>
  );
};

export default Users;
